import DataFilterSelector from 'components/DataFilterSelector'
import DataEditModal from 'components/DataEditModal'
import { Row, Button } from 'antd'
import {
  FILTERS_BY_DASHBOARD_SECTION,
  PAGES_WITH_COMPARISONS,
  DATA_REDUCER_ACTION_TYPES
} from '../utils'
import React, { useState } from 'react'

import './DataContext.less'

export default ({
  filterGroups,
  onGetData,
  loading,
  currentDataPage,
  dispatchFilterGroupsAction
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const pageFilters = Object.keys(FILTERS_BY_DASHBOARD_SECTION[currentDataPage])
  const onFilterChange = (category, selectedValue, filterGroupIndex) =>
    dispatchFilterGroupsAction({
      type: DATA_REDUCER_ACTION_TYPES.FILTER_CHANGE,
      payload: { category, selectedValue, filterGroupIndex }
    })
  return (
    <>
      <Row justify='start' align='middle'>
        *Setting this filter will not restrict available values in other
        filters.
      </Row>
      <Row className='data-context' justify='end' align='middle' type='flex'>
        <Row justify='space-between' align='middle' type='flex'>
          {pageFilters.map(category => (
            <div className='filter-selector' key={`${category}-filter`}>
              <DataFilterSelector
                category={category}
                filterData={filterGroups[0].filterState[category]}
                onFilterChange={onFilterChange}
                loading={loading}
              />
            </div>
          ))}
        </Row>
        <Row
          className='data-context-btn-area'
          justify='end'
          align='middle'
          type='flex'
        >
          {PAGES_WITH_COMPARISONS.includes(currentDataPage) && (
            <Button
              className='edit-btn'
              type='secondary'
              onClick={() => setIsModalVisible(true)}
              loading={loading}
            >
              Edit
            </Button>
          )}
          <Button
            className='get-data-btn'
            type='primary'
            onClick={onGetData}
            loading={loading}
          >
            Get Data
          </Button>
        </Row>
      </Row>

      {PAGES_WITH_COMPARISONS.includes(currentDataPage) && (
        <DataEditModal
          visible={isModalVisible}
          filterGroups={filterGroups}
          pageFilters={pageFilters}
          onOkOrCancel={() => setIsModalVisible(false)}
          onFilterChange={onFilterChange}
          onAddFilterGroup={() =>
            dispatchFilterGroupsAction({
              type: DATA_REDUCER_ACTION_TYPES.ADD_FILTER_GROUP
            })
          }
          onDeleteFilterGroup={filterGroupIndex =>
            dispatchFilterGroupsAction({
              type: DATA_REDUCER_ACTION_TYPES.DELETE_FILTER_GROUP,
              payload: { filterGroupIndex }
            })
          }
          onUpdateLabel={(filterGroupIndex, value) =>
            dispatchFilterGroupsAction({
              type: DATA_REDUCER_ACTION_TYPES.UPDATE_LABEL,
              payload: { filterGroupIndex, value }
            })
          }
          onUpdateColor={(filterGroupIndex, value) =>
            dispatchFilterGroupsAction({
              type: DATA_REDUCER_ACTION_TYPES.UPDATE_COLOR,
              payload: { filterGroupIndex, value }
            })
          }
        />
      )}
    </>
  )
}
