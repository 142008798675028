import useForm from 'shared/hooks/useForm'
import Toggle from './PermissionToggle'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import { Modal, Form } from 'antd'
import reduce from '@f/reduce'
import React from 'react'
import { useModal } from 'shared/hooks/useModal'
import InputConfirmModal from 'shared/components/InputConfirmModal'

import './PermissionsModal.less'
import { getFullName } from 'shared/utils'

const PermissionsModal = ({ user, orgs, onOk, rpc, ...rest }) => {
  const { schools = [] } = orgs
  const { roles = {}, id } = user
  const initVals = rolesToFormValues(roles)
  const userName = getFullName(user)
  const { handleSubmit, getFieldProps, isSubmitting } = useForm({
    onSubmit: async vals => {
      const newVals = valuesToRoles(initVals, vals)
      if (Object.keys(newVals).length) {
        await rpc('user.setRoles', {
          user: id,
          roles: newVals
        })
      }
      onOk()
    },
    initialValues: schools.reduce(
      (acc, { id }) => ({ ...acc, [id]: initVals[id] }),
      {}
    )
  })
  const [changePermissionModal, hideChangePermissionModal] = useModal(() => (
    <InputConfirmModal
      title={`${userName} Permissions`}
      content={`This action will change the permissions for ${userName}. Are you sure you want to do this? 
      If so, please enter ' Change ' to confirm.`}
      okText='Change'
      okButtonText='Change'
      hide={hideChangePermissionModal}
      onConfirm={handleSubmit} />
  ))

  return (
    <Modal
      {...rest}
      title={`${userName} Permissions`}
      okText='Save'
      confirmLoading={isSubmitting}
      onOk={changePermissionModal}>
      <Form
        style={{
          maxHeight: 360,
          overflowY: 'auto',
          border: '1px solid #e8e8e8'
        }}
        onSubmit={changePermissionModal}>
        <Toggle label={<b>School</b>} rightLabel={<b>Role</b>} />
        {schools.map((s, i) => (
          <Toggle
            {...getFieldProps(s.id, 'select')}
            name={s.id}
            last={schools.length === i + 1}
            label={s.displayName}
            options={[
              { label: 'Admin', value: 'admin' },
              { label: 'Teacher', value: 'teacher' }
            ]}
            key={s.id} />
        ))}
      </Form>
    </Modal>
  )
}

/** @todo propTypes */

export default connect(
  null,
  { rpc }
)(PermissionsModal)

/**
 * @function rolesToFormValues
 * @param {Object} roles user profile roles
 * @returns {Object} user roles formatted to be used in the form
 */
const rolesToFormValues = roles =>
  reduce(
    (acc, ids, roleType) => ({
      ...acc,
      ...ids.reduce((acc, id) => ({ ...acc, [id]: roleType }), {})
    }),
    {},
    roles
  )

// Re-format form values back to profile.roles format
const valuesToRoles = (initialVals, newVals) =>
  reduce(
    (acc, roleType, id) => ({
      ...acc,
      [roleType]: (acc[roleType] || []).concat(id)
    }),
    {},
    getNewRoles(initialVals, newVals)
  )

// find changed roles
const getNewRoles = (initVals = {}, roles) =>
  reduce(
    (acc, newVal, key) => {
      const isRoleChanged = initVals[key] === newVal
      return isRoleChanged ? acc : { ...acc, [key]: newVal }
    },
    {},
    roles
  )
