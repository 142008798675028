import { errorToMessage } from '../../utils/errors'
import { useState, useEffect } from 'react'
import get from 'lodash/get'
import set from 'lodash/set'

export default function Formik ({
  initialValues,
  validate,
  onSubmit,
  validateOnBlur = true,
  validateOnChange = true,
  validationSchema,
  abortEarly = false,
  ...props
}) {
  const [values, updateValues] = useState(initialValues)
  const [errors, updateErrors] = useState({})
  const [touched, updateTouched] = useState({})
  const [submitAttemptCount, updateSubmitAttemptCount] = useState(0)
  const [isSubmitting, updateIsSubmitting] = useState(false)
  const [isValidating, updateIsValidating] = useState(false)
  function validateForm (vals = values) {
    updateIsValidating(true)
    return Promise.resolve(
      validate
        ? validate(vals)
        : validationSchema
          ? validationSchema(vals, { greedy: true }).errors
          : {}
    ).then((e = {}) => {
      // return or take a callback?
      updateIsValidating(false)
      if (e && e.length) {
        updateErrors(
          e.reduce((acc, err) => {
            if (!get(touched, err.field)) return acc
            return set({ ...acc }, err.field, errorToMessage(err.message))
          }, {})
        )
        return 'error'
      }
      updateErrors({})
    })
  }

  function getFieldProps (name, type) {
    const isSelect = type === 'select'
    const isCheckType = type === 'radio' || type === 'checkbox'
    const handleChange = (e, val) => {
      if (e.persist) {
        e.persist()
      }
      updateValues(prevValues => set({ ...prevValues }, name, val))
    }
    const onChange = isSelect
      ? (checked, e) => handleChange(e, checked)
      : e =>
        handleChange(
          e,
          isCheckType ? e.target.value || e.target.checked : e.target.value
        )

    return {
      value: isCheckType
        ? undefined // React uses checked={} for these inputs
        : get(values, name),
      checked: isCheckType ? get(values, name) : false,
      onChange,
      onBlur () {
        updateTouched(prevTouched => set({ ...prevTouched }, name, true))
      },
      error: get(errors, name)
    }
  }

  function getFieldError (name) {
    return {
      help: get(errors, name),
      validateStatus: get(errors, name) ? 'error' : ''
    }
  }

  async function submitForm () {
    updateTouched(setNestedObjectValues(values, true))
    updateIsSubmitting(true)
    updateSubmitAttemptCount(prev => prev++)
    try {
      const error = await validateForm()
      if (error) throw new Error('validation_failed')
      await onSubmit(values)
      updateIsSubmitting(false)
    } catch (e) {
      updateIsSubmitting(false)
    }
  }

  function handleSubmit (e) {
    e.preventDefault()
    return submitForm()
  }

  useEffect(() => {
    if (validateOnChange && (validate || validationSchema)) {
      validateForm()
    }
  }, [values, touched])

  return {
    values,
    updateValues,
    errors,
    updateErrors,
    touched,
    updateTouched,
    submitAttemptCount,
    updateSubmitAttemptCount,
    isSubmitting,
    updateIsSubmitting,
    isValidating,
    validateOnChange,
    validateOnBlur,
    getFieldProps,
    getFieldError,
    handleSubmit
  }
}

export const isObject = obj => obj !== null && typeof obj === 'object'

/**
 * Recursively a set the same value for all keys and arrays nested object, cloning
 * @param object
 * @param value
 * @param visited
 * @param response
 */
export function setNestedObjectValues (
  object,
  value,
  visited = new WeakMap(),
  response = {}
) {
  for (let k of Object.keys(object)) {
    const val = object[k]
    if (isObject(val)) {
      if (!visited.get(val)) {
        visited.set(val, true)
        // In order to keep array values consistent for both dot path  and
        // bracket syntax, we need to check if this is an array so that
        // this will output  { friends: [true] } and not { friends: { "0": true } }
        response[k] = Array.isArray(val) ? [] : {}
        setNestedObjectValues(val, value, visited, response[k])
      }
    } else {
      response[k] = value
    }
  }

  return response
}
