/**
 * @file A component for viewing school overview data
 * @author Julius Diaz Panoriñgan
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import ProficiencyBar from 'components/ProficiencyBar'
import Loading from 'shared/components/Loading'
import LessonHighlight from './LessonHighlight'
import { Col, Row, Card, Icon } from 'antd'
import React from 'react'

import './DataOverview.less'

const DataOverview = ({ data, loading }) => {
  if (loading) {
    return <Loading />
  }
  // first request has not been sent yet
  if (Object.keys(data).length === 0) {
    return <></>
  }

  return (
    <div className='data-overview'>
      {data.proficiencyCounts && (
        <ProficiencyBar proficiencyCounts={data.proficiencyCounts} />
      )}
      <Card>
        <Row type='flex' className='stats'>
          <Col span={8}>
            <Stat
              value={data.percentLikingCoding || 'N/A %'}
              icon='heart'
              text='of students say they like coding'
            />
          </Col>
          {/** @todo change to lines of code */}
          <Col span={8}>
            <Stat
              value={data.challengesCompleted}
              icon='code'
              text='challenges completed this school year'
            />
          </Col>
          {/* <Col span={8}>
            <Stat
              value='10K'
              icon='code'
              text='lines of code this school year'
            />
          </Col> */}
          <Col span={8}>
            <Stat
              value={data.codingTime}
              icon='hourglass'
              text='hours of student coding time'
            />
          </Col>
        </Row>
      </Card>
      {data.randomRecentLesson && (
        <LessonHighlight {...data.randomRecentLesson} />
      )}
    </div>
  )
}

export default DataOverview

const Stat = ({ value, icon, text }) => (
  <Row type='flex' align='middle' justify='center' className='statistic'>
    <Col span={7}>
      <Icon type={icon} />
    </Col>
    <Col span={17}>
      <div className='value'>{value}</div>
      <div className='text'>{text}</div>
    </Col>
  </Row>
)
