/**
 * @file a hook for getting all GIT districts and schools
 * @author Ana Mesias
 */
import { useEffect, useState } from 'react'
import { rpc } from 'shared/utils/api'

/**
 * @function useGitDistrictsAndSchools
 * @returns {Array.<Object>}
 */
const useGitDistrictsAndSchools = () => {
  const [loadingData, setLoading] = useState(true)
  const [data, setData] = useState({ districtData: {}, schoolData: {} })

  useEffect(() => {
    setLoading(true)
    rpc('gradualRelease.getDistrictsAndSchools')
      .then(({ data }) => {
        const districtData = {}
        const schoolData = {}
        for (const district of data) {
          const { displayName, id, schools } = district
          districtData[id] = { displayName, schools }

          for (const school of schools) {
            schoolData[school.id] = {
              displayName: school.displayName,
              districtId: id
            }
          }
        }
        setData({ districtData, schoolData })
        setLoading(false)
      })
      .catch(console.error)
  }, [])

  return [data, loadingData]
}

export default useGitDistrictsAndSchools
