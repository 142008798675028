import { Modal, message } from 'antd'

export default (
  key,
  {
    removeFromSchoolModal,
    currentClass = {},
    archiveUserModal,
    openPermissions,
    currentSchool,
    openEditStudent,
    openTeacherSupport,
    user,
    rpc
  }
) => {
  const { id, displayName, email, district, role } = user
  const { teachers = [], id: classId } = currentClass
  const [classRole, endpoint] = teachers.includes(id)
    ? ['teacher', 'class.removeTeacher']
    : ['student', 'class.removeStudent']
  switch (key) {
    case 'permissions':
      return openPermissions()
    case 'editStudentInfo':
      return openEditStudent()
    case 'support':
      return openTeacherSupport()
    case 'resendInvite':
      return rpc('user.resendInvite', {
        school: currentSchool.id,
        district,
        email,
        role
      }).then(() => message.success('Invite Sent'))
    case 'removeFromClass':
      return Modal.confirm({
        title: `Remove ${classRole}?`,
        content: `Are you sure you would like to remove ${displayName} as a ${classRole} from this class?`,
        onOk: () =>
          rpc(endpoint, {
            class: classId,
            [classRole]: id
          })
      })
    case 'removeFromSchool':
      return removeFromSchoolModal()
    case 'archiveUser':
      return archiveUserModal()
  }
}
