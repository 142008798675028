import { alphaCompare, getRoleDisplay, isRosterSynced } from 'shared/utils'
import SyncProviderBadge from 'shared/components/SyncProviderBadge'
import { support as supportLevels } from 'shared/utils/data'
import { useClassMembers } from 'shared/firestore/hooks'
import isNinedotsRole from 'shared/utils/isNinedotsRole'
import useDistrict from 'hooks/useDistrict/useDistrict'
import { usersBySchool } from 'shared/firestore/refs'
import useFirestore from 'shared/hooks/firestore'
import { Switch, Route } from 'react-router-dom'
import { Table, Card, Tooltip } from 'antd'
import useClasses from 'hooks/useClasses'
import ClassTagger from './ClassTagger'
import useOrgs from 'hooks/useOrgs'
import PropTypes from 'prop-types'
import UserMenu from './UserMenu'
import reduce from '@f/reduce'
import React from 'react'

import './PeopleTable.less'

const SchoolTable = props => {
  const { currentSchool } = useClasses()
  const [snap, loading] = useFirestore(usersBySchool(currentSchool))
  const users = snap
    ? snap.docs.map(doc => ({ id: doc.id, ...doc.data() })).sort(nameSort)
    : []

  return <PeopleTable loading={loading} users={users} {...props} />
}

const ClassTable = props => {
  const { currentClass } = useClasses()
  const [users, loading] = useClassMembers(currentClass.id)

  return (
    <PeopleTable loading={loading} users={users.sort(nameSort)} {...props} />
  )
}

const PendingTable = props => {
  const { currentSchool } = useClasses()
  const [snap, loading] = useFirestore(
    usersBySchool(currentSchool).where('invitePending', '==', true)
  )
  const users = snap
    ? snap.docs.map(doc => ({ id: doc.id, ...doc.data() })).sort(nameSort)
    : []

  return <PeopleTable loading={loading} users={users} {...props} />
}

const PeopleTable = ({ loading, users, viewerRole }) => {
  const { orgs } = useOrgs()
  const { currentClass, currentSchool } = useClasses()
  const [district] = useDistrict(currentSchool.district)
  const idType = district && district.uniqueIdField
  const isViewerNinedots = isNinedotsRole(viewerRole)

  return (
    <Card style={{ minHeight: 'calc(100vh - 203px)' }}>
      <ClassTagger currentClass={currentClass} currentSchool={currentSchool} />
      <Table
        className='people-table'
        loading={loading || idType === null}
        columns={columns(
          orgs,
          currentSchool,
          currentClass,
          idType,
          isViewerNinedots
        )}
        rowKey={({ id }) => id}
        dataSource={users} />
    </Card>
  )
}

PeopleTable.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array,
  viewerRole: PropTypes.string
}

export default props => {
  const { currentSchool } = useClasses()
  if (!currentSchool) return <React.Fragment />

  return (
    <Switch>
      <Route
        path='/people/:schoolId/pending'
        render={() => <PendingTable {...props} />} />
      <Route
        path='/people/:schoolId/:classId'
        render={() => <ClassTable {...props} />} />
      <Route
        path='/people/:schoolId'
        render={() => <SchoolTable {...props} />} />
    </Switch>
  )
}

const columns = (orgs, currentSchool, currentClass, idType, isViewerNinedots) => {
  const mainColumns = [
    {
      title: 'First',
      key: 'family',
      dataIndex: 'name.given',
      width: 300,
      sorter: (a, b) => alphaCompare(a, b, 'name.given'),
      className: 'first-name',
      render: name => <div className='ellipsis'>{name}</div>
    },
    {
      title: 'Last',
      dataIndex: 'name.family',
      width: 300,
      sorter: (a, b) => alphaCompare(a, b, 'name.family'),
      className: 'last-name',
      render: name => <div className='ellipsis'>{name}</div>
    },
    {
      title: 'Role',
      render: ({ roles = {}, support }) =>
        getRoles(roles, currentSchool)
          .map(getRoleDisplay)
          .join(', ')
          .concat(
            roles.teacher && roles.teacher.length && isViewerNinedots && support
              ? ` (${support} support)`
              : ''
          ),
      width: 300,
      sorter: roleSort
    }
  ]

  const idColumns = []
  switch (idType) {
    case 'email':
      idColumns.push({
        title: 'Email',
        dataIndex: 'email',
        width: 300,
        className: 'email',
        render: email => <div className='ellipsis'>{email}</div>
      })
      break
    case 'stateId':
      idColumns.push({
        title: 'State ID',
        dataIndex: 'stateId',
        width: 150
      })
      break
  }

  const providerColumns = []
  if (isRosterSynced(currentSchool)) {
    providerColumns.push({
      width: 80,
      align: 'right',
      render: user => {
        const { providerDisplayName } = isRosterSynced(user) || {}
        return (
          providerDisplayName && (
            <Tooltip
              title={`${providerDisplayName} Synced`}
              style={{ cursor: 'default' }}>
              <SyncProviderBadge provider={providerDisplayName} size='small' />
            </Tooltip>
          )
        )
      }
    })
  }

  const actionColumns = [
    {
      title: 'Actions',
      className: 'actions-column',
      width: 100,
      render: user => (
        <UserMenu
          currentSchool={currentSchool}
          currentClass={currentClass}
          orgs={orgs}
          user={user} />
      )
    }
  ]

  return [...mainColumns, ...idColumns, ...providerColumns, ...actionColumns]
}

function getRoles (roles = {}, { district, id }) {
  const ids = [district, id]
  return reduce(
    (acc, val, type) =>
      ids.some(id => val.includes(id)) ? acc.concat(type) : acc,
    [],
    roles
  )
}

function roleSort (a, b) {
  const roles = ['districtAdmin', 'admin', 'teacher', 'student']
  const supports = supportLevels.map(level => level.value).sort()
  return roles.indexOf(a.role) - roles.indexOf(b.role) ||
    supports.indexOf(a.support) - supports.indexOf(b.support)
}

const nameSort = (a, b) => alphaCompare(a, b, 'name.given')
