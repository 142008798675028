import { gradeValueToId, gradeValueToShortText } from 'shared/utils/data'

/**
 * @function gradesToLabel
 * @author Giovanni Bonilla
 * @description Given an array of grades, concatenates into one string
 * @param {Array.<String>} grades
 * @returns {String}
 */
export default function gradesToLabel(grades) {
  return (
    grades
      .sort((a, b) => gradeValueToId(a) - gradeValueToId(b))
      .map(grade => gradeValueToShortText(grade))
      .join(', ') || '—'
  )
}
