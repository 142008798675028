/**
 * @file util functions for getting and parsing school overview data
 * @author Julius Diaz Panoriñgan
 * @author Andrew Mazariegos
 */
import numbro from 'numbro'

import { gradeValueToId, grades as allGrades } from 'shared/utils/data'
import { rpc } from 'shared/utils/api'

// GENERAL UTILS

export const FILTER_CATEGORIES_IDS = Object.freeze({
  DISTRICTS: 'districts',
  SCHOOLS: 'schools',
  GRADES: 'grades',
  NINE_DOTS_MEMBERS: 'ninedotsMembers',
  TEACHERS: 'teachers',
  SCHOOL_YEARS: 'schoolYears',
  GR_LEVELS: 'grLevels'
})

export const DATA_DASHBOARD_PAGES = Object.freeze({
  OVERVIEW: 'overview',
  PROFICIENCY: 'proficiency',
  TEACHER_ENGAGEMENT: 'engagement',
  MOTIVATION: 'motivation'
})

export const PAGES_WITH_COMPARISONS = Object.freeze([
  DATA_DASHBOARD_PAGES.MOTIVATION,
  DATA_DASHBOARD_PAGES.PROFICIENCY
])

/*
keeps track of what filters to include in the request for each dashboard section/page.
NOTE: order of filter categories determines order of filters displayed in UI
*/
export const FILTERS_BY_DASHBOARD_SECTION = Object.freeze({
  [DATA_DASHBOARD_PAGES.OVERVIEW]: {
    [FILTER_CATEGORIES_IDS.SCHOOL_YEARS]: true,
    [FILTER_CATEGORIES_IDS.DISTRICTS]: true,
    [FILTER_CATEGORIES_IDS.SCHOOLS]: true,
    [FILTER_CATEGORIES_IDS.GRADES]: true,
    [FILTER_CATEGORIES_IDS.NINE_DOTS_MEMBERS]: true,
    [FILTER_CATEGORIES_IDS.TEACHERS]: true,
    [FILTER_CATEGORIES_IDS.GR_LEVELS]: true
  },
  [DATA_DASHBOARD_PAGES.PROFICIENCY]: {
    [FILTER_CATEGORIES_IDS.SCHOOL_YEARS]: true,
    [FILTER_CATEGORIES_IDS.DISTRICTS]: true,
    [FILTER_CATEGORIES_IDS.SCHOOLS]: true,
    [FILTER_CATEGORIES_IDS.GRADES]: true,
    [FILTER_CATEGORIES_IDS.NINE_DOTS_MEMBERS]: true,
    [FILTER_CATEGORIES_IDS.TEACHERS]: true,
    [FILTER_CATEGORIES_IDS.GR_LEVELS]: true
  },
  [DATA_DASHBOARD_PAGES.TEACHER_ENGAGEMENT]: {
    [FILTER_CATEGORIES_IDS.SCHOOL_YEARS]: true,
    [FILTER_CATEGORIES_IDS.DISTRICTS]: true,
    [FILTER_CATEGORIES_IDS.SCHOOLS]: true,
    [FILTER_CATEGORIES_IDS.GRADES]: true,
    [FILTER_CATEGORIES_IDS.TEACHERS]: true,
    [FILTER_CATEGORIES_IDS.GR_LEVELS]: true
  },
  [DATA_DASHBOARD_PAGES.MOTIVATION]: {
    [FILTER_CATEGORIES_IDS.SCHOOL_YEARS]: true,
    [FILTER_CATEGORIES_IDS.DISTRICTS]: true,
    [FILTER_CATEGORIES_IDS.SCHOOLS]: true,
    [FILTER_CATEGORIES_IDS.GRADES]: true,
    [FILTER_CATEGORIES_IDS.NINE_DOTS_MEMBERS]: true,
    [FILTER_CATEGORIES_IDS.TEACHERS]: true,
    [FILTER_CATEGORIES_IDS.GR_LEVELS]: true
  }
})

export const DATA_REDUCER_ACTION_TYPES = {
  SET_FILTER_GROUPS: 'SET_FILTER_GROUPS',
  FILTER_CHANGE: 'FILTER_CHANGE',
  GET_DATA: 'GET_DATA',
  ADD_FILTER_GROUP: 'ADD_FILTER_GROUP',
  DELETE_FILTER_GROUP: 'DELETE_FILTER_GROUP',
  UPDATE_LABEL: 'UPDATE_LABEL',
  UPDATE_COLOR: 'UPDATE_COLOR',
  RESET_FILTER_GROUPS: 'RESET_FILTER_GROUPS'
}

export const rpcCalls = {
  [DATA_DASHBOARD_PAGES.OVERVIEW]: (requestBody, options) =>
    rpc('school.getOverviewData', requestBody, options).then(data => ({
      proficiencyCounts: processProficiencyCounts(data.proficiencyCounts),
      percentLikingCoding: formatPercent(data.percentLikingCoding),
      challengesCompleted: formatShortScale(data.challengesCompleted),
      codingTime: formatShortScale(data.codingTime),
      randomRecentLesson: data.randomRecentLesson
    })),
  [DATA_DASHBOARD_PAGES.PROFICIENCY]: handleProficiencyRequests,
  [DATA_DASHBOARD_PAGES.TEACHER_ENGAGEMENT]: (requestBody, options) =>
    rpc('school.getTeacherEngagement', requestBody, options).then(
      ({ teacherEngagementData }) => teacherEngagementData
    ),
  [DATA_DASHBOARD_PAGES.MOTIVATION]: handleMotivationRequests
}

/**
 * Sends a request to retrieve data for each filter group in filterGroups
 * @function handleComparisonRequests
 * @param {String} dashboardPage
 * @param {Object} filterGroups
 * @param {Object} options
 * @param {Function} singleRequestFunc
 * @returns {Promise<Array>}
 */
const handleComparisonRequests = async (
  dashboardPage,
  filterGroups,
  options,
  singleRequestFunc
) => ({
  dataResults: await Promise.all(
    filterGroups.map(({ filterState }, index) =>
      singleRequestFunc(
        formatRequestBody(dashboardPage, filterState),
        options,
        index
      )
    )
  ),
  graphInfo: filterGroups.map(({ label, color }) => ({ label, color }))
})

/**
 * Builds a request body for a data dashboard endpoint based on the current data page being viewed
 * and a single filter group's data
 * @function formatRequestBody
 * @param {String} currentDataPage
 * @param {Object} filterState
 * @returns {Object}
 */
export const formatRequestBody = (currentDataPage, filterState) =>
  Object.keys(FILTERS_BY_DASHBOARD_SECTION[currentDataPage]).reduce(
    (accObj, filterCategory) => {
      const currFilter = filterState[filterCategory]
      // if grade filter is empty set to all options to only include GC classes
      if (
        filterCategory === FILTER_CATEGORIES_IDS.GRADES &&
        currFilter.selectedValues.length === 0
      ) {
        accObj[filterCategory] = currFilter.options.map(({ id }) =>
          id.toString()
        )
      } else {
        accObj[filterCategory] = currFilter.selectedValues.map(id =>
          id.toString()
        )
      }

      return accObj
    },
    {}
  )

// DATA OVERVIEW UTILS

/**
 * Given a proficiency counts object, returns an array of proficiency counts.
 * If there are no students with proficiency data, returns null instead.
 * @param {!Object.<?string>} rawProficiencyCounts
 * @returns {?Array.<number>}
 */
const processProficiencyCounts = rawProficiencyCounts => {
  const { p1, p2, p3, p4 } = rawProficiencyCounts
  const proficiencyCounts = [p1, p2, p3, p4].map(count =>
    count === null ? 0 : parseInt(count, 10)
  )
  return proficiencyCounts.some(count => count > 0) ? proficiencyCounts : null
}

/**
 * Formats a percent to have no decimal point.
 * If passed null, returns null.
 * @function formatPercent
 * @param {?number} rawPercent
 * @returns {?string}
 */
const formatPercent = rawPercent =>
  rawPercent && numbro(rawPercent).format({ output: 'percent', mantissa: 0 })

/**
 * Abbreviates a number according to the short scale naming system.
 * https://en.wikipedia.org/wiki/Long_and_short_scales
 * http://numbrojs.com/format.html#average
 * @function formatShortScale
 * @param {!number} num
 * @returns {string}
 */
const formatShortScale = num =>
  numbro(num)
    .format({ average: true })
    .toUpperCase()

// DATA PROFICIENCY UTILS

/**
 * @function aggregateAndFormatProficiencyData
 * @author Andrew Mazariegos
 * based on skeleton code  written by:
 * @author Julius Diaz Panoriñgan
 * @param {Array} proficiencyData
 * @returns {Array}
 */
const aggregateAndFormatProficiencyData = proficiencyData =>
  proficiencyData.map(c => {
    const { class: classId, grade: gradeText, displayName } = c
    const gradeNum = gradeValueToId(gradeText)
    const p1 = parseInt(c.p1, 10)
    const p2 = parseInt(c.p2, 10)
    const p3 = parseInt(c.p3, 10)
    const p4 = parseInt(c.p4, 10)
    const numWithProficiencyData = p1 + p2 + p3 + p4
    return {
      classId,
      numLessons: parseInt(c.num_lessons, 10),
      avgPracticeTime: parseFloat(c.avg_lesson_practice_time),
      p1,
      p2,
      p3,
      p4,
      noProficiencyData: parseInt(c.no_proficiency_data, 10),
      // for a class w/ no proficiency data yet, we use the sentinel 0
      avgProficiency: numWithProficiencyData
        ? (p1 + 2 * p2 + 3 * p3 + 4 * p4) / numWithProficiencyData
        : 0,

      gradeText,
      gradeNum,
      displayName
    }
  })

/**
 * Given an array of proficiency data, returns a four-element array with the
 * counts of students at proficiency level 1, 2, 3, and 4.
 * @function generateProficiencyCounts
 * @author Julius Diaz Panoriñgan
 * @param {Array} proficiencyData
 * @returns {Array}
 */
function generateProficiencyCounts(proficiencyData) {
  return proficiencyData
    ? proficiencyData.reduce(
        (counts, { p1, p2, p3, p4 }) => {
          counts[0] += parseInt(p1)
          counts[1] += parseInt(p2)
          counts[2] += parseInt(p3)
          counts[3] += parseInt(p4)
          return counts
        },
        [0, 0, 0, 0]
      )
    : [0, 0, 0, 0]
}

/**
 * Given an array of proficiency data and an array of proficiency counts,
 * returns an object aggregating all the given data, in a format that matches
 * a proficiency data object (with some exclusions that are not required for
 * displaying the data in this component).
 * @function generateProficiencyAggregate
 * @author Julius Diaz Panoriñgan
 * @param {Array} proficiencyData
 * @param {Array} proficiencyCounts
 * @returns {Object}
 */
function generateProficiencyAggregate(proficiencyData, proficiencyCounts) {
  const numLessons = proficiencyData.reduce(
    (total, { numLessons }) => total + numLessons,
    0
  )

  const totalTime = proficiencyData.reduce(
    (total, { avgPracticeTime, numLessons }) =>
      total + avgPracticeTime * numLessons,
    0
  )

  const numStudents = proficiencyCounts.reduce((total, count) => total + count)

  const proficiencySum = proficiencyCounts.reduce(
    (sum, count, i) => sum + count * (i + 1)
  )

  return {
    classId: '',
    displayName: 'All Classes',
    gradeText: '—',
    numLessons,
    avgPracticeTime: totalTime / numLessons,
    avgProficiency: proficiencySum / numStudents
  }
}

/**
 * Handles proficiency data formatting, aggregation, and calculations
 * from 'school.getProficiencyData' rpc call
 * @function processProficiencyData
 * @author Andrew Mazariegos
 * based on skeleton code  written by:
 * @author Julius Diaz Panoriñgan
 * @param {Object} data
 * @returns {Object}
 */
const processProficiencyData = data => {
  const { proficiencyData: rawProficiencyData } = data

  const formattedProficiencyData = aggregateAndFormatProficiencyData(
    rawProficiencyData
  )
  const proficiencyCounts = generateProficiencyCounts(rawProficiencyData)
  const proficiencyData = [
    generateProficiencyAggregate(formattedProficiencyData, proficiencyCounts),
    ...formattedProficiencyData
  ]

  return { proficiencyData, proficiencyCounts }
}

/**
 * Handles rpc call logic for the proficiency data dashboard page
 * based on whether there is a grade filter applied or not
 * @function handleSingleProficiencyRequest
 * @author Andrew Mazariegos
 * based on skeleton code  written by:
 * @author Julius Diaz Panoriñgan
 * @param {Object} requestBody
 * @param {Object} options
 * @param {Number} compIndex
 * @returns {Object}
 */
const handleSingleProficiencyRequest = (requestBody, options, compIndex) => {
  // use school wide data if no grade filter applied
  if (
    requestBody.grades.length ===
    allGrades.filter(({ id }) => -1 <= id && id <= 6).length
  ) {
    // only call getProficiencyData for the first filter group
    const proficiencyDataPromise =
      compIndex === 0
        ? rpc('school.getProficiencyData', requestBody, options).then(data => {
            const { proficiencyData } = processProficiencyData(data)
            return proficiencyData
          })
        : null

    const proficiencyCountsPromise = rpc(
      'school.getProficiencyOverview',
      requestBody,
      options
    ).then(({ proficiencyCounts }) =>
      processProficiencyCounts(proficiencyCounts)
    )

    return Promise.all([proficiencyDataPromise, proficiencyCountsPromise]).then(
      ([proficiencyData, proficiencyCounts]) => {
        return {
          proficiencyData,
          proficiencyCounts
        }
      }
    )
  } else {
    return rpc('school.getProficiencyData', requestBody, options).then(data =>
      processProficiencyData(data)
    )
  }
}

async function handleProficiencyRequests(filterGroups, options) {
  const isComparingData = filterGroups.length > 1
  // get results for each filter group
  const { dataResults, graphInfo } = await handleComparisonRequests(
    DATA_DASHBOARD_PAGES.PROFICIENCY,
    filterGroups,
    options,
    handleSingleProficiencyRequest
  )

  return isComparingData
    ? {
        proficiencyData: dataResults[0].proficiencyData,
        proficiencyPercents: dataResults.map(
          ({ proficiencyCounts }, compIndex) => {
            // calculate proficiency percentage
            const totalCount = proficiencyCounts.reduce(
              (sum, count) => sum + count,
              0
            )
            // calculate number meeting or exceeding proficiency
            const numProficient = proficiencyCounts
              .slice(-2)
              .reduce((sum, count) => sum + count, 0)

            return {
              compIndex,
              proficiencyPercent: (numProficient / totalCount) * 100
            }
          }
        ),
        graphInfo
      }
    : {
        ...dataResults[0],
        graphInfo
      }
}

// DATA MOTIVATION UTILS

const handleSingleMotivationRequest = async (requestBody, options) =>
  rpc(
    'motivation.getStudentMotivationData',
    requestBody,
    options
  ).then(({ surveyData, interestData }) => ({ surveyData, interestData }))

async function handleMotivationRequests(filterGroups, options) {
  // get results for each filter group
  const {
    dataResults: responseData,
    graphInfo
  } = await handleComparisonRequests(
    DATA_DASHBOARD_PAGES.MOTIVATION,
    filterGroups,
    options,
    handleSingleMotivationRequest
  )

  return {
    responseData,
    graphInfo
  }
}
