import SyncProviderBadge from 'shared/components/SyncProviderBadge'
import CreateSchoolModal from '../Schools/CreateSchoolModal'
import HeaderLayout from 'components/HeaderLayout'
import { ClassesProvider } from 'hooks/useClasses'
import { useModal } from 'shared/hooks/useModal'
import { Row, Col, Tooltip, Icon } from 'antd'
import Header from 'shared/components/Header'
import { isRosterSynced } from 'shared/utils'
import { withRouter } from 'react-router-dom'
import ContextMenu from './ContextMenu'
import PeopleTable from './PeopleTable'
import { connect } from 'react-redux'
import ActionMenu from './ActionMenu'
import useOrgs from 'hooks/useOrgs'
import React from 'react'
import './People.less'

const People = ({
  match: {
    params: { schoolId, classId }
  },
  profile: { role }
}) => {
  const { orgs } = useOrgs()
  const { schools = [], district = {} } = orgs
  const currentSchool = schools.find(({ id }) => id === schoolId) || {}
  const { providerDisplayName } = isRosterSynced(currentSchool) || {}

  const [openSettings] = useModal(
    <CreateSchoolModal edit school={currentSchool} district={district} />
  )

  return (
    <ClassesProvider schoolId={schoolId} classId={classId}>
      <HeaderLayout
        header={
          <Header
            title={
              <Row gutter={10} type='flex' align='middle'>
                <Col>{currentSchool.displayName}</Col>
                <Col>
                  {providerDisplayName && (
                    <Tooltip
                      title={`${providerDisplayName} Synced`}
                      placement='bottom'
                    >
                      <SyncProviderBadge
                        provider={providerDisplayName}
                        className='clever-school-badge'
                        size='small'
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            }
            extra={
              role !== 'ninedotsTeacher' && (
                <a className='settings-link' onClick={openSettings}>
                  <Icon type='setting' />
                  &ensp;School Settings
                </a>
              )
            }
          />
        }
      >
        <Row type='flex'>
          <Col
            className={
              'people-context-menu-container' +
              (schools.length > 1 ? ' has-schools' : '')
            }
          >
            <ContextMenu />
          </Col>
          <Col className='flex-grow'>
            <ActionMenu />
            <PeopleTable viewerRole={role}/>
          </Col>
        </Row>
      </HeaderLayout>
    </ClassesProvider>
  )
}

People.propTypes = {}

export default connect(
  ({ firebase: { profile } }) => ({ profile }),
  withRouter
)(People)
