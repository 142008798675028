import map from '@f/map'

export default function trimValues (values, omitted) {
  const omitFields = Array.isArray(omitted) ? omitted : [omitted]
  if (omitted && omitFields.some(omit => typeof omit !== 'string')) {
    throw new Error('omitted must be string or array of strings')
  }
  return map(val => {
    if (!val) return val
    if (typeof val === 'object') return trimValues(val, omitted)
    return typeof val === 'string' && omitFields.indexOf(val) === -1
      ? val.trim()
      : val
  }, values)
}
