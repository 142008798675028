import { useEffect, useState, useMemo, useCallback, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import useGitDistrictsAndSchools from 'hooks/useGitDistrictsAndSchools'
import { grades as allGrades, support } from 'shared/utils/data'
import {
  nineDotsMembers,
  teachers,
  schoolYearsRef
} from 'shared/firestore/refs'
import useFirestore from 'shared/hooks/firestore/useFirestore'
import palette from 'shared/theme/vars/palette'
import {
  rpcCalls,
  DATA_DASHBOARD_PAGES,
  PAGES_WITH_COMPARISONS,
  FILTER_CATEGORIES_IDS,
  DATA_REDUCER_ACTION_TYPES,
  formatRequestBody
} from './utils'
import isEqual from 'lodash/isEqual'

const useDataDashboard = () => {
  const location = useLocation()
  const [
    { districtData, schoolData },
    loadingDistrictsAndSchools
  ] = useGitDistrictsAndSchools()
  // base filter data when page is changed or comparisons are added, both gets finalized when all filter data is loaded
  const [baseFilterStates, setBaseFilterStates] = useState({
    default: INITIAL_FILTERS_STATE,
    comparison: INITIAL_FILTERS_STATE
  })

  const filterGroupsReducer = useCallback(
    (state, action) => {
      const { type, payload } = action
      switch (type) {
        case DATA_REDUCER_ACTION_TYPES.SET_FILTER_GROUPS: {
          const { filterState } = payload
          return [
            {
              ...state[0],
              filterState: filterState
            }
          ]
        }
        case DATA_REDUCER_ACTION_TYPES.RESET_FILTER_GROUPS: {
          return [
            {
              label: 'Default',
              color: DEFAULT_GROUP_COLORS[0],
              filterState: baseFilterStates.default
            }
          ]
        }
        case DATA_REDUCER_ACTION_TYPES.FILTER_CHANGE: {
          const { category, selectedValue, filterGroupIndex = 0 } = payload
          const newFilterGroups = [...state]
          newFilterGroups[filterGroupIndex] = {
            ...state[filterGroupIndex],
            filterState: getUpdatedFilterCategoriesOnFilterChange(
              category,
              selectedValue,
              state[filterGroupIndex].filterState
            )
          }
          return newFilterGroups
        }
        case DATA_REDUCER_ACTION_TYPES.ADD_FILTER_GROUP: {
          return [
            ...state,
            {
              label: `Comparison ${state.length}`,
              color: DEFAULT_GROUP_COLORS[state.length % 4],
              filterState: baseFilterStates.comparison
            }
          ]
        }
        case DATA_REDUCER_ACTION_TYPES.DELETE_FILTER_GROUP: {
          const { filterGroupIndex } = payload
          return state.length > 1
            ? state.filter((_, i) => filterGroupIndex !== i)
            : state
        }
        case DATA_REDUCER_ACTION_TYPES.UPDATE_LABEL: {
          const { filterGroupIndex, value } = payload
          const updatedFilterGroups = [...state]
          updatedFilterGroups[filterGroupIndex] = {
            ...state[filterGroupIndex],
            label: value
          }
          return updatedFilterGroups
        }
        case DATA_REDUCER_ACTION_TYPES.UPDATE_COLOR: {
          const { filterGroupIndex, value } = payload
          const updatedFilterGroups = [...state]
          updatedFilterGroups[filterGroupIndex] = {
            ...state[filterGroupIndex],
            color: value
          }
          return updatedFilterGroups
        }
        default:
          return
      }
    },
    [baseFilterStates]
  )
  const [filterGroups, dispatchFilterGroupsAction] = useReducer(
    filterGroupsReducer,
    [
      {
        filterState: INITIAL_FILTERS_STATE,
        // used for pages with graph comparisons
        label: 'Default',
        color: DEFAULT_GROUP_COLORS[0]
      }
    ]
  )
  // current section data should only be null prior to first request response being received
  const [sectionData, setSectionData] = useState(EMPTY_SECTION_DATA_OBJ)
  const [schoolYearsSnap, schoolYearsLoading] = useFirestore(schoolYearsRef(), {
    get: true
  })

  const [nineDotsUsersSnap, nineDotsUsersLoading] = useFirestore(
    nineDotsMembers(),
    {
      get: true
    }
  )
  const [teachersSnap, teachersLoading] = useFirestore(teachers(), {
    get: true
  })

  const [numFetchingDataCalls, setNumFetchingDataCalls] = useState(0)
  const [abortControllers, setAbortControllers] = useState([])

  const currentDataPage = useMemo(() => {
    const pathArray = location.pathname.split('/')
    return pathArray[pathArray.length - 1]
  }, [location])

  // if current section data is not null, the first request response has been received
  const initialDataReqReceived = !!sectionData[currentDataPage]

  const initialFiltersLoading =
    loadingDistrictsAndSchools ||
    nineDotsUsersLoading ||
    teachersLoading ||
    schoolYearsLoading

  useEffect(() => {
    // set all filter category states when they finish loading
    if (!initialFiltersLoading) {
      const baseFilterState = {
        ...INITIAL_FILTERS_STATE,
        ...processSchoolYearsSnap(schoolYearsSnap),
        ...processOrgFilterData(FILTER_CATEGORIES_IDS.DISTRICTS, districtData),
        ...processOrgFilterData(FILTER_CATEGORIES_IDS.SCHOOLS, schoolData),
        ...processUserSnapData(
          FILTER_CATEGORIES_IDS.NINE_DOTS_MEMBERS,
          nineDotsUsersSnap
        ),
        ...processUserSnapData(FILTER_CATEGORIES_IDS.TEACHERS, teachersSnap)
      }
      dispatchFilterGroupsAction({
        type: DATA_REDUCER_ACTION_TYPES.SET_FILTER_GROUPS,
        payload: { filterState: baseFilterState }
      })
      setBaseFilterStates({
        default: baseFilterState,
        comparison: {
          ...baseFilterState,
          schoolYears: {
            ...baseFilterState.schoolYears,
            selectedValues: []
          },
          ninedotsMembers: {
            ...baseFilterState.ninedotsMembers,
            options: baseFilterState.ninedotsMembers.originalOptions
          },
          teachers: {
            ...baseFilterState.teachers,
            options: baseFilterState.teachers.originalOptions
          }
        }
      })
    }
  }, [
    initialFiltersLoading,
    schoolYearsSnap,
    districtData,
    schoolData,
    nineDotsUsersSnap,
    teachersSnap
  ])

  const handleGetData = useCallback(() => {
    if (Object.values(DATA_DASHBOARD_PAGES).includes(currentDataPage)) {
      // used to terminate old requests if a new request is sent due to updates
      const controller = new AbortController()
      const signal = controller.signal

      rpcCalls[currentDataPage](
        // if current page allows for comparisons process all filter groups and data is being compared,
        // otherwise format a request body for the only filter group
        PAGES_WITH_COMPARISONS.includes(currentDataPage)
          ? filterGroups
          : formatRequestBody(currentDataPage, filterGroups[0].filterState),
        {
          signal
        }
      )
        .then(data => {
          setNumFetchingDataCalls(prev => prev - 1)
          setSectionData({
            ...EMPTY_SECTION_DATA_OBJ,
            [currentDataPage]: data
          })
        })
        .catch(() => {
          setNumFetchingDataCalls(prev => prev - 1)
        })

      setNumFetchingDataCalls(prev => prev + 1)
      setAbortControllers(prev => [...prev, controller])
    }
  }, [currentDataPage, filterGroups])

  useEffect(() => {
    // abort and remove every controller besides the newest one
    if (abortControllers.length > 1) {
      for (let i = 0; i < abortControllers.length - 1; i++) {
        const controller = abortControllers[i]
        if (!controller.signal.aborted) {
          controller.abort()
        }
      }

      setAbortControllers(prev => prev.slice(-1))
    }
  }, [abortControllers])

  // reset selected filters when current dashboard page is changed
  useEffect(() => {
    if (!isEqual(baseFilterStates.default, INITIAL_FILTERS_STATE)) {
      dispatchFilterGroupsAction({
        type: DATA_REDUCER_ACTION_TYPES.RESET_FILTER_GROUPS
      })
      setSectionData(EMPTY_SECTION_DATA_OBJ)
    }
  }, [currentDataPage, baseFilterStates])

  return {
    filterGroups,
    sectionData,
    currentDataPage,
    loading: !!numFetchingDataCalls || initialFiltersLoading,
    onGetData: handleGetData,
    dispatchFilterGroupsAction
  }
}

const sortByDisplayNames = (a, b) => {
  const nameA = a.displayName.toLowerCase(),
    nameB = b.displayName.toLowerCase()
  if (nameA < nameB) return -1
  else if (nameA > nameB) return 1
  return 0
}

const processSchoolYearsSnap = snap => {
  let currentSchoolYearId = null
  const schoolsYearsOptions = snap.docs
    .map(doc => {
      const { id, displayName, isActive, isDefault } = doc.data()

      if (isActive && isDefault) {
        currentSchoolYearId = id
      }

      return {
        id,
        displayName
      }
    })
    .sort(sortByDisplayNames)

  const schoolsYearsId = FILTER_CATEGORIES_IDS.SCHOOL_YEARS
  return {
    [schoolsYearsId]: {
      ...INITIAL_FILTERS_STATE[schoolsYearsId],
      currentSchoolYearId,
      options: schoolsYearsOptions,
      selectedValues: currentSchoolYearId ? [currentSchoolYearId] : []
    }
  }
}

const processOrgFilterData = (category, filterData) => {
  // used to store the district id for schools to filter options when district filter changes
  const data = {}
  // reformat retrieved options data
  const newOptions = Object.keys(filterData)
    .map(key => {
      const { displayName, districtId: district } = filterData[key]

      if (category === FILTER_CATEGORIES_IDS.SCHOOLS) {
        data[key] = { district }
      }

      return {
        displayName,
        id: key
      }
    })
    .sort(sortByDisplayNames)

  return {
    [category]: {
      ...INITIAL_FILTERS_STATE[category],
      options: newOptions,
      selectedValues: [],
      ...(category === FILTER_CATEGORIES_IDS.SCHOOLS
        ? { originalOptions: newOptions, data }
        : {})
    }
  }
}

/**
 * used to check for and update other filter options when a filter is changed
 * that can effect other filters' options / selected values
 * @function getUpdatedFilterCategoriesOnFilterChange
 * @author Andrew Mazariegos
 * @param {String} category
 * @param {String} selectedValue
 * @param {Object} prevFilterObj
 * @returns {Object} updated filter data for changed category + filter data that was
 * updated based on the changed category's newly selected values
 */
const getUpdatedFilterCategoriesOnFilterChange = (
  category,
  selectedValue,
  prevFilterObj
) => {
  const prevChangedFilterData = prevFilterObj[category]
  const prevSelectedValues = prevChangedFilterData.selectedValues
  const isDeselecting = prevSelectedValues.includes(selectedValue)

  let newSelectedValues = isDeselecting
    ? prevSelectedValues.filter(id => id !== selectedValue)
    : [...prevSelectedValues, selectedValue]

  return {
    ...prevFilterObj,
    [category]: {
      ...prevFilterObj[category],
      selectedValues: newSelectedValues
    },
    // if a filter is changed that effects other filter options, get those updated filter options also
    ...([
      FILTER_CATEGORIES_IDS.DISTRICTS,
      FILTER_CATEGORIES_IDS.SCHOOL_YEARS,
      FILTER_CATEGORIES_IDS.SCHOOLS,
      FILTER_CATEGORIES_IDS.GR_LEVELS
    ].includes(category) &&
      getUpdatedEffectedFiltersOnChange(
        category,
        newSelectedValues,
        prevFilterObj
      ))
  }
}

const processUserSnapData = (category, snap) => {
  /*
  maps users ids to some of their filter-related data. used to filter
  options and selectedValues whenever a related filter change occurs 
  */
  const data = {}

  const parsedSnapData = snap.docs
    .flatMap(doc => {
      const {
        displayName,
        email,
        name = {},
        archived,
        support,
        school,
        district,
        id
      } = doc.data()

      data[id] =
        category === FILTER_CATEGORIES_IDS.TEACHERS
          ? { archived, support, school, district }
          : { archived }

      const { given, family } = name

      const userDisplayName =
        given && family
          ? // for teachers, show family name first
            category === FILTER_CATEGORIES_IDS.TEACHERS
            ? `${family}, ${given}`
            : `${given} ${family}`
          : displayName
          ? displayName
          : email
      return id
        ? {
            id,
            displayName: userDisplayName
          }
        : []
    })
    .sort(sortByDisplayNames)

  return {
    [category]: {
      ...INITIAL_FILTERS_STATE[category],
      // default to filter by non-archived users only on load
      options: parsedSnapData.filter(user => !data[user.id].archived),
      selectedValues: [],
      originalOptions: parsedSnapData,
      data
    }
  }
}

/**
 * used to check for and update other filter options when a filter is changed
 * that can effect other filters' options / selected values
 * @function getUpdatedEffectedFiltersOnChange
 * @author Andrew Mazariegos
 * @param {String} changedCategory
 * @param {Array.<String>} newSelectedValues
 * @param {Object} prevFilterObj
 * @returns {Object} updated filters that were updated based on the changed category's newly selected values
 */
const getUpdatedEffectedFiltersOnChange = (
  changedCategory,
  newSelectedValues,
  prevFilterObj
) => {
  // calculate if now viewing or no longer viewing current school year for filtering users by archived status
  const prevSchoolYearsData = prevFilterObj[FILTER_CATEGORIES_IDS.SCHOOL_YEARS]
  const {
    currentSchoolYearId,
    selectedValues: prevSelectedSchoolYearValues
  } = prevSchoolYearsData

  const isNowViewingCurrentSchoolYearOnly =
    changedCategory === FILTER_CATEGORIES_IDS.SCHOOL_YEARS
      ? newSelectedValues.length === 1 &&
        newSelectedValues.includes(currentSchoolYearId)
      : prevSelectedSchoolYearValues.length === 1 &&
        prevSelectedSchoolYearValues.includes(currentSchoolYearId)

  // used for school years changes, only need to check if the data object is marked as archived
  const filterByArchiveStatus = isNowViewingCurrentSchoolYearOnly
    ? // update filters to show non-archived users only
      categoryOptionData => !categoryOptionData.archived
    : // restore all options, archive status doesn't matter if no longer viewing current school year only
      () => true

  // translates changed category id to stored data key for dependent filters
  const CHANGED_CATEGORY_TO_FILTER_DATA_KEY = {
    [FILTER_CATEGORIES_IDS.DISTRICTS]: 'district',
    [FILTER_CATEGORIES_IDS.SCHOOLS]: 'school',
    [FILTER_CATEGORIES_IDS.GR_LEVELS]: 'support'
  }
  /*
  determines how the dependent categories options will be filtered, only school years changes are
  differently than changes to the districts, schools, or gr level filters
  */
  const filterFuncForChangedCategory =
    changedCategory === FILTER_CATEGORIES_IDS.SCHOOL_YEARS
      ? filterByArchiveStatus
      : (categoryOptionData, selectedValues) =>
          categoryOptionData &&
          /*
            if selectedValues passed in, use it, otherwise use the changed category's newly selected values.
            selectedValues is passed in for the teacher filter when checking other categories that could be filtering teachers
            */
          (selectedValues || newSelectedValues).includes(
            categoryOptionData[
              CHANGED_CATEGORY_TO_FILTER_DATA_KEY[changedCategory]
            ]
          )

  /*
  teachers options can be filtered by multiple filters so every time one of them is changed
  they all need to be applied with the new selected values for the changed category taken into account
  */
  const filterTeachers = () => {
    const prevTeacherFilterData = prevFilterObj[FILTER_CATEGORIES_IDS.TEACHERS]
    const { originalOptions, selectedValues, data } = prevTeacherFilterData

    // get filter categories that are already filtering out some teacher options
    // exclude the category that was just changed since we need to take into account the newly selected values
    const teacherFilterKeysApplied = Object.keys(prevFilterObj).filter(
      filterCategory =>
        filterCategory !== changedCategory &&
        [
          FILTER_CATEGORIES_IDS.DISTRICTS,
          FILTER_CATEGORIES_IDS.SCHOOLS,
          FILTER_CATEGORIES_IDS.GR_LEVELS
        ].includes(filterCategory) &&
        prevFilterObj[filterCategory].selectedValues.length > 0
    )

    return {
      [FILTER_CATEGORIES_IDS.TEACHERS]: {
        ...prevTeacherFilterData,
        options: originalOptions.filter(
          ({ id }) =>
            // handle other filters that are potentially filtering out teachers options already
            teacherFilterKeysApplied.every(filterCategory => {
              const { selectedValues } = prevFilterObj[filterCategory]
              return filterFuncForChangedCategory(data[id], selectedValues)
            }) &&
            // handle changed filter
            (newSelectedValues.length === 0 ||
              filterFuncForChangedCategory(data[id])) &&
            // apply archive status filter if changed category was not school year filter
            (changedCategory !== FILTER_CATEGORIES_IDS.SCHOOL_YEARS
              ? filterByArchiveStatus(data[id])
              : true)
        ),
        selectedValues:
          // less complicated logic for selectedValues since it won't need to restore any selections unlike options
          // if newly selected values is empty, selectedValues stays the same
          newSelectedValues.length === 0
            ? selectedValues
            : selectedValues.filter(id =>
                filterFuncForChangedCategory(data[id])
              )
      }
    }
  }

  // filter a categories options and selected values
  const filterCategoryData = categoryToFilter => {
    const prevFilterData = prevFilterObj[categoryToFilter]
    const { originalOptions, selectedValues, data } = prevFilterData

    return {
      [categoryToFilter]: {
        ...prevFilterData,
        options:
          newSelectedValues.length === 0
            ? originalOptions
            : originalOptions.filter(({ id }) =>
                filterFuncForChangedCategory(data[id])
              ),
        selectedValues:
          newSelectedValues.length === 0
            ? selectedValues
            : selectedValues.filter(({ id }) =>
                filterFuncForChangedCategory(data[id])
              )
      }
    }
  }

  // if changed category is districts, update school and teacher options
  if (changedCategory === FILTER_CATEGORIES_IDS.DISTRICTS) {
    return {
      ...filterCategoryData(FILTER_CATEGORIES_IDS.SCHOOLS),
      ...filterTeachers()
    }
  }
  // if school years filter was changed check if we need to update nine dots members and teachers options
  else if (changedCategory === FILTER_CATEGORIES_IDS.SCHOOL_YEARS) {
    // filter was changed so regardless we know it no longer is viewing current school year if it previously was
    const isNoLongerViewingCurrentSchoolYearOnly =
      prevSelectedSchoolYearValues.includes(currentSchoolYearId) &&
      prevSelectedSchoolYearValues.length === 1
    // need to update users filter options if now viewing or no longer viewing current school year only
    if (
      isNowViewingCurrentSchoolYearOnly ||
      isNoLongerViewingCurrentSchoolYearOnly
    ) {
      return {
        ...filterCategoryData(FILTER_CATEGORIES_IDS.NINE_DOTS_MEMBERS),
        ...filterTeachers()
      }
    }
  }
  // if school or teacher gr level filter was changed update teachers options
  else if (
    changedCategory === FILTER_CATEGORIES_IDS.SCHOOLS ||
    changedCategory === FILTER_CATEGORIES_IDS.GR_LEVELS
  ) {
    return {
      ...filterTeachers()
    }
  }
}

const EMPTY_SECTION_DATA_OBJ = {
  [DATA_DASHBOARD_PAGES.OVERVIEW]: null,
  [DATA_DASHBOARD_PAGES.PROFICIENCY]: null,
  [DATA_DASHBOARD_PAGES.TEACHER_ENGAGEMENT]: null,
  [DATA_DASHBOARD_PAGES.MOTIVATION]: null
}

const EMPTY_OPTIONS_AND_SELECTED_VALUES_OBJ = {
  options: [],
  selectedValues: []
}

const INITIAL_FILTERS_STATE = {
  [FILTER_CATEGORIES_IDS.SCHOOL_YEARS]: {
    ...EMPTY_OPTIONS_AND_SELECTED_VALUES_OBJ,
    currentSchoolYearId: null,
    label: 'School Years'
  },
  [FILTER_CATEGORIES_IDS.DISTRICTS]: {
    ...EMPTY_OPTIONS_AND_SELECTED_VALUES_OBJ,
    label: 'Districts'
  },
  [FILTER_CATEGORIES_IDS.SCHOOLS]: {
    originalOptions: [],
    data: {},
    label: 'Schools',
    ...EMPTY_OPTIONS_AND_SELECTED_VALUES_OBJ
  },
  [FILTER_CATEGORIES_IDS.GRADES]: {
    options: allGrades
      .filter(({ id }) => -1 <= id && id <= 6)
      .map(gradeObj => {
        return {
          id: gradeObj.value,
          displayName: gradeObj.label
        }
      }),
    selectedValues: [],
    label: 'Grades*'
  },
  [FILTER_CATEGORIES_IDS.NINE_DOTS_MEMBERS]: {
    ...EMPTY_OPTIONS_AND_SELECTED_VALUES_OBJ,
    originalOptions: [],
    data: {},
    label: '9 Dots Members*'
  },
  [FILTER_CATEGORIES_IDS.TEACHERS]: {
    ...EMPTY_OPTIONS_AND_SELECTED_VALUES_OBJ,
    originalOptions: [],
    data: {},
    label: 'Teachers'
  },
  [FILTER_CATEGORIES_IDS.GR_LEVELS]: {
    options: support.map(({ value }) => ({
      id: value,
      displayName: value === 'full' ? 'L1' : value === 'partial' ? 'L2' : 'L3'
    })),
    selectedValues: [],
    label: 'Teacher GR Levels'
  }
}

const DEFAULT_GROUP_COLORS = [
  palette['@blue-light'],
  palette['@green'],
  palette['@yellow'],
  palette['@red']
]

export default useDataDashboard
