import useNinedotsMembers from 'shared/firestore/useNinedotsMembers'
import AddToClassModal from 'components/AddToClassModal'
import { useClassMembers } from 'shared/firestore/hooks'
import { useModal } from 'shared/hooks/useModal'
import { getFullName } from 'shared/utils'
import Tagger from 'components/Tagger'
import { Row, Col, Modal } from 'antd'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import get from 'lodash/get'
import React from 'react'
import './ClassTagger.less'

const ClassTagger = ({ currentClass = {}, currentSchool, rpc }) => {
  const { id: classId } = currentClass
  const [teachers = []] = useClassMembers(classId, 'teachers')
  const [ninedotsTeachers = []] = useNinedotsMembers()
  const [openAddTeacherModal] = useModal(
    <AddToClassModal
      additionalUsers={ninedotsTeachers}
      currentClass={currentClass}
      currentSchool={currentSchool} />
  )
  const cleverTeacher = get(currentClass, 'providers.clever.teacher')

  if (!classId) return <React.Fragment />

  return (
    <Row type='flex' align='middle' gutter={10} style={{ marginBottom: 16 }} className='class-tagger'>
      <Col>Teachers:</Col>
      <Col>
        <Tagger
          editable={true}
          addText='Add Teacher'
          onAdd={openAddTeacherModal}
          onRemove={onRemove}
          tags={teachers.map(t => ({ label: getFullName(t), value: t.id }))} />
      </Col>
    </Row>
  )

  function onRemove ({ value, label }) {
    return value === cleverTeacher
      ? Modal.error({
        title: 'Permission Denied',
        content: `${label} is Clever synced to this class. Use your account on Clever.com to remove them.`
      })
      : Modal.confirm({
        title: 'Remove Teacher?',
        content: `Are you sure you would like to remove ${label} as a teacher from this class?`,
        onOk: () =>
          rpc('class.removeTeacher', { class: classId, teacher: value })
      })
  }
}

/** @todo propTypes */

export default connect(
  null,
  { rpc }
)(ClassTagger)
