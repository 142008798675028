// const mock = require('./mock')
// const map = require('@f/map')

const schemas = {
  assignedActivity: require('./assignedActivity'),
  assignedModule: require('./assignedModule'),
  gradualRelease: require('./gradualRelease'),
  motivation: require('./motivation'),
  district: require('./district'),
  session: require('./session'),
  module: require('./module'),
  school: require('./school'),
  class: require('./class'),
  event: require('./event'),
  auth: require('./auth'),
  user: require('./user')
}

// const mocks = map((val, key) => mock(val.default.schema), schemas)

module.exports = schemas
// schemas.mock = mocks
schemas.picturePasswords = require('./picturePasswords')
