import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col } from 'antd'
import padStart from 'lodash/padStart'
import enhancer from './enhancer'

import './TimeoutModal.less'

/**
 * @todo Update this when refactoring admin verifySession
 */
const TimeoutModal = ({
  loading,
  expires,
  handleLogout,
  extendSession,
  ...props
}) => {
  const [, setTime] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time => time + 1)
    }, 1000)
    return () => clearInterval(interval)
  })
  return (
    <Modal
      title='SESSION EXPIRING'
      className='timeout-modal'
      footer={''}
      {...props}
      onCancel={() => {}}
      maskClosable={false}
      closable={false}
      visible>
      <h3>
        Your session will expire in {timeString(expires)}. Do you want to stay
        logged in?
      </h3>
      <Row type='flex' gutter={16} justify='center'>
        <Col>
          <Button disabled={loading} onClick={handleLogout}>
            Logout Now
          </Button>
        </Col>
        <Col>
          <Button
            loading={loading}
            disabled={loading}
            type='primary'
            onClick={extendSession}>
            Stay logged in!
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

TimeoutModal.propTypes = {}

export default enhancer(TimeoutModal)
export { TimeoutModal }

function timeString (time = 0) {
  const timeLeft = Math.max(time - Date.now(), 0)
  const mins = Math.floor(timeLeft / 60000)
  const secs = Math.floor((timeLeft - mins * 60000) / 1000)
  return mins + ':' + padStart(secs, 2, '0')
}
