import classLinkBadge from '../../assets/images/classLink-badge.png'
import cleverBadge from '../../assets/images/clever-badge.jpg'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import React from 'react'

import './SyncProviderBadge.less'

const SyncProviderBadge = ({ provider, ...rest }) => {
  const providerBadge = getBadge(provider)
  return <Avatar className='clever-badge' {...rest} src={providerBadge} />
}

SyncProviderBadge.propTypes = {}

export default SyncProviderBadge

/**
 * @function getBadge
 * @param {string} provider
 * @returns {(string|null)} address of provider image
 * if valid provider otherwise returns null
 */
function getBadge (provider) {
  switch (provider) {
    case 'Clever':
      return cleverBadge
    case 'ClassLink':
      return classLinkBadge
    default:
      return null
  }
}
