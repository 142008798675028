const Schema = require('@weo-edu/schema')
const validate = require('@weo-edu/validate')
const { date, firebaseRef } = require('../utils')

const trackEvent = Schema()
  .prop('type', Schema('string'))
  .prop('name', Schema('string'))
  .prop('path', Schema('string'))
  .prop('referrer', Schema('string'))
  .prop('search', Schema('string'))
  .prop('title', Schema('string'))
  .prop('url', Schema('string'))
  .required(['type'])

const getUserEngagement = Schema()
  .prop('start', date)
  .prop('classes', Schema('array'))
  .prop('user', firebaseRef)

const getEngagementOverview = Schema()
  .prop('start', date)
  .prop('classes', Schema('array'))
  .required(['start', 'classes'])

// exports.default = Event
exports.trackEvent = validate(trackEvent)
exports.getEngagementOverview = validate(getEngagementOverview)
exports.getUserEngagement = validate(getUserEngagement)
exports.rebuildSessions = () => ({ valid: true })
exports.refreshViews = () => ({ valid: true })
