import { useEffect } from 'react'
import { useIsEqualRef, useLoadingValue } from './utils'

const useFirestore = (firestoreRef, options = {}) => {
  const { get, ...restOptions } = options
  const { error, loading, reset, setError, setSnap, snap } = useLoadingValue()
  const ref = useIsEqualRef(firestoreRef, reset)

  useEffect(() => {
    if (get) {
      ref.current.get(restOptions).then(setSnap, setError)
    } else {
      const listener = restOptions
        ? ref.current.onSnapshot(restOptions, setSnap, setError)
        : ref.current.onSnapshot(setSnap, setError)
      return () => {
        listener()
      }
    }
  }, [ref.current])

  return [snap, loading, error]
}

export default useFirestore
