import { useEffect, useRef } from 'react'

/**
 * @function useLastRenderValue
 * @author Giovanni Bonilla
 * @param {*} value 
 * @returns {*}
 */
export function useLastRenderValue(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

/**
 * @function usePrevious
 * @author danleavitt0
 * @description returns the previous value of a variable
 * @param {*} value
 * @returns {Any}
 */
export default function usePrevious(value, deps = []) {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [value, ...deps])
  return ref.current
}

/**
 * @function useHasChangedValues
 * @author danleavitt0
 * @description compares a variable with it's previous value and
 * returns true when previous !== current
 * @param {*} value
 * @returns {boolean}
 */
export const useHasChangedValues = value => {
  const previous = usePrevious(value)
  return previous !== undefined && previous !== value
}

/**
 * @function useHasBecomeTruthy
 * @author danleavitt0
 * @description compares a variable with it's previous value and
 * returns true when value switches from a falsy value to a truthy value
 * @param {*} value
 * @returns {boolean}
 */
export const useHasBecomeTruthy = (value, deps = []) => {
  const previous = usePrevious(value, deps)
  // if (!!previous && !!value) return previous
  return previous !== undefined && !previous && !!value
}
