import { Menu, Row, Button, Col, Dropdown, Icon } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

const SchoolPicker = ({ schools = [], currentSchool = {}, path }) => {
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
          {schools.map(({ id, displayName }) => (
            <Menu.Item key={id} value={id}>
              <Link to={`${path}/${id}`}>{displayName}</Link>
            </Menu.Item>
          ))}
        </Menu>
      }>
      <Button className='school-picker-btn' style={{ width: '100%', textAlign: 'left' }}>
        <Row type='flex' justify='center'>
          <Col className='flex-grow ellipsis'>{currentSchool.displayName}</Col>
          <Col>
            <Icon type='down' />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}

export default SchoolPicker
