/**
 * @file provides a thunk factory for analytics tracking, a hook for
 * loading the analytics config from the database, and possibly other
 * things later down the road
 * @author Julius Diaz Panoriñgan
 * @author Giovanni Bonilla
 * h/t Daniel Leavitt for the thunk factory idea and virtually all its starter code
 */

import { useFirestoreValues } from '../hooks/firestore'
import { platformRef } from '../firestore/refs'
import { useEffect, useState } from 'react'
import getConfig from '../config/getConfig'
import { EventTypes } from 'redux-segment'
import { useDispatch } from 'react-redux'
import { setAnalytics } from './reducer'
import get from 'lodash/get'

// all analytics events generated via this file indicate which deployment
// they're coming from
const deployment =
  getConfig().projectId === 'school-5927d' ? 'production' : 'development'

// creates default properties for analytics events
// (just the source of the event for now)
const createDefaultProperties = source => ({ source })

/**
 * Creates a thunk that triggers redux-segment
 * middleware for analytics tracking.
 * Note that createPropertiesFn is nullable;
 * if so, the default properties are not used.
 * @function createAnalyticsThunk
 * @exports
 * @author Daniel Leavitt
 * @author Julius Diaz Panoriñgan
 * @param {string} type
 * @param {?function} createPropertiesFn
 * @returns {function}
 */
export const createAnalyticsThunk = (
  type,
  createPropertiesFn = createDefaultProperties
) => (...args) => (dispatch, getState) => {
  const isLoggedIn = !!getState().firebase.auth.uid

  if (!isLoggedIn) return // for now, no analytics tracking if not logged in

  const properties = createPropertiesFn ? createPropertiesFn(...args) : {}
  return dispatch({
    type,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: type,
          properties: {
            ...properties,
            isLoggedIn: String(isLoggedIn),
            deployment
          }
        }
      }
    }
  })
}

/**
 * A React hook that loads an analytics config and adds it to redux state.
 * @function useAnalytics
 * @exports
 * @author Julius Diaz Panoriñgan
 * @returns {boolean}
 */
export const useAnalytics = () => {
  const [loading, setLoading] = useState(true)

  const [platformData, platformDataLoading] = useFirestoreValues(platformRef())
  const analytics = get(platformData, '[0].analytics')
  const dispatch = useDispatch()
  useEffect(() => {
    if (!platformDataLoading) {
      if (analytics) {
        dispatch(setAnalytics(analytics))
      }
      setLoading(false)
    }
  }, [analytics, platformDataLoading])

  return loading
}

/**
 * A React hook that takes an object containing strings referring to analytics
 * events and returns a mapping of analytics events to thunks wrapped in the 
 * Redux store's dispatch function
 * @function useAnalyticsTracker
 * @exports
 * @author Giovanni Bonilla
 * @param {Object} events
 * @param {String} trackerSource
 * @returns {Object}
 */
export const useAnalyticsTracker = (events, trackerSource) => {
  const dispatch = useDispatch()
  return Object.keys(events).reduce((acc, curr) => {
    const event = events[curr]
    const analyticsThunk = typeof event === 'object' 
      ? createAnalyticsThunk(event.type, event.createPropertiesFn)
      : createAnalyticsThunk(event)
      
    const trackerFn = trackerSource
      ? (...args) => dispatch(analyticsThunk(trackerSource, ...args))
      : (...args) => dispatch(analyticsThunk(...args))
  
    return {
      ...acc,
      [curr]: trackerFn
    }
  }, {})
}