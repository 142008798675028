import EditTeacherSupportModal from 'components/EditTeacherSupportModal'
import PermissionsModal from 'components/PermissionsModal'
import EditStudentModal from 'components/EditStudentModal'
import { useModal } from 'shared/hooks/useModal'
import { isRosterSynced, getFullName } from 'shared/utils'
import { Dropdown, Menu, Button, Tooltip } from 'antd'
import menuHandler from './menuHandler'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import React from 'react'
import InputConfirmModal from 'shared/components/InputConfirmModal'

const UserMenu = props => {
  const { user, currentClass = {}, orgs, rpc, profile } = props

  const [openEditStudent] = useModal(<EditStudentModal user={user} />)
  const [openPermissions] = useModal(
    <PermissionsModal orgs={orgs} user={user} />
  )
  const [openTeacherSupport] = useModal(<EditTeacherSupportModal user={user} />)

  const isSelf = user.id === profile.id
  const isRosterSyncedUser = isRosterSynced(user)
  const isStudent = user.role === 'student'
  const canEditSupport = user.role === 'teacher' &&
    profile.role === 'ninedotsAdmin'
  const userName = getFullName(user)
  const archiveUser = () => {
    return rpc('user.archiveUser', { user: user.id })
  }
  const [archiveUserModal, hideArchiveUserModal] = useModal(() => (
    <InputConfirmModal
      title={`Remove ${userName} from ${(orgs.district || {}).displayName}?`}
      content={`This action will permanently remove ${userName} from ${
        (orgs.district || {}).displayName
      } and cannot be undone. Are you absolutely sure you want to do this? 
      If so, please enter ${userName} to confirm.`}
      okText={userName}
      hide={hideArchiveUserModal}
      onConfirm={archiveUser} />
  ))
  const removeFromSchool = () => {
    return rpc('user.removeFromSchool', {
      user: user.id,
      school: props.currentSchool.id
    })
  }
  const [removeFromSchoolModal, hideRemoveFromSchoolModal] = useModal(() => (
    <InputConfirmModal
      title={`Remove ${userName} from ${props.currentSchool.displayName}?`}
      content={`This action will permanently remove ${userName} from ${props.currentSchool.displayName} and cannot be undone. Are you absolutely sure you want to do this? 
      If so, please enter ${userName} to confirm.`}
      okText={userName}
      buttonText='Remove'
      hide={hideRemoveFromSchoolModal}
      onConfirm={removeFromSchool} />
  ))

  return (
    <Dropdown
      trigger={['click']}
      disabled={isSelf}
      overlay={
        <Menu
          onClick={({ key }) =>
            menuHandler(key, {
              ...props,
              removeFromSchoolModal,
              archiveUserModal,
              openPermissions,
              openEditStudent,
              openTeacherSupport
            })
          }>
          {isStudent ? (
            <Menu.Item
              key='editStudentInfo'
              disabled={isRosterSyncedUser.providerDisplayName}>
              <SyncProviderTooltip syncProvider={isRosterSyncedUser}>
                Edit Student Details
              </SyncProviderTooltip>
            </Menu.Item>
          ) : (
            <Menu.Item
              key='permissions'
              disabled={isRosterSyncedUser.providerDisplayName}>
              <SyncProviderTooltip syncProvider={isRosterSyncedUser}>
                Edit Permissions
              </SyncProviderTooltip>
            </Menu.Item>
          )}
          {canEditSupport && (
            <Menu.Item key='support'>Edit Support Level</Menu.Item>
          )}
          {currentClass.id && (
            <Menu.Item
              key='removeFromClass'
              disabled={!isRosterSynced(currentClass).canEditField('classes')}>
              <SyncProviderTooltip
                disabled={!isRosterSynced(currentClass).canEditField('classes')}
                syncProvider={isRosterSynced(currentClass)}
                type='class'>
                Remove from Class
              </SyncProviderTooltip>
            </Menu.Item>
          )}
          <Menu.Item
            key='removeFromSchool'
            disabled={isRosterSyncedUser.providerDisplayName}>
            <SyncProviderTooltip syncProvider={isRosterSyncedUser}>
              Remove from School
            </SyncProviderTooltip>
          </Menu.Item>
          {(orgs.district || {}).id && (
            <Menu.Item
              key='archiveUser'
              disabled={isRosterSyncedUser.providerDisplayName}>
              <SyncProviderTooltip syncProvider={isRosterSyncedUser}>
                Delete User
              </SyncProviderTooltip>
            </Menu.Item>
          )}
          {user.invitePending && (
            <Menu.Item key='resendInvite'>Resend Invite</Menu.Item>
          )}
        </Menu>
      }>
      <Tooltip
        trigger={['hover']}
        title={
          isSelf &&
          `Can't remove yourself from a school or district or edit your own permissions`
        }
        overlayClassName='small'
        placement='right'>
        <Button disabled={isSelf} icon='ellipsis' shape='circle' />
      </Tooltip>
    </Dropdown>
  )
}

export default connect(
  ({ firebase: { profile } }) => ({ profile }),
  { rpc }
)(UserMenu)

const SyncProviderTooltip = ({
  children,
  syncProvider,
  type = 'users',
  disabled = true,
  ...rest
}) => {
  return (
    <Tooltip
      trigger={['hover']}
      title={
        disabled &&
        `Can't edit ${syncProvider.providerDisplayName} synced ${type}`
      }
      overlayClassName='small'
      placement='left'
      {...rest}>
      {children}
    </Tooltip>
  )
}
