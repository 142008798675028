import { Row, Col } from 'antd'
import React from 'react'

export default ({ label, content }) => (
  <Row type='flex' gutter={20} style={{ marginBottom: 16 }}>
    <Col span={8}>
      <b>{label}</b>
    </Col>
    <Col>{content}</Col>
  </Row>
)
