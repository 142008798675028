import { Card, Row, Col, Modal, message, Icon, Switch } from 'antd'
import SyncProviderBadge from 'shared/components/SyncProviderBadge'
import { districtAdmins, platformRef } from 'shared/firestore/refs'
import { isRosterSynced, getFullName } from 'shared/utils'
import React, { useCallback, useState } from 'react'
import HeaderLayout from 'components/HeaderLayout'
import InviteUsersModal from '../InviteUsersModal'
import useFirestore from 'shared/hooks/firestore'
import { useModal } from 'shared/hooks/useModal'
import useOrgs from '../../hooks/useOrgs'
import Tagger from 'components/Tagger'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import PropTypes from 'prop-types'
import InfoRow from './InfoRow'

import './AccountInfo.less'

const AccountInfo = ({ profile, rpc }) => {
  const {
    orgs: { schools = [], district }
  } = useOrgs()

  const [loadingEnablePasswords, setLoadingEnablePasswords] = useState()
  const { providerDisplayName, syncFields } = isRosterSynced(district) || {}
  const licenses = schools.length
  const { district: districtId, role } = profile
  const isDistrictAdmin = role === 'districtAdmin' || role === 'ninedotsAdmin'
  const [showAddUser] = useModal(
    <InviteUsersModal initialRole='districtAdmin' district={districtId} />
  )

  const [platformSnap, platformLoading] = useFirestore(platformRef())
  const platform = platformSnap ? platformSnap.docs[0].data() : {}
  const { adminContact } = platform

  const [snap, loadingAdmins] = useFirestore(districtAdmins(districtId))
  const enablePasswords = useCallback(passwordEnabled => {
    setLoadingEnablePasswords(true)
    rpc('district.update', { district: districtId, passwordEnabled }).then(
      () => {
        setLoadingEnablePasswords(false)
      }
    )
  })

  const admins = snap
    ? snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    : []
  const adminTags = admins.map(a => ({
    displayName: getFullName(a),
    isRosterSynced: isRosterSynced(a),
    label: (
      <>
        <span className='tagger-label'>{getFullName(a)}</span>
        {a.invitePending && (
          <Icon
            type='question-circle'
            style={{ marginLeft: 5 }}
            onClick={() =>
              Modal.confirm({
                title: 'Invite Pending',
                content: `Resend invitation to ${a.email}?`,
                okText: 'Resend',
                onOk: () =>
                  rpc('user.resendInvite', {
                    email: a.email
                  }).then(() => {
                    message.success('Invite Sent')
                  })
              })
            } />
        )}
      </>
    ),
    value: a.id
  }))

  if (loadingAdmins || platformLoading) return <React.Fragment />

  return (
    <HeaderLayout
      title={isDistrictAdmin ? district.displayName : 'Account Info'}
      contentContainerStyle={{ maxWidth: 800 }}>
      <Row type='flex' gutter={20} style={{ marginBottom: 16 }}>
        <Col>
          <h3>District Admins:</h3>
        </Col>
        <Col className='flex-grow district-admin-tags'>
          <Tagger
            onRemove={tag => {
              tag.isRosterSynced
                ? Modal.info({
                  title: 'Delete District Admin',
                  content: `This user can only be removed on ${providerDisplayName}.com`,
                  okText: 'Ok'
                })
                : Modal.confirm({
                  title: 'Delete District Admin',
                  content: `Are you sure you want to delete ${tag.displayName}?`,
                  okText: 'Delete',
                  onOk: () => rpc('user.archiveUser', { user: tag.value })
                })
            }}
            editable={isDistrictAdmin}
            onAdd={showAddUser}
            tags={adminTags} />
        </Col>
      </Row>
      <Card title='Summary' style={{ marginBottom: 20 }}>
        {/* <InfoRow
          label='Start Date:'
        content={moment(Date.now()).format('MMMM Do, YYYY')} /> */}
        <InfoRow label='Licenses Purchased:' content={licenses} />
        <InfoRow label='Licenses Assigned:' content={licenses} />
      </Card>
      <Card title='Contact' style={{ marginBottom: 20 }}>
        <p>
          Please get in contact with us if you have any questions or feedback.
        </p>
        <InfoRow label='Name:' content={adminContact.name} />
        <InfoRow
          label='Email:'
          content={
            <a href={`mailto:${adminContact.email}`}>{adminContact.email}</a>
          } />
        <InfoRow label='Number:' content='+1 (323) 524-8328' />
      </Card>
      {providerDisplayName && (
        <Card title={`${providerDisplayName} Synced`}>
          <Row type='flex' align='middle' justify='space-between'>
            <Col span={18}>
              {`Your district is currently synced with ${providerDisplayName}. Any ${syncFieldsToString(
                syncFields
              )} with the ${providerDisplayName} badge next to them have also
              been synced with ${providerDisplayName} and can only be edited by logging into
              your account on ${providerDisplayName}.com`}
            </Col>
            <Col>
              <SyncProviderBadge
                provider={providerDisplayName}
                className='clever-school-badge' />
            </Col>
          </Row>
        </Card>
      )}
      {isDistrictAdmin && (
        <Card title='Student Login'>
          <p>
            Simple passwords allow students to login with an image or short pin.
            This will make it easier for them to login, but will be less secure.
          </p>
          <div>
            <Switch
              loading={loadingEnablePasswords}
              onChange={enablePasswords}
              checked={district.passwordEnabled} />
            &emsp;Enable Simple Passwords
          </div>
        </Card>
      )}
    </HeaderLayout>
  )
}

AccountInfo.propTypes = {
  admins: PropTypes.array,
  isDistrictAdmin: PropTypes.bool
}

export default connect(
  ({ firebase: { profile } }) => ({ profile }),
  { rpc }
)(AccountInfo)

function syncFieldsToString (syncFields) {
  if (syncFields.length > 2) {
    return `${syncFields.slice(0, -1).join(', ')}, or ${
      syncFields[syncFields.length - 1]
    }`
  }
  if (syncFields.length === 2) return syncFields.join(' or ')
  return syncFields[0]
}
