import TeacherEngagementTable from 'components/TeacherEngagementTable'
import Loading from 'shared/components/Loading'
import { Empty, Card, Table } from 'antd'
import React from 'react'

import './DataTeacherEngagement.less'

export default ({ data, loading }) => {
  if (loading) {
    return <Loading />
  }
  // first request has not been sent yet
  else if (!Array.isArray(data) && Object.keys(data).length === 0) {
    return <></>
  } else if (Array.isArray(data) && data.length === 0) {
    return <Empty description='No engagement data for selected filters.' />
  }

  return (
    <div className='data-teacher-engagement'>
      <Summary data={data} />
      <TeacherEngagementTable data={data} />
    </div>
  )
}

const Summary = ({ data }) => {
  let totalLessonsTaught = 0
  const teacherLessonsTaught = {}
  const numMeetingBenchmarks = [0, 0, 0, 0]

  for (const classData of data) {
    const { lessonsTaught, teacherId } = classData
    teacherLessonsTaught[teacherId] = Math.max(
      teacherLessonsTaught[teacherId] || 0,
      lessonsTaught
    )
  }

  const numLessonsByTeacher = Object.values(teacherLessonsTaught)
  const numTeachers = numLessonsByTeacher.length

  for (const lessonsTaught of numLessonsByTeacher) {
    totalLessonsTaught += lessonsTaught
    if (lessonsTaught >= 30) {
      numMeetingBenchmarks[3]++
    } else if (lessonsTaught >= 20) {
      numMeetingBenchmarks[2]++
    } else if (lessonsTaught >= 10) {
      numMeetingBenchmarks[1]++
    } else {
      numMeetingBenchmarks[0]++
    }
  }

  const columns = [
    { title: 'Modules Taught', dataIndex: 'modulesTaught', width: '50%' },
    { title: '0', dataIndex: 'zero', align: 'center' },
    { title: '1', dataIndex: 'one', align: 'center' },
    { title: '2', dataIndex: 'two', align: 'center' },
    { title: '3', dataIndex: 'three', align: 'center' }
  ]
  const summaryData = [
    {
      key: '1',
      modulesTaught: 'Teachers',
      zero: numMeetingBenchmarks[0],
      one: numMeetingBenchmarks[1],
      two: numMeetingBenchmarks[2],
      three: numMeetingBenchmarks[3]
    }
  ]

  return (
    <Card className='teacher-engagement-summary' size='small'>
      <h3>Summary</h3>
      <div className='teacher-engagement-summary-content'>
        <h4>
          <b>{numTeachers}</b> teachers have taught
          <b>{` ${Number(totalLessonsTaught / numTeachers || 0).toFixed(
            1
          )} `}</b>
          lessons on average.
        </h4>
        <Table
          className='teacher-engagement-summary-table'
          bordered
          pagination={false}
          columns={columns}
          dataSource={summaryData}
          size='small'
        />
      </div>
    </Card>
  )
}
