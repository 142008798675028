import styles from 'shared/theme/vars/vars.js'
import Sidebar from './Sidebar'
import { Layout } from 'antd'
import React from 'react'

import './SidebarLayout.less'

const SidebarLayout = ({ children }) => {
  return (
    <Layout>
      <Layout.Sider className='main-sider' width={styles['@sidebar-width']}>
        <Sidebar />
      </Layout.Sider>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  )
}

export default SidebarLayout
