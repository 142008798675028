import React, { useState } from 'react'
import { ModalContext } from './ModalContext'
import getProp from '@f/get-prop'

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null)
  const onOk = getProp('props.onOk', modal)
  const onCancel = getProp('props.onCnacel', modal)
  const props = {
    onCancel: onCancel || (() => setModal(null)),
    onOk: onOk || (() => setModal(null)),
    visible: true
  }

  return (
    <ModalContext.Provider value={{ setModal }}>
      {children}
      {modal ? React.cloneElement(modal, props) : <></>}
    </ModalContext.Provider>
  )
}
