const validate = require('@weo-edu/validate')
const Schema = require('@weo-edu/schema')
const {
  activityType,
  displayName,
  description,
  firebaseRef,
  uuidObject,
  lessonTags,
  imageUrl,
  gradeMap,
  uuid,
  url
} = require('../utils')

// add description

const activity = Schema()
  .prop('displayName', displayName)
  .prop('url', url)
  .prop('keyActivity', Schema('number').enum([0, 1, 2, 3, 4, 5]))
  .prop('type', activityType)
  .prop('id', Schema('string').faker('random.uuid'))
  .prop(
    'index',
    Schema('number')
      .multiple(1.0)
      .faker('random.number')
  )
  .required(['url', 'type', 'index'])
const activities = Schema('array').items(activity.schema)

const duration = Schema()
  .prop(
    'time',
    Schema('number')
      .min(1)
      .max(60)
      .faker('random.number')
  )
  .prop(
    'unit',
    Schema('string').enum(['minutes', 'hours', 'days', 'weeks', 'months'])
  )
  .required(['time', 'unit'])

const lesson = Schema()
  .prop('displayName', displayName)
  .prop('description', description)
  .prop('slides', url)
  .prop('lessonPlan', url)
  .prop('activities', activities)
  .prop('id', Schema('string').faker('random.uuid'))
  .prop(
    'index',
    Schema('number')
      .multiple(1.0)
      .faker('random.number')
  )
  .prop('tags', lessonTags)
  .required(['displayName', 'description', 'index'])

const lessons = Schema('array').items(lesson.schema)

const Module = Schema()
  .prop('displayName', displayName)
  .prop('description', description)
  .prop('owner', firebaseRef)
  .prop('duration', duration)
  .prop('lessons', lessons)
  .prop('tags', lessonTags)
  .prop('imageUrl', imageUrl)
  .prop('difficulty', Schema('string').enum(['A', 'B', 'C', 'D', 'E']))
  .prop('published', Schema('boolean').faker('random.boolean'))
  .prop('featured', Schema('boolean').faker('random.boolean'))
  .prop(
    'assigns',
    Schema('number')
      .min(0)
      .multiple(1.0)
  )
  .prop('scopeAndSequenceUrl', url)
  .required(['displayName', 'description', 'owner', 'imageUrl'])

const create = Schema()
  .prop('displayName', displayName)
  .prop('description', description)
  .prop('duration', duration)
  .prop('tags', uuidObject)
  .prop('grade', gradeMap)
  .prop('scopeAndSequenceUrl', url)
  .required(['displayName', 'description', 'grade', 'tags'])

const addLesson = Schema()
  .prop('displayName', displayName)
  .prop('description', description)
  .prop('draft', firebaseRef)
  .prop('module', firebaseRef)
  .prop('slides', url)
  .prop('lessonPlan', url)
  .required([
    'displayName',
    'module',
    'description',
    'slides',
    'lessonPlan',
    'draft'
  ])
  .others(false, 'invalid_keys')

const removeLesson = Schema()
  .prop('module', firebaseRef)
  .prop('draft', firebaseRef)
  .prop('lesson', uuid)
  .required(['lesson', 'module', 'draft'])
  .others(false, 'invalid_keys')

const updateLesson = Schema()
  .prop('module', firebaseRef)
  .prop('draft', firebaseRef)
  .prop('lesson', uuid)
  .prop('displayName', displayName)
  .prop('description', description)
  .prop('module', firebaseRef)
  .prop('slides', url)
  .prop('lessonPlan', url)
  .prop('activities', activities)
  .required(['lesson', 'module', 'draft'])

const addActivity = Schema()
  .prop('module', firebaseRef)
  .prop('draft', firebaseRef)
  .prop('lesson', uuid)
  .prop('url', url)
  .prop('access_token')
  .required(['url', 'module', 'lesson', 'draft'])
  .others(false, 'invalid_keys')

const removeActivity = Schema()
  .prop('module', firebaseRef)
  .prop('draft', firebaseRef)
  .prop('lesson', uuid)
  .prop('activity', uuid)
  .required(['activity', 'module', 'lesson', 'draft'])
  .others(false, 'invalid_keys')

const activityTag = Schema()
  .prop('type', Schema('number'))
  .prop(
    'difficulty',
    Schema('number')
      .multiple(0.1)
      .min(0)
      .max(6)
  )
  .required(['type', 'difficulty'])

const checklistItem = Schema()
  .prop('title', Schema('string'))
  .required(['title'])

const instructions = Schema('string')
  .min(0)
  .faker('lorem.sentences')

const image = Schema()
  .prop('downloadUrl')
  .prop('storagePath')

const activityInstruction = Schema()
  .prop('text', instructions)
  .prop('images', Schema('array').items(image.schema))

const updateActivity = Schema()
  .prop('module', firebaseRef)
  .prop('draft', firebaseRef)
  .prop('lesson', uuid)
  .prop('activity', uuid)
  .prop(
    'difficulty',
    Schema('number')
      .multiple(0.1)
      .min(0)
      .max(6)
  )
  .prop('project', Schema('boolean'))
  .prop('tags', Schema('array').items(activityTag.schema))
  .prop('checklist', Schema('array').items(checklistItem.schema))
  .prop('displayName', displayName)
  .prop('type', activityType)
  .prop('instructions', activityInstruction)
  .required(['activity', 'module', 'lesson', 'draft'])
  .others(false, 'invalid_keys')

const addProject = Schema()
  .prop('module', firebaseRef)
  .prop('draft', firebaseRef)
  .prop('lesson', uuid)
  .prop('activity', uuid)
  .prop('project', uuid)
  .prop('tags', Schema('array').items(activityTag.schema))
  .prop('displayName', displayName)
  .prop('type', activityType)
  .required(['activity', 'module', 'lesson', 'draft'])
  .others(false, 'invalid_keys')

const courseRef = Schema()
  .prop('module', firebaseRef)
  .required(['module'])

exports.default = Module
exports.lesson = lesson
exports.activity = activity
exports.create = validate(create)
exports.update = validate(create)
exports.addLesson = validate(addLesson)
exports.removeLesson = validate(removeLesson)
exports.updateLesson = validate(updateLesson)
exports.addActivity = validate(addActivity)
exports.addProject = validate(addProject)
exports.removeActivity = validate(removeActivity)
exports.updateActivity = validate(updateActivity)
exports.reorder = validate(courseRef)
exports.publish = validate(courseRef)
exports.unpublish = validate(courseRef)
exports.createDraft = validate(courseRef)
