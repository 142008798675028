import createAction from '@f/create-action'
import { useEffect, useReducer } from 'react'

const firebase = window.firebase
const database = firebase.database()

const setConnected = createAction('PRESENCE_TOAST: SET_CONNECTION_STATUS')

const initialState = {
  connected: undefined
}

function reducer (state, action) {
  switch (action.type) {
    case setConnected.type:
      return {
        connected: action.payload
      }
  }
  return state
}

export default function () {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const connectedRef = database.ref('.info/connected')
    connectedRef.on('value', snapshot => {
      if (snapshot) {
        if (state.connected && snapshot.val() === false) {
          dispatch(setConnected(false))
        } else if (!state.connected && snapshot.val()) {
          dispatch(setConnected(true))
        }
      }
    })
    return () => connectedRef.off()
  }, [state.connected])

  return {
    connected: state.connected
  }
}
