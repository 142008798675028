import React, { createContext, useContext, useState, useEffect } from 'react'
import { classesBySchool } from 'shared/firestore/refs'
import { alphaCompare } from 'shared/utils'
import useOrgs from '../useOrgs'

export const ClassesContext = createContext()

export const ClassesProvider = ({ schoolId, classId, children }) => {
  const { orgs } = useOrgs()

  const [classState, setClassState] = useState({
    loading: true,
    currentSchool: getCurrentSchool(orgs, schoolId),
    classes: []
  })

  const [currentClass, setCurrentClass] = useState({})

  useEffect(() => {
    const listener = classesBySchool(
      getCurrentSchool(orgs, schoolId)
    ).onSnapshot(snap => {
      const classes = snap
        ? snap.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => alphaCompare(a, b, 'displayName'))
        : []

      setClassState({
        classes,
        currentSchool: getCurrentSchool(orgs, schoolId),
        loading: false
      })
    })
    return () => {
      listener()
    }
  }, [schoolId])

  useEffect(() => {
    const { classes } = classState
    setCurrentClass(classes.find(({ id }) => id === classId) || {})
  }, [classId, classState.loading])

  return (
    <ClassesContext.Provider value={{ ...classState, currentClass }}>
      {children}
    </ClassesContext.Provider>
  )
}

const useClasses = () => useContext(ClassesContext)

export default useClasses

const getCurrentSchool = (orgs, school) =>
  (orgs.schools || []).find(({ id }) => id === school)
