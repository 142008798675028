import CreateStudentModal from 'components/CreateStudentModal'
import { Row, Col, Button, Modal, Tooltip } from 'antd'
import InviteUsersModal from '../InviteUsersModal/'
import { useModal } from 'shared/hooks/useModal'
import { isRosterSynced } from 'shared/utils'
import ClassActions from './ClassActions'
import useClasses from 'hooks/useClasses'
import React from 'react'

const ActionMenu = props => {
  const { currentClass = {}, currentSchool = {} } = useClasses()
  const { id: classId } = currentClass

  const { providerDisplayName } = isRosterSynced(currentSchool)
  const [openCreateStudent] = useModal(
    <CreateStudentModal school={currentSchool} classId={classId} />
  )
  const [openInviteModal] = useModal(
    <InviteUsersModal
      school={currentSchool.id}
      district={currentSchool.district}
    />
  )

  return (
    <Row type='flex' gutter={10} style={{ marginBottom: 16 }}>
      <Col>
        <Tooltip
          overlayClassName='small'
          title={
            providerDisplayName &&
            `Can't invite to ${providerDisplayName} synced schools`
          }
        >
          <Button
            icon='mail'
            disabled={providerDisplayName}
            onClick={openInviteModal}
          >
            Invite Teachers &amp; Admins
          </Button>
        </Tooltip>
      </Col>
      <Col>
        <Tooltip
          overlayClassName='small'
          title={
            providerDisplayName &&
            `Can't add new students to ${providerDisplayName} synced schools`
          }
        >
          <Button
            disabled={providerDisplayName}
            icon='plus'
            onClick={() => handler('createStudent')}
          >
            Create Student
          </Button>
        </Tooltip>
      </Col>
      <Col className='flex-grow' />
      {classId && (
        <Col>
          <ClassActions />
        </Col>
      )}
    </Row>
  )

  function handler(actionType) {
    switch (actionType) {
      case 'createStudent':
        return syncProviderModal() || openCreateStudent()
    }
  }

  function syncProviderModal() {
    if (providerDisplayName) {
      return Modal.warning({
        title: 'Permission Denied',
        content: `This school has been synced with ${providerDisplayName}. You can only perform this action through your account on ${providerDisplayName}.com.`,
        maskClosable: true
      })
    }
  }
}

export default ActionMenu
