import { alphaCompare } from 'shared/utils'

/**
 * @function nameSorter
 * @author Giovanni Bonilla
 * @description util function for sorting names by last name.
 * If last names are identical, sorts by first name.
 * @param {Object} a
 * @param {Object} b
 * @returns {Number}
 */
export default function nameSorter(a, b) {
  const { family: aFamily } = a.teacherName
  const { family: bFamily } = b.teacherName
  const sortKey =
    aFamily === bFamily ? 'teacherName.given' : 'teacherName.family'

  return alphaCompare(a, b, sortKey)
}
