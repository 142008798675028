import InputConfirmModal from 'shared/components/InputConfirmModal'
import { Icon, Button, Dropdown, Menu, Tooltip } from 'antd'
import AddToClassModal from 'components/AddToClassModal'
import EditClassModal from 'components/EditClassModal'
import { isRosterSynced } from 'shared/utils'
import { useModal } from 'shared/hooks/useModal'
import { rpc, setUrl } from 'shared/actions'
import useClasses from 'hooks/useClasses'
import { connect } from 'react-redux'
import React from 'react'
import './ClassActions.less'

const ClassActions = ({ rpc, setUrl }) => {
  const { currentClass, currentSchool } = useClasses()
  const { id: schoolId } = currentSchool
  const { id: classId } = currentClass

  // const isPDClass = isPD(currentClass)
  const rosterSyncData = isRosterSynced(
    currentClass
  )
  const {providerDisplayName} = rosterSyncData
  const classesLocked = !rosterSyncData.canEditField('classes')
  const [openEditClass] = useModal(<EditClassModal classId={classId} />)
  const [openAddUser] = useModal(
    <AddToClassModal
      currentClass={currentClass}
      currentSchool={currentSchool}
      addStudent />
  )
  const archiveClass = async function () {
    await rpc('class.archiveClass', { class: classId })
    return setUrl(`/people/${schoolId}`)
  }

  const [openArchiveModal, hideArchiveModal] = useModal(() => (
    <InputConfirmModal
      title={`Remove ${currentClass.displayName}?`}
      content={`This action will permanently delete ${currentClass.displayName} and cannot be undone.
      Are you absolutely sure you want to do this? If so, please enter the name of the class to confirm.`}
      okText={currentClass.displayName}
      okButtonText='Remove Class'
      hide={hideArchiveModal}
      onConfirm={archiveClass} />
  ))

  return (
    <Dropdown
      trigger={['click']}
      placement='bottomRight'
      overlay={
        <Menu onClick={handler}>
          <Menu.Item key='editClass'>
            <Icon type='edit' />
            Edit Class Details
          </Menu.Item>
          <Menu.Item key='addStudent' disabled={classesLocked}>
            <Tooltip
              title={
                classesLocked &&
                `Can't add students to ${providerDisplayName} synced classes`
              }
              overlayClassName='small'
              placement='left'>
              <Icon type='user-add' />
              Add Student to Class
            </Tooltip>
          </Menu.Item>
          <Menu.Item key='archive' className='danger' disabled={classesLocked}>
            <Tooltip
              title={
                classesLocked &&
                `Can't archive ${providerDisplayName} synced classes`
              }
              overlayClassName='small'
              placement='left'>
              <Icon type='delete' />
              Archive Class
            </Tooltip>
          </Menu.Item>
        </Menu>
      }>
      <Button icon='setting'>
        Class Settings
        <Icon type='down' />
      </Button>
    </Dropdown>
  )

  function handler ({ key }) {
    switch (key) {
      case 'editClass':
        return openEditClass()
      case 'addStudent':
        return openAddUser()
      case 'archive':
        return openArchiveModal()
    }
  }
}

/** @todo propTypes */

export default connect(
  null,
  { rpc, setUrl }
)(ClassActions)
