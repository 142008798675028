import { useContext } from 'react'
import { ModalContext } from './ModalContext'

/**
 *
 * @param {useModal} function
 * hook for showing and hiding modals
 * @param {component} <Component />
 * @returns An array of functions for showing and hiding
 * the passed in modal component
 */
export const useModal = component => {
  const { setModal } = useContext(ModalContext)
  const showModal = () => setModal(component)
  const hideModal = () => setModal(null)
  return [showModal, hideModal]
}
