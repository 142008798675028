/**
 * @file A component for viewing student's survey data
 * @author Anh Tran
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Text,
  Bar
} from 'recharts'
import palette from 'shared/theme/vars/palette'
import { Card, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import Chart from './Chart'
import React from 'react'

const chartHeight = 500

const SurveyChart = ({ surveyData, graphInfo, isComparingData, loading }) => {
  const renderCustomTooltip = ({ active, payload, label }) =>
    active && payload.length > 0 ? (
      <div className='custom-tooltip'>
        <h3>{label}</h3>
        <Row type='flex' gutter={[16, 8]}>
          <Col span={18}>Survey Averages:</Col>
        </Row>
        {payload.map(({ name, color, value }, i) => (
          <Row type='flex' key={`row-${i}`} gutter={[16, 8]}>
            <Col style={{ color }} key={`col-${i}`} span={12}>
              {`${name}: ${Math.floor(value)}%`}
            </Col>
          </Row>
        ))}
      </div>
    ) : null

  return (
    <Card title='Student Survey Responses'>
      <ResponsiveContainer width='100%' height={500}>
        {loading ? (
          <Chart chartHeight={chartHeight} type='loading' />
        ) : surveyData.length === 0 ? (
          <Chart
            type='empty'
            chartHeight={chartHeight}
            message='No survey data matches the current selected filters.'
          />
        ) : (
          <BarChart
            data={surveyData}
            margin={{ top: 20, bottom: 50, left: 10 }}
          >
            <Legend
              align='right'
              verticalAlign='top'
              wrapperStyle={{ paddingBottom: '20px' }}
            />
            <Tooltip
              content={renderCustomTooltip}
              cursor={{ fill: '#f0f0f0' }}
            />
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='question'
              tick={renderCustomXAxisTick}
              interval={0}
            />
            <YAxis dataKey='survey1' unit='%' domain={[0, 100]} />
            {Object.keys(surveyData[0])
              .filter(key => key !== 'question')
              .map((surveyKey, index) => (
                <Bar
                  key={surveyKey}
                  dataKey={surveyKey}
                  name={
                    isComparingData
                      ? graphInfo[index].label
                      : `Survey ${index + 1}`
                  }
                  fill={
                    isComparingData
                      ? graphInfo[index].color
                      : defaultBarColors[index]
                  }
                />
              ))}
          </BarChart>
        )}
      </ResponsiveContainer>
    </Card>
  )
}

export default SurveyChart

SurveyChart.propTypes = {
  surveyData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
}

const renderCustomXAxisTick = ({ x, y, payload }) => {
  return (
    <Text
      x={x}
      y={y}
      textAnchor='middle'
      verticalAnchor='start'
      width={100}
      dy={5}
    >
      {payload.value}
    </Text>
  )
}

const defaultBarColors = [
  palette['@blue-light'],
  palette['@green'],
  palette['@yellow'],
  palette['@red'],
  palette['@pink'],
  palette['@blue']
]
