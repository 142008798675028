/**
 *
 * @file analytics.js
 * @author Julius Diaz Panoriñgan
 * @author Giovanni Bonilla
 * File exports an object containing strings referring to admin related actions
 * for passing into the 'useAnalyticsTracker' hook for analytics tracking.
 * File also exports a function that generates the set of actions that
 * trigger redux-segment middleware for admin related analytics.
 */

import { useAnalyticsTracker } from 'shared/analytics'

export const adminEvents = {
  overview: 'VIEWED_ADMIN_DATA_OVERVIEW',
  studentProficiency: 'VIEWED_ADMIN_DATA_STUDENT_PROFICIENCY',
  studentMotivation: 'VIEWED_ADMIN_DATA_STUDENT_MOTIVATION',
  teacherEngagement: 'VIEWED_ADMIN_DATA_TEACHER_ENGAGEMENT'
}

export default defaultSource => useAnalyticsTracker(adminEvents, defaultSource)
