import { useEffect, useState } from 'react'
import uniqBy from 'lodash/uniqBy'

const firestore = window.firebase.firestore()

export default ({ id, district }, roles = []) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    Promise.all(
      roles.map(role =>
        firestore
          .collection('users')
          .where('district', '==', district)
          .where(`roles.${role}`, 'array-contains', id)
          .where('archived', '==', false)
          .get()
      )
    )
      .then(snaps => {
        setLoading(false)
        const users = uniqBy(
          snaps.reduce(
            (acc, snap) =>
              acc.concat(snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))),
            []
          ),
          'id'
        )
        return setUsers(users)
      })
      .catch(console.error)
  }, [id, district, ...roles])
  return [users, loading]
}
