import { Modal, Input, Select, Form, message } from 'antd'
import useForm from 'shared/hooks/useForm'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import schema from 'schema'
import React from 'react'

import './InviteUsersModal.less'

const roles = [
  { value: 'admin', label: 'Administrator' },
  { value: 'teacher', label: 'Teacher' }
]

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

const InviteUsersModal = ({
  initialRole = 'teacher',
  school,
  district,
  rpc,
  onOk,
  ...props
}) => {
  const [endpoint, showRoleSelect] =
    initialRole === 'districtAdmin'
      ? ['inviteDistrictAdmin', false]
      : ['inviteUser', true]
  const { handleSubmit, getFieldProps, getFieldError, isSubmitting } = useForm({
    onSubmit: vals => {
      return rpc(`user.${endpoint}`, vals)
        .then(() => {
          message.success('Invitation Sent.')
          onOk()
        })
        .catch(e => {
          if (e.error && e.error.message === 'email_in_use') {
            message.error('This email is already in use.')
          } else {
            message.error('Oops, something went wrong. Please Try aggain')
          }
        })
    },
    initialValues: {
      school,
      district,
      role: initialRole
    },
    validationSchema: schema.user[endpoint]
  })

  return (
    <Modal
      {...props}
      confirmLoading={isSubmitting}
      okText='Send Invite'
      onOk={handleSubmit}
      title='Invite Users'>
      <Form className='align-label-left'>
        <Form.Item
          {...formItemLayout}
          {...getFieldError('email')}
          label='Email'>
          <Input
            {...getFieldProps('email')}
            autoFocus
            placeholder='Email Address' />
        </Form.Item>
        {showRoleSelect && (
          <Form.Item
            {...getFieldError('role')}
            label='Role'
            {...formItemLayout}>
            <Select
              {...getFieldProps('role', 'select')}
              defaultValue={initialRole}>
              {roles.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

/** @todo propTypes */

export default connect(
  null,
  { rpc }
)(InviteUsersModal)
