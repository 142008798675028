import {
  Modal,
  Form,
  Input,
  Divider,
  Select,
  Icon,
  Row,
  Col,
  Tooltip
} from 'antd'
import useForm from 'shared/hooks/useForm'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import getProp from '@f/get-prop'
import schema from 'schema'
import React from 'react'
import './CreateSchoolModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
}

const CreateSchoolModal = ({
  district = {},
  school = {},
  profile,
  edit,
  onOk,
  rpc,
  ...rest
}) => {
  const [title, endpoint, districtId] = edit
    ? ['Edit School', 'school.update']
    : ['Create School', 'school.create', profile.district]

  const { handleSubmit, getFieldProps, getFieldError, isSubmitting } = useForm({
    onSubmit: async vals => {
      await rpc(endpoint, vals)
      return onOk()
    },
    initialValues: {
      displayName: school.displayName,
      principal: school.principal,
      ...(edit && { passwordEnabled: !!school.passwordEnabled }),
      ...(school.id && { school: school.id }),
      ...(districtId && { district: districtId })
    },
    validationSchema: getProp(endpoint, schema)
  })

  return (
    <Modal
      {...rest}
      confirmLoading={isSubmitting}
      onOk={handleSubmit}
      title={title}>
      <Form {...formItemLayout}>
        <Form.Item {...getFieldError('displayName')} label='School Name'>
          <Input {...getFieldProps('displayName')} placeholder='School Name' />
        </Form.Item>
        <Form.Item {...getFieldError('principal.name')} label='Principal Name'>
          <Input
            {...getFieldProps('principal.name')}
            placeholder='Principal Name' />
        </Form.Item>
        <Form.Item
          {...getFieldError('principal.email')}
          label='Principal Email'>
          <Input
            {...getFieldProps('principal.email')}
            placeholder='Principal Email' />
        </Form.Item>
        {edit && district.passwordEnabled && (
          <>
            <Divider />
            <Form.Item
              {...getFieldError('passwordDisabled')}
              label='Student Login'>
              <Row type='flex' align='middle' gutter={20}>
                <Col span={22}>
                  <Select {...getFieldProps('passwordEnabled', 'select')}>
                    <Select.Option key='enabled' value={true}>
                      Allow Simple Login
                    </Select.Option>
                    <Select.Option key='disable' value={false}>
                      Disable Simple Login
                    </Select.Option>
                  </Select>
                </Col>
                <Tooltip title='Simple passwords allow students to login with an image or short pin.  This will make it easier for them to login, but will be less secure.'>
                  <Icon type='question-circle' />
                </Tooltip>
              </Row>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}

/** @todo propTypes */

export default connect(
  ({ firebase: { profile } }) => ({
    profile
  }),
  { rpc }
)(CreateSchoolModal)
