import getProp from '@f/get-prop'
import natsort from 'natsort'

export default function alphaCompare (a, b, key, insensitive = true) {
  const sorter = natsort({ insensitive })
  const aProp = key ? getProp(key, a) : a
  const bProp = key ? getProp(key, b) : b

  // Lowercase z's to ensure undefined props are at
  // the end of the sort order
  return sorter(
    typeof aProp === 'undefined' ? 'zzzzz' : aProp,
    typeof bProp === 'undefined' ? 'zzzzz' : bProp
  )
}

/**
 * @function alphaSort
 * @param {Object} a
 * @param {Object} b
 * @param {string} selector an object path to pull a string from a and b
 * @returns {number} -1 if a < b; 0 if a = b; 1 if a > b
 * @exports
 * This function is used a few times throughout the codebase, and has been moved
 * here. Moving forward we may want to do the following:
 * @todo deprecate alphaSort?
 * @todo refactor alphaSort as a wrapper around alphaCompare?
 */
export function alphaSort (a, b, selector) {
  let nameA = (getProp(selector, a) || '').toLowerCase()
  let nameB = (getProp(selector, b) || '').toLowerCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}
