import { compose, withHandlers, withState } from 'recompose'
import { logout } from 'shared/ducks/user'
import { connect } from 'react-redux'
import { rpc } from '../../actions'

export default compose(
  connect(
    ({ firebase }) => ({
      profile: firebase.profile
    }),
    { logout, rpc }
  ),
  withState('loading', 'setLoading', false),
  withHandlers({
    handleLogout: ({ setLoading, profile, logout, onCancel }) => async () => {
      setLoading(true)
      return logout(profile)
        .then(onCancel)
        .catch(() => setLoading(false))
    },
    extendSession: ({ setLoading, rpc, onCancel }) => () => {
      setLoading(true)
      return rpc('session.updateTimestamp')
        .then(onCancel)
        .catch(() => setLoading(false))
    }
  })
)
