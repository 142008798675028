import { Row, Col, Select } from 'antd'
import React from 'react'

export default ({ label, last, rightLabel, options, ...rest }) => (
  <Row
    type='flex'
    justify='space-between'
    style={{ padding: 10, borderBottom: last ? 'none' : '1px solid #e8e8e8' }}>
    <Col>{label}</Col>
    <Col>
      {rightLabel || (
        <Select {...rest} style={{ minWidth: 140 }} placeholder='None'>
          {options.map(({ label, value }) => (
            <Select.Option value={value} key={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      )}
    </Col>
  </Row>
)
