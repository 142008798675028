/**
 * @function decimalToPercent
 * @description converts a stringified floating point
 * number to its equivalent stringified percent value
 * @param {String} val
 * @returns {String}
 */
export default function decimalToPercent (val) {
  const parsed = parseFloat(val, 10) * 100
  return parsed.toString()
}
