/**
 * @file A component for viewing school proficiency data
 * @author Julius Diaz Panoriñgan
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import { gradeValueToShortText } from 'shared/utils/data'
import { Card, Empty, Icon, Table, Tooltip } from 'antd'
import ProficiencyBar from 'components/ProficiencyBar'
import ProficiencyChart from './ProficiencyChart'
import palette from 'shared/theme/vars/palette'
import Loading from 'shared/components/Loading'
import { alphaCompare } from 'shared/utils'
import PropTypes from 'prop-types'
import React from 'react'

import './DataProficiency.less'

const DataProficiency = ({ data, loading }) => {
  // render loading screen if loading
  if (loading) {
    return <Loading />
  }
  // first request has not been sent yet
  if (Object.keys(data).length === 0) {
    return <></>
  }
  // proficiencyPercents replaces proficiencyCounts when data is being compared
  const {
    proficiencyData,
    proficiencyCounts,
    proficiencyPercents,
    graphInfo
  } = data
  const isComparingData = graphInfo.length > 1

  // render helpful message if all data filtered out
  if (
    (!isComparingData &&
      (!proficiencyCounts || proficiencyCounts.every(count => count === 0))) ||
    (isComparingData &&
      (!proficiencyPercents || proficiencyPercents.length === 0))
  ) {
    return (
      <Empty description='No proficiency data matches the current filters.' />
    )
  }

  return (
    <>
      {isComparingData ? (
        <ProficiencyChart
          proficiencyPercents={proficiencyPercents}
          graphInfo={graphInfo}
        />
      ) : (
        <ProficiencyBar proficiencyCounts={proficiencyCounts} />
      )}
      <Card className='proficiency-card'>
        <Table
          columns={columns}
          dataSource={proficiencyData}
          pagination={false}
          rowKey={c => c.classId}
        />
      </Card>
    </>
  )
}

DataProficiency.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default DataProficiency

const columns = [
  {
    title: 'Classes',
    dataIndex: 'displayName',
    sorter: pinnedSorter((a, b) => alphaCompare(a, b, 'displayName')),
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Grade',
    dataIndex: 'gradeText',
    render: gradeText => {
      if (gradeText !== '—') gradeText = gradeValueToShortText(gradeText)
      return <span>{gradeText}</span>
    },
    sorter: (a, b) => a.gradeNum - b.gradeNum, // pinnedSorter not required!
    align: 'center'
  },
  {
    title: '# of Lessons Taught',
    dataIndex: 'numLessons',
    sorter: pinnedSorter((a, b) => a.numLessons - b.numLessons),
    align: 'center'
  },
  {
    title: (
      <Tooltip title='Represents the average amount of time that students spend working individually on computers during class.'>
        {'Avg. Practice Time Per Lesson '}
        <Icon type='question-circle' />
      </Tooltip>
    ),
    dataIndex: 'avgPracticeTime',
    render: avgPracticeTime => (
      <span>{Number(avgPracticeTime / 60000).toFixed(1)} min</span>
    ),
    sorter: pinnedSorter((a, b) => a.avgPracticeTime - b.avgPracticeTime),
    align: 'center'
  },
  {
    title: 'Avg. Proficiency',
    dataIndex: 'avgProficiency',
    render: avgProficiency => (
      <span style={{ color: proficiencyToColor(avgProficiency) }}>
        {avgProficiency ? Number(avgProficiency).toFixed(1) : 'No data'}
      </span>
    ),
    sorter: pinnedSorter((a, b) => a.avgProficiency - b.avgProficiency),
    align: 'center'
  }
]

/**
 * @todo Created a general pinnedSorter function which currently lives in the shared/utils dir.
 * Delete this pinnedSorter and use the one from shared/utils instead.
 */

/**
 * Given a standard sort function (a, b) => number, returns a function that
 * takes a, b, and a sortOrder, which can be used as a sorter function for an
 * ant design table. This returned  function works like the given sort function,
 * except it always pins an object without a classId property at the top of the
 * sort, no matter the sort order.
 * @function pinnedSorter
 * @author Julius Diaz Panoriñgan
 * @param {Function} sorter
 * @returns {Function}
 */
function pinnedSorter(sorter) {
  return (a, b, sortOrder) => {
    if (a.classId && b.classId) {
      return sorter(a, b)
    } else if (sortOrder === 'ascend') {
      return a.classId ? Infinity : -Infinity
    } else {
      // sortOrder === 'descend'
      return a.classId ? -Infinity : Infinity
    }
  }
}

/**
 * Given a proficiency, returns a color string.
 * Returns undefined if proficiency === 0.
 * @function proficiencyToColor
 * @author Julius Diaz Panoriñgan
 * @param {number} proficiency
 * @returns {string}
 */
function proficiencyToColor(proficiency) {
  if (proficiency === 0) {
    return
  } else if (proficiency < 1.5) {
    return palette['@red']
  } else if (proficiency < 2.5) {
    return palette['@yellow']
  } else if (proficiency < 3.5) {
    return palette['@green']
  } else {
    // 3.5 <= proficiency <= 4.0
    return palette['@blue-light']
  }
}
