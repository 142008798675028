import { alphaCompare, isRosterSynced } from 'shared/utils'
import HeaderLayout from '../../components/HeaderLayout'
import SyncProviderBadge from 'shared/components/SyncProviderBadge'
import useOrgs from '../../hooks/useOrgs/useOrgs'
import SchoolActions from './SchoolActions'
import { Table, Card, Tooltip } from 'antd'
import SchoolMenu from './SchoolMenu'
import React from 'react'
import './Schools.less'

const Schools = props => {
  const {
    orgs: { schools = [] },
    loading
  } = useOrgs()

  return (
    <HeaderLayout title='Schools'>
      <SchoolActions />
      <Card>
        <Table
          columns={columns}
          loading={loading}
          rowKey={({ id }) => id}
          dataSource={schools} />
      </Card>
    </HeaderLayout>
  )
}

Schools.propTypes = {}

export default Schools

const columns = [
  {
    title: 'Name',
    dataIndex: 'displayName',
    width: 300,
    sorter: (a, b) => alphaCompare(a, b, 'displayName')
  },
  {
    title: 'Principal',
    width: 150,
    render: ({ principal = {} }) => {
      return principal.name
    }
  },
  {
    title: 'Principal Email',
    width: 150,
    render: ({ principal = {} }) => {
      const { email } = principal
      return email && <a href={`mailto:${email}`}>{email}</a>
    }
  },
  {
    width: 80,
    align: 'right',
    render: school => {
      const rosterSyncProvider = isRosterSynced(school).providerDisplayName
      return (
        rosterSyncProvider && (
          <Tooltip
            title={`${rosterSyncProvider} Synced`}
            style={{ cursor: 'default' }}>
            <SyncProviderBadge provider={rosterSyncProvider} size='small' />
          </Tooltip>
        )
      )
    }
  },
  {
    title: 'Actions',
    className: 'actions-column',
    width: 100,
    render: school => <SchoolMenu school={school} />
  }
]
