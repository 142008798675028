/**
 * @file a basic hook for getting district info
 * @author Julius Diaz Panoriñgan
 */

import { useState, useEffect } from 'react'
import useFirestore from 'shared/hooks/firestore'
import { districtRef } from 'shared/firestore/refs'

/**
 * @function useDistrict
 * @exports
 * @param {string} districtId
 * @returns {Array.<?Object>}
 *
 * @todo add loading and/or error states
 *
 * Currently returns a single-element array, an object with a district's
 * displayName, passwordEnabled flag, and uniqueIdField.
 * That object is null if the district info has not loaded yet or successfully.
 */
const useDistrict = districtId => {
  const [district, setDistrict] = useState(null)

  const [districtSnap] = useFirestore(districtRef(districtId))

  useEffect(() => {
    if (districtSnap) {
      setDistrict({
        displayName: districtSnap.get('displayName'),
        passwordEnabled: districtSnap.get('passwordEnabled'),
        uniqueIdField: districtSnap.get('uniqueIdField')
      })
    }
  }, [districtSnap])

  return [district] /** @todo add loading and/or error states */
}

export default useDistrict
