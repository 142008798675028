import DistrictSwitcher from 'components/DistrictSwitcher'
import React, { useState, useCallback } from 'react'
import { Modal } from 'antd'

import './DistrictSwitcherModal.less'

const DistrictSwitcherModal = ({ onOk, currentDistrict, ...restProps }) => {
  const [selectedDistrict, setSelectedDistrict] = useState(currentDistrict)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const onSelect = useCallback(val => setSelectedDistrict(val))
  const handleOk = useCallback(async () => {
    setConfirmLoading(true)
    try {
      return onOk(selectedDistrict)
    } catch (e) {
      setConfirmLoading(false)
    }
  })
  return (
    <Modal
      {...restProps}
      bodyStyle={{ textAlign: 'center' }}
      centered
      title='Select a District'
      onOk={handleOk}
      confirmLoading={confirmLoading}
      visible>
      <DistrictSwitcher
        selectedDistrict={selectedDistrict}
        onSelect={onSelect} />
    </Modal>
  )
}

/** @todo propTypes */

export default DistrictSwitcherModal
