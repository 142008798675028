import useFirebasePresence from 'shared/hooks/useFirebasePresence'
import { useEffect } from 'react'
import { message } from 'antd'

export default function () {
  const { connected } = useFirebasePresence()
  useEffect(() => {
    if (!connected && typeof connected !== 'undefined') {
      return message.error('Oops! Reconnecting...', 0)
    }
  }, [connected])
  return { connected }
}
