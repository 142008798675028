/**
 * @file A component that displays a lesson highlight to an admin
 * @author Julius Diaz Panoriñgan
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import { Button, Card, Icon } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

import { classRef, moduleRef } from 'shared/firestore/refs'
import { useFirestoreValues } from 'shared/hooks/firestore'
import { gradeValueToAdjective } from 'shared/utils/data'

import { config } from 'configureStore'

const { clientUrl } = config

const LessonHighlight = ({ class: cls, module: mod, lesson }) => {
  const [classData, classLoading] = useFirestoreValues(classRef(cls))
  const [moduleData, moduleLoading] = useFirestoreValues(moduleRef(mod))

  if (classLoading || moduleLoading) {
    return null
  }

  const lessonData = moduleData.lessons.find(({ id }) => id === lesson)
  const { description, displayName, lessonPlan, slides } = lessonData

  const previewUrl = `${clientUrl}/module/${mod}/lesson/${lesson}/0/preview`

  return (
    <Card
      className='lesson-highlight'
      title={
        <span>
          Lesson Highlight:
          <span className='sub-title'>
            {`Check out this coding lesson that was taught in a ${gradeValueToAdjective(
              classData.grade
            )} class this week.`}
          </span>
        </span>
      }
    >
      <div>
        <h3 className='ellipsis'>{displayName}</h3>
        <p>{description}</p>
      </div>
      <div className='lesson-buttons'>
        <Button.Group>
          <Button icon='profile' href={lessonPlan} target='_blank'>
            Lesson Plan
          </Button>
          <Button icon='file-ppt' href={slides} target='_blank'>
            Slides
          </Button>
        </Button.Group>
        <Button className='preview-button' href={previewUrl} target='_blank'>
          Preview
          <Icon type='caret-right' />
        </Button>
      </div>
    </Card>
  )
}

LessonHighlight.propTypes = {
  class: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  lesson: PropTypes.string.isRequired
}

export default LessonHighlight
