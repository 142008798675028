function canEditField (field) {
  return !(this.syncFields || []).includes(field)
}

const providerData = {
  classLink: {
    providerDisplayName: 'ClassLink',
    syncFields: ['schools', 'classes', 'users'],
    canEditField
  },
  clever: {
    providerDisplayName: 'Clever',
    syncFields: ['schools', 'classes', 'users'],
    canEditField
  },
  default: {
    providerDisplayName: null,
    syncFields: [],
    canEditField
  }
}

export default function getRosterSync ({ providers = {} }) {
  const provider = Object.keys(providers).find(provider => {
    return provider === 'classLink' || provider === 'clever'
  })

  switch (provider) {
    case 'clever':
      return providerData.clever
    case 'classLink':
      return providerData.classLink
    default:
      return providerData.default
  }
}
