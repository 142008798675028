import usePermissionUpdate from 'shared/hooks/usePermissionUpdate'
import useVerifySession from 'hooks/useVerifySession'
import usePresenceToast from 'shared/hooks/usePresenceToast'
import { formatIntercomUser } from 'shared/utils'
import Loading from 'shared/components/Loading'
import getConfig from 'shared/config/getConfig'
import { useAnalytics } from 'shared/analytics'
import { OrgsProvider } from 'hooks/useOrgs'
import { connect } from 'react-redux'
import Intercom from 'react-intercom'
import { compose } from 'recompose'
import Router from 'app/Router'
import React from 'react'
import './Root.less'

const { intercomId } = getConfig()

const Root = ({ expiration, profile }) => {
  useVerifySession(expiration, profile)
  usePermissionUpdate(profile)
  usePresenceToast()
  const analyticsLoading = useAnalytics()

  if (profile.isEmpty || analyticsLoading) return <Loading />
  const { roles = {}, role, district } = profile
  const { admin = [] } = roles

  const adminRoles = {
    districtId: district,
    schoolIds: admin
  }

  return (
    <OrgsProvider roles={adminRoles} role={role}>
      <Intercom appID={intercomId} {...formatIntercomUser(profile)} />
      <Router />
    </OrgsProvider>
  )
}

/** @todo propTypes */

export default compose(
  connect(({ firebase: { profile, auth }, session = {} }) => ({
    expiration: session.expiration,
    profile,
    auth
  }))
)(Root)
