import { Row, Col } from 'antd'
import React from 'react'

// idTypes and related constants
const idTypes = ['email', 'stateId']
// antd grid, out of 24
const nameColWidths = {
  email: 12, // 50%
  stateId: 16 // 66 2/3 %
}

export const getDisplayName = ({ displayName, email }) => displayName || email
export const getFullName = ({ name, email, displayName }) =>
  name ? name.given + ' ' + name.family : displayName || email
export const getId = (student, idType) =>
  idTypes.includes(idType) && student[idType]

/**
 * @function getStudentNameWithId
 * @exports
 * @author Julius Diaz Panoriñgan
 * @param {Object} student
 * @param {?string} idType
 * Formats a student name and id using antd Row and Col.
 * Ideal for drop-down boxes and such.
 * If no idType is specified, falls back to the output of getFullName wrapped
 * in `<span className='ellipsis'></span>`
 */
export const getStudentNameWithId = (student, idType) => {
  const name = getFullName(student)
  const id = getId(student, idType)

  // fallback to (formatted) getFullName for unknown idType
  if (!id) {
    return <span className='ellipsis'>{name}</span>
  }

  // set display geometry
  const mainColWidth = nameColWidths[idType]
  const idColWidth = 24 - mainColWidth

  return (
    <Row type='flex'>
      <Col span={mainColWidth} className='ellipsis'>
        {name}
      </Col>
      <Col
        span={idColWidth}
        className='ellipsis'
        style={{ fontFamily: 'monospace', fontSize: 13 }}>
        {id}
      </Col>
    </Row>
  )
}
