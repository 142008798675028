import { userDidLogin, userDidLogout } from './actions'

export default function (state = null, action) {
  switch (action.type) {
    case userDidLogin.type:
      return action.payload
    case userDidLogout.type:
      return ''
  }
  return state
}
