const validate = require('@weo-edu/validate')
const Schema = require('@weo-edu/schema')
const {
  firebaseRefObject,
  // activityType,
  firebaseRef,
  displayName,
  // lessonTags,
  // ethnicity,
  progress,
  score,
  // grade,
  uuid,
  url
} = require('../utils')

// const properties = Schema()
//   .prop('studentAge', Schema('number').faker('random.number'))
//   .prop('studentGradeLevel', grade)
//   .prop('teacherAge', Schema('number').faker('random.number'))
//   .prop('studentEthnicity', ethnicity)
//   .prop('teacherEthnicity', ethnicity)
//   .prop('activityType', activityType)
//   .prop('lessonTags', lessonTags)
//   .required([
//     'studentAge',
//     'studentGradeLevel',
//     'teacherAge',
//     'studentEthnicity',
//     'teacherEthnicity',
//     'activityType'
//   ])

// const subactivity = Schema()
//   .prop('url', url)
//   .prop('type', activityType)
//   .prop('score', score)
//   .prop('started', Schema('boolean'))
//   .prop('completed', Schema('boolean'))
//   .prop('progress', progress)
//   .required(['url', 'started', 'completed', 'progress'])

// const subactivities = Schema('array').items(subactivity.schema)

const assignedActivity = Schema()
  .prop('student', firebaseRef)
  .prop('assignedModule', firebaseRef)
  .prop('teachers', Schema('array').items(firebaseRef))
  .prop('class', firebaseRef)
  .prop('displayName', displayName)
  .prop('activity', uuid)
  .prop('index', Schema('number'))
  .prop('instance', url)
  .prop('id', uuid)
  .prop('lesson', uuid)
  .prop('score', score)
  .prop('started', Schema('boolean'))
  .prop('completed', Schema('boolean'))
  .prop('progress', progress)
  .prop('url', url)
  .prop('schoolYear', Schema('string'))
  .required([
    'student',
    'teachers',
    'assignedModule',
    'lesson',
    'class',
    'activity',
    'url',
    'schoolYear'
  ])

const add = Schema()
  .prop('student', firebaseRef)
  .prop('assignedModule', firebaseRef)
  .prop('teachers', firebaseRefObject)
  .prop('class', firebaseRef)
  .prop('activity', firebaseRef)
  .prop('lesson', firebaseRef)
  .prop('moduleInstance', firebaseRef)
  .prop('score', score)
  .prop('active', Schema('boolean'))
  .prop('started', Schema('boolean'))
  .prop('completed', Schema('boolean'))
  .prop('progress', progress)
  .prop('url', url)
  .required([
    'moduleInstance',
    'student',
    'teachers',
    'assignedModule',
    'lesson',
    'class',
    'activity',
    'url'
  ])

const setActive = Schema()
  .prop('lesson', firebaseRef)
  .prop('assignedActivity', firebaseRef)
  .required(['lesson', 'assignedActivity'])

const responses = Schema()
  .prop('label', Schema('string'))
  .prop('value')
  .required(['label', 'value'])

const externalUpdate = Schema()
  .prop('completed', Schema('boolean'))
  .prop('responses', Schema('array').items(responses.schema))
  .prop('progress', progress)
  .prop('score', score)
  .prop('id', uuid)
  .others(false)

const updateTime = Schema()
  .prop('assignedActivity', firebaseRef)
  .required(['assignedActivity'])

const setStartTime = Schema()
  .prop('assignedActivity', firebaseRef)
  .required(['assignedActivity'])

const maybeSetCompleted = Schema()
  .prop('assignedActivity', firebaseRef)
  .required(['assignedActivity'])

const getActivityCsv = Schema()
  .prop('activitys', Schema('array').items(uuid.schema))
  .prop('classes', Schema('array').items(firebaseRef.schema))
  .required(['activitys'])
  .others(false)

const getGradebook = Schema()
  .prop('class', firebaseRef)
  .prop('lessons', Schema('array'))
  .prop('students', Schema('array'))
  .prop('schoolYear', firebaseRef)
  .required(['class', 'lessons'])

const getStartedLessonsData = Schema()
  .prop('class', firebaseRef)
  .required(['class'])

// TODO: better name for assignedModule instance
exports.default = assignedActivity
exports.getGradebook = validate(getGradebook)
exports.getStartedLessonsData = validate(getStartedLessonsData)
exports.maybeSetCompleted = validate(maybeSetCompleted)
exports.externalUpdate = validate(externalUpdate)
exports.setStartTime = validate(setStartTime)
exports.getActivityCsv = validate(getActivityCsv)
exports.updateTime = validate(updateTime)
exports.setActive = validate(setActive)
exports.add = validate(add)
