/**
 * @file A component for viewing student interest data
 * @author Anh Tran
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line
} from 'recharts'
import PropTypes from 'prop-types'
import Chart from './Chart'
import { Card, Row, Col } from 'antd'
import moment from 'moment'
import React from 'react'
const chartHeight = 250

const InterestChart = ({ interestData, graphInfo, loading }) => {
  const renderToolTip = ({ payload, label, active }) =>
    active && payload.length > 0 ? (
      <div className='custom-tooltip'>
        <h3>{label}</h3>
        {payload.map(({ name, color, value }, i) => (
          <Row type='flex' key={`row-${i}`} gutter={[16, 8]}>
            <Col style={{ color }} key={`col-${i}`}>
              {`${name}: ${Math.floor(value)}%`}
            </Col>
          </Row>
        ))}
      </div>
    ) : null

  return (
    <Card title='% of Students Who Like Coding'>
      <ResponsiveContainer width='100%' height={chartHeight}>
        {loading ? (
          <Chart chartHeight={chartHeight} type='loading' />
        ) : interestData.length === 0 ? (
          <Chart
            type='empty'
            chartHeight={chartHeight}
            message='No interest data matches the current selected filters.'
          />
        ) : (
          <LineChart data={interestData}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={({ date }) => moment(date).format('MMM')} />
            <YAxis unit='%' domain={[0, 100]} />
            <Legend
              verticalAlign='top'
              iconType='line'
              align='right'
              wrapperStyle={{ paddingBottom: '20px' }}
              iconSize={20}
            />
            {graphInfo.map(({ label, color }, index) => (
              <Line
                key={`line-${index}`}
                type='linear'
                strokeWidth='2'
                name={label}
                dataKey={index}
                stroke={color}
                activeDot={{ r: 8 }}
                dot
              />
            ))}
            <Tooltip content={renderToolTip} />
          </LineChart>
        )}
      </ResponsiveContainer>
    </Card>
  )
}

export default InterestChart

InterestChart.propTypes = {
  interestData: PropTypes.array.isRequired,
  graphInfo: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
}
