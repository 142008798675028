import CreateSchoolModal from './CreateSchoolModal'
import { useModal } from 'shared/hooks/useModal'
import { isRosterSynced } from 'shared/utils'
import useOrgs from '../../hooks/useOrgs'
import { Row, Col, Button, Tooltip } from 'antd'
import React from 'react'

export default () => {
  const [openCreateSchool] = useModal(<CreateSchoolModal />)
  const {
    orgs: { district = {} }
  } = useOrgs()
  const rosterSyncProvider = isRosterSynced(district)
  const canEditSchool = rosterSyncProvider.canEditField('schools')

  return (
    <Row type='flex' gutter={10} style={{ marginBottom: 16 }}>
      <Col className='flex-grow' />
      <Col>
        <Tooltip
          title={
            !canEditSchool &&
            `Can't add schools to ${rosterSyncProvider.providerDisplayName} districts`
          }>
          <Button
            type='primary'
            disabled={!canEditSchool}
            className='secondary'
            icon='plus'
            onClick={openCreateSchool}>
            Create New School
          </Button>
        </Tooltip>
      </Col>
      {/*
    <Col>
      <Button type='primary' className='secondary' icon='file-ppt'>
        Upload CSV
      </Button>
    </Col> */}
    </Row>
  )
}
