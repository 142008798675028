import createAction from '@f/create-action'

const setExpiration = createAction('<session/>: SET_EXPIRATION', time => time)

export default function (state = {}, action) {
  switch (action.type) {
    case setExpiration.type:
      return {
        ...state,
        expiration: action.payload
      }
  }
  return state
}

export { setExpiration }
