/**
 * @file a wrapper around redux-segment's createTracker() middleware creator
 * @author Julius Diaz Panoriñgan
 */

import { createTracker } from 'redux-segment'
import get from 'lodash/get'

export default function(customMapper) {
  const tracker = createTracker(customMapper)

  return storeAPI => {
    const trackerWrapDispatch = tracker(storeAPI)
    return next => {
      const trackerHandleAction = trackerWrapDispatch(next)
      return action => {
        /*
          we bypass redux segment middleware if
          (1) the action has an event type
          and
          (2) the analytics filter value for that type is false
        */

        const eventType = get(action, 'meta.analytics.eventPayload.event')

        if (!eventType) {
          return trackerHandleAction(action)
        }

        const analyticsFilter = get(storeAPI.getState(), 'analytics.filter')
        const isTypeTracked = get(analyticsFilter, eventType, true)

        if (isTypeTracked) {
          return trackerHandleAction(action)
        } else {
          return next(action)
        }
      }
    }
  }
}
