/**
 * @function checkTimes
 * Gets the current user session expiration time
 * @returns Timestamp
 */

export default function checkTimes (getState, cb) {
  const db = window.firebase.database()
  const offsetRef = db.ref('.info/serverTimeOffset')
  return offsetRef
    .once('value')
    .then(snap => snap.val())
    .then(offset => {
      const { uid } = getState().firebase.auth
      if (uid) {
        db.ref(`sessions/${uid}/expires`)
          .once('value')
          .then(snap => {
            const time = snap.val()
            const expires = time ? time - offset : 0
            return cb(null, expires)
          })
      }
    })
}
