import { Col, Icon, Row, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

import './ProficiencyBar.less'

const ProficiencyBar = ({ proficiencyCounts }) => {
  const total = proficiencyCounts.reduce((total, count) => total + count)
  const proficiencyPercents = proficiencyCounts.map(
    count => (100 * count) / total
  )

  return (
    <div className='proficiency-bar'>
      <Row type='flex' align='middle' justify='space-between'>
        <Col>
          <h3>
            <Tooltip title='Percent of students in each proficiency band.'>
              Proficiency Breakdown&ensp;
              <Icon type='question-circle' />
            </Tooltip>
          </h3>
        </Col>
        <Col>
          <Row type='flex'>
            <KeyItem label='Below (1)' />
            <KeyItem label='Approaching (2)' />
            <KeyItem label='Meeting (3)' />
            <KeyItem label='Exceeding (4)' />
          </Row>
        </Col>
      </Row>
      <Row type='flex' className='proficiency'>
        {proficiencyPercents.map((percent, i) => (
          <Tooltip
            key={i}
            title={isSmall(percent) && Math.round(percent) + '%'}
          >
            <Col className='proficiency-band' style={{ width: percent + '%' }}>
              {!isSmall(percent) && Math.round(percent) + '%'}
            </Col>
          </Tooltip>
        ))}
      </Row>
    </div>
  )
}

ProficiencyBar.propTypes = {
  proficiencyCounts: PropTypes.arrayOf(PropTypes.number).isRequired
}

export default ProficiencyBar

const KeyItem = ({ label }) => (
  <Col className='key-item'>
    <div className='key-color' />
    {label}
  </Col>
)

const isSmall = percent => percent <= 6
