import { classRef, userRef } from './refs'
import { useEffect, useState } from 'react'

export default (classId, role = 'members') => {
  const [users, setUsers] = useState([])
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(false)

  /**
   *  @todo Make this live
   *  Load with a get request then add listeners after it is loaded
   * */

  useEffect(() => {
    setLoading(true)
    if (classId) {
      const listener = classRef(classId).onSnapshot(doc => {
        // Use class data to retrieve student profiles
        if (doc.exists) {
          const members = doc.data()[role] || []
          Promise.all(
            members.map(member =>
              userRef(member)
                .get()
                .then(snap => ({ ...snap.data(), id: snap.id }))
            )
          )
            .then(setUsers)
            .then(() => setLoading(false))
          setMembers(members)
        }
      })
      return () => listener()
    }
  }, [classId])

  useEffect(() => {
    const memberListeners = members.map(member =>
      userRef(member).onSnapshot(snap => {
        const data = { id: snap.id, ...snap.data() }
        setUsers(users =>
          users.map(user => (user.id === data.id ? data : user))
        )
      })
    )
    return () => memberListeners.map(fn => fn())
  }, [JSON.stringify(members)])

  return [users, loading]
}
