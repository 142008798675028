import { Row, Col, Empty, Spin, Icon } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Depending on the type, creates either an empty message or a loading
 * message in the middle of the chart
 */
const Chart = ({ chartHeight, type, message }) => {
  const empty = (
    <Empty
      description={
        <>
          <span className='empty-title'>{message}</span>
        </>
      }
    />
  )
  const loading = (
    <>
      <div className='loader-text'>Loading…</div>
      <Spin indicator={<Icon type='loading' className='loader-icon' spin />} />
    </>
  )
  return (
    <Row
      style={{ textAlign: 'center', height: chartHeight }}
      justify='center'
      align='middle'
      type='flex'
    >
      <Col>{type === 'loading' ? loading : empty}</Col>
    </Row>
  )
}

export default Chart

Chart.propTypes = {
  chartHeight: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['loading', 'empty']).isRequired,
  message: PropTypes.string
}
