import { useEffect, useRef, useReducer } from 'react'

export const useComparatorRef = (value, isEqual, onChange) => {
  const ref = useRef(value)
  useEffect(() => {
    if (value) {
      if (!ref.current || !isEqual(value, ref.current)) {
        ref.current = value
        if (onChange) {
          onChange()
        }
      }
    }
  })
  return ref
}

export const useIsEqualRef = (value, onChange) => {
  return useComparatorRef(value, (v1, v2) => v1.isEqual(v2), onChange)
}

export const useIdentifyRef = (value, onChange) => {
  return useComparatorRef(value, (v1, v2) => v1 === v2, onChange)
}

const defaultState = defaultValue => {
  return {
    loading: defaultValue === undefined || defaultValue === null,
    snap: defaultValue
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        error: action.error,
        loading: false
      }
    case 'reset':
      return defaultState(action.defaultValue)
    case 'snap':
      return {
        ...state,
        loading: false,
        snap: action.snap
      }
    default:
      return state
  }
}

export const useLoadingValue = getDefaultValue => {
  const defaultValue = getDefaultValue ? getDefaultValue() : undefined
  const [state, dispatch] = useReducer(reducer, defaultState(defaultValue))

  const reset = () => {
    const defaultValue = getDefaultValue ? getDefaultValue() : undefined
    dispatch({ type: 'reset', defaultValue })
  }

  const setError = error => {
    dispatch({ type: 'error', error })
  }

  const setSnap = snap => {
    dispatch({ type: 'snap', snap })
  }

  return {
    error: state.error,
    loading: state.loading,
    reset,
    setError,
    setSnap,
    snap: state.snap
  }
}
