import checkTimes from 'shared/utils/checkSessionTimeout'
import { setExpiration } from '../ducks/session'

const checkDuration = 1000

/**
 * @function verifySession
 * Check session isn't expired every at an interval of {checkDuration}
 */
export default function verifySession ({ getState, dispatch }) {
  const cb = (err, expires) => {
    if (err) return
    if (expires !== getState().session.expiration) {
      dispatch(setExpiration(expires))
    }
  }

  // Check session on first load
  checkTimes(getState, cb)
  // Set interval for subsequent checks.
  setInterval(() => checkTimes(getState, cb), checkDuration)

  return next => action => {
    return next(action)
  }
}
