import PropTypes from 'prop-types'
import React, { useState, useEffect, useMemo } from 'react'
import { Select, Row } from 'antd'
import { groupBy, orderBy, flatten, mergeWith } from 'lodash'

import { decimalToPercent } from 'shared/utils'
import InterestChart from './InterestChart'
import SurveyChart from './SurveyChart'

import './DataMotivation.less'

const { Option } = Select

const DataMotivation = ({ data, loading }) => {
  const { responseData = [], graphInfo = [] } = data
  const [
    selectedSurveyAggregateType,
    setSelectedSurveyAggregateType
  ] = useState(AGGREGATE_SELECTOR_OPTIONS.SURVEY)

  const isComparingData = graphInfo.length > 1

  useEffect(() => {
    // if now comparing data and the selected option was aggregate by survey,
    // set the selected value to average all surveys
    if (
      isComparingData &&
      selectedSurveyAggregateType === AGGREGATE_SELECTOR_OPTIONS.SURVEY
    ) {
      setSelectedSurveyAggregateType(AGGREGATE_SELECTOR_OPTIONS.AVG)
    }
  }, [isComparingData, selectedSurveyAggregateType])

  const aggregatedInterestData = useMemo(
    () => combineAndAggregateInterestData(responseData),
    [responseData]
  )
  const aggregatedSurveyData = useMemo(
    () =>
      combineAndAggregateSurveyData(responseData, selectedSurveyAggregateType),
    [responseData, selectedSurveyAggregateType]
  )

  // first request has not been sent yet
  if (Object.keys(data).length === 0) {
    return <></>
  }

  return (
    <div className='data-motivation'>
      <InterestChart
        interestData={aggregatedInterestData}
        graphInfo={graphInfo}
        loading={loading}
      />
      {!loading && (
        <Row className='aggregate-selector-row' justify='end' type='flex'>
          <span className='aggregate-selector-label'>Aggregate Data By:</span>
          <Select
            value={selectedSurveyAggregateType}
            className='aggregate-selector'
            onChange={value => setSelectedSurveyAggregateType(value)}
          >
            <Option
              value={AGGREGATE_SELECTOR_OPTIONS.SURVEY}
              disabled={isComparingData}
            >
              Survey Number
            </Option>
            <Option value={AGGREGATE_SELECTOR_OPTIONS.AVG}>
              Average All Surveys
            </Option>
          </Select>
        </Row>
      )}
      <SurveyChart
        surveyData={aggregatedSurveyData}
        graphInfo={graphInfo}
        isComparingData={isComparingData}
        loading={loading}
      />
    </div>
  )
}

export default DataMotivation

DataMotivation.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

const AGGREGATE_SELECTOR_OPTIONS = {
  SURVEY: 'SURVEY',
  AVG: 'AVG'
}

const combineAndAggregateInterestData = responseData =>
  responseData.reduce((accInterestData, { interestData }, compIndex) => {
    const aggregatedInterestData = aggregateSurveyResults(
      interestData,
      'date',
      compIndex
    )

    return mergeWith(accInterestData, aggregatedInterestData)
  }, [])

const combineAndAggregateSurveyData = (responseData, aggregateType) =>
  responseData.reduce((accSurveyData, { surveyData }, compIndex) => {
    const aggregatedSurveyData =
      aggregateType === AGGREGATE_SELECTOR_OPTIONS.SURVEY
        ? aggregateSurveysBySurvey(surveyData)
        : averageAllSurveys(surveyData, compIndex)

    return mergeWith(accSurveyData, aggregatedSurveyData)
  }, [])

/**
 * Responses will be group together according to the iteratee, then
 * the grouped responses will be aggregated to find the averages for each group
 * The return array will be an array of objects of the iteratee information
 * and the positive responses average.
 * @function aggregateSurveyResults
 * @author Anh Tran
 * @param {Array} responses
 * @param {String} iteratee
 * @param {String} resultKey
 * @returns {Array}
 */
const aggregateSurveyResults = (responses, iteratee, resultKey) => {
  const getAverage = data =>
    decimalToPercent(data.totalPositiveResponses / data.totalResponses)

  const groupedResponses = groupBy(responses, iteratee)

  const average = []

  const keys = Object.keys(groupedResponses)

  for (let i = 0; i < keys.length; i++) {
    const totals = groupedResponses[keys[i]].reduce(
      (acc, cur) => {
        return {
          totalResponses: acc.totalResponses + parseInt(cur.totalResponses),
          totalPositiveResponses:
            acc.totalPositiveResponses + parseInt(cur.totalPositiveResponses)
        }
      },
      {
        totalResponses: 0,
        totalPositiveResponses: 0
      }
    )

    average.push({
      [iteratee]: keys[i],
      [resultKey]: getAverage(totals)
    })
  }
  return average
}

/**
 * helper function to aggregate the responses
 * Given an array of responses, group the responses by survey number on question id
 * then pass the results into the aggregateSurveyResults function
 * It will return an array of the formatted data
 * @function aggregateSurveysBySurvey
 * @author Anh Tran
 * @param {Array} surveyData
 * @returns {Array}
 */
const aggregateSurveysBySurvey = surveyData => {
  const averagesBySurvey = {}

  const responsesBySurvey = groupBy(surveyData, 'survey_number')

  for (const surveyNumber in responsesBySurvey) {
    const allResults = []
    const currentSurvey = responsesBySurvey[surveyNumber]
    for (let i = 0; i < currentSurvey.length; i++) {
      const results = orderBy(currentSurvey[i].results, 'question_id')
      allResults.push(results)
    }
    const averages = aggregateSurveyResults(
      flatten(allResults),
      'question',
      'positiveResponses'
    )
    averagesBySurvey[`survey${surveyNumber}`] = averages
  }
  return formatSurveyData(averagesBySurvey)
}

const averageAllSurveys = (surveyData, compIndex) => {
  const allResults = surveyData.map(({ results }) =>
    orderBy(results, 'question_id')
  )
  return aggregateSurveyResults(flatten(allResults), 'question', compIndex)
}

/**
 * helper function to format the data
 * Given an array of averages by survey number, it will format the data
 * such that the return array is an array of objects in the following format:
 * {question, survey1 , survey2 , ...}
 * @function formatSurveyData
 * @author Anh Tran
 * @param {Array} averages
 * @returns {Array}
 */
const formatSurveyData = averages => {
  const data = []
  for (const survey in averages) {
    const currentSurvey = averages[survey]
    for (let i = 0; i < currentSurvey.length; i++) {
      const { question, positiveResponses } = currentSurvey[i]
      data[i] = { ...data[i], question, [survey]: positiveResponses }
    }
  }
  return data
}
