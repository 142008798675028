import getConfig from '../config/getConfig'
import fetch from 'isomorphic-fetch'
import urlJoin from 'url-join'

const config = getConfig()

export default function apiRequest(endpoint, data, options = {}) {
  return getAuthorizationHeader()
    .then(Authorization =>
      fetch(urlJoin(config.apiServer || '/', endpoint), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...Authorization
        },
        credentials: 'include',
        ...options,
        body: JSON.stringify(data)
      })
    )

    .then(res => {
      if (res.redirected) return { ok: true }
      return res.status > 200 ? Promise.reject(res) : res.json()
    })
    .then(res => (res.ok ? res : Promise.reject(res)))
    .catch(e => {
      if (e.error && e.error.errors) {
        if (
          e.error.errors['session_expired'] ||
          e.error.errors['no_authorization_cookie']
        ) {
          window.location = config.clientUrl + '/login'
        }
      }
      return Promise.reject(e)
    })
}

export const get = (endpoint, Authorization) =>
  fetch(urlJoin(config.apiServer || '/', endpoint), {
    headers: {
      Authorization,
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    credentials: 'include'
  })
    .then(res => {
      if (res.redirected) return { ok: true }
      return res.status > 200 ? Promise.reject(res) : res.json()
    })
    .then(res => (res.ok ? res : Promise.reject(res)))

/**
 * @function rpc
 * @author Julius Diaz Panoriñgan
 * @param {Function} method the API method to call
 * @param {Object} data an object w/ arguments for the API method
 * @param {Object} options extra request headers that will be applied
 * @returns {Promise} indicates success/failure of server API call
 * This is an alternate version of rpc that directly returns a promise,
 * instead of returning a thunk that must be dispatched to return a promise.
 */
export const rpc = (method, data, options) =>
  apiRequest(`api/${method}`, data, options)

const getAuthorizationHeader = async () => {
  const currentUser = window.firebase.auth().currentUser
  if (!currentUser) return {}
  const token = await currentUser.getIdToken()
  return { Authorization: `Bearer ${token}` }
}
