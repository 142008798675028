import useSchoolMembersByRoles from 'shared/firestore/useSchoolMembersByRoles'
import useDistrict from 'hooks/useDistrict/useDistrict'
import { Modal, Form, Select } from 'antd'
import useForm from 'shared/hooks/useForm'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import schema from 'schema'
import React from 'react'
import {
  alphaCompare,
  getFullName,
  getStudentNameWithId,
  isPD,
  getId
} from 'shared/utils'

import './AddToClassModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const AddToClassModal = ({
  currentClass,
  addStudent,
  currentSchool,
  additionalUsers = [],
  onOk,
  rpc,
  ...rest
}) => {
  const { id: classId, district } = currentClass

  const [currentDistrict] = useDistrict(district)
  const studentIdType = currentDistrict && currentDistrict.uniqueIdField

  const [label, role, roles, endpoint] = addStudent
    ? [
      'Student',
      'student',
      isPD(currentClass) ? ['admin', 'teacher'] : ['student'],
      'addStudent'
    ]
    : ['Teacher', 'teacher', ['admin', 'teacher'], 'addTeacher']

  const [users, loading] = useSchoolMembersByRoles(currentSchool, roles)

  const filteredAdditional = additionalUsers
    .filter(({ id }) => !currentClass.members.includes(id))
    .sort((a, b) => alphaCompare(getFullName(a), getFullName(b)))

  const filteredUsers = users
    .filter(({ id }) => !currentClass.members.includes(id))
    .sort((a, b) => alphaCompare(getFullName(a), getFullName(b)))

  const { handleSubmit, getFieldProps, getFieldError, isSubmitting } = useForm({
    onSubmit: async vals => {
      await rpc(`class.${endpoint}`, vals)
      onOk()
    },
    initialValues: {
      class: classId
    },
    validationSchema: schema.class[endpoint]
  })

  if (loading) return <React.Fragment />

  return (
    (!addStudent || studentIdType) && (
      <Modal
        {...rest}
        title={`Add ${label} To Class`}
        confirmLoading={isSubmitting}
        onOk={handleSubmit}
        okText='Save'>
        <Form {...formItemLayout}>
          <Form.Item {...getFieldError(role)} label={label}>
            <Select
              {...getFieldProps(role, 'select')}
              placeholder={`Select a ${role}`}
              showSearch
              filterOption={(input, option) => {
                const { optLabel = '' } = option.props
                return optLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}>
              {filteredAdditional.length
                ? [
                  <Select.OptGroup
                    key='nineDotsTeachers'
                    label='9 Dots Teachers'>
                    {getOptions(filteredAdditional)}
                  </Select.OptGroup>,
                  <Select.OptGroup key='teachers' label='Teachers'>
                    {getOptions(filteredUsers)}
                  </Select.OptGroup>
                ]
                : getOptions(filteredUsers, studentIdType)}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  )
}

/** @todo propTypes */

export default connect(
  null,
  { rpc }
)(AddToClassModal)

const getOptions = (users, idType) =>
  users.map(({ id, ...user }) => (
    <Select.Option
      key={id}
      value={id}
      optLabel={getFullName(user) + getId(user, idType)}>
      {/* note: this falls back to getFullName if no idType is specified */}
      {getStudentNameWithId(user, idType)}
    </Select.Option>
  ))
