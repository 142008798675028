import { Switch, Route } from 'react-router-dom'
import React from 'react'
import isNinedotsRole from 'shared/utils/isNinedotsRole'
import DataTeacherEngagement from './DataTeacherEngagement/DataTeacherEngagement'
import HeaderLayout from 'components/HeaderLayout'
import adminAnalyticsTracker from './analytics'
import DataProficiency from './DataProficiency'
import Header from 'shared/components/Header'
import DataMotivation from './DataMotivation'
import DataOverview from './DataOverview'
import { useSelector } from 'react-redux'
import DataContext from './DataContext'
import useDataDashboard from './useDataDashboard'
import { DATA_DASHBOARD_PAGES } from './utils'
import './Data.less'

const Data = () => {
  const {
    filterGroups,
    sectionData,
    loading,
    currentDataPage,
    onGetData,
    dispatchFilterGroupsAction
  } = useDataDashboard()
  const role = useSelector(state => state.firebase.profile.role)
  const trackerSource = 'Admin Data Tab'
  const analyticsTracker = adminAnalyticsTracker(trackerSource)

  return (
    <HeaderLayout
      header={<Header title='Data' menu={getMenu(role, analyticsTracker)} />}
    >
      <DataContext
        filterGroups={filterGroups}
        dispatchFilterGroupsAction={dispatchFilterGroupsAction}
        onGetData={onGetData}
        currentDataPage={currentDataPage}
      />
      <Switch>
        <Route
          path={`/data/${DATA_DASHBOARD_PAGES.OVERVIEW}`}
          render={() => (
            <DataOverview
              loading={loading}
              data={sectionData[DATA_DASHBOARD_PAGES.OVERVIEW] || {}}
            />
          )}
        />
        <Route
          exact
          path={`/data/${DATA_DASHBOARD_PAGES.PROFICIENCY}`}
          render={() => (
            <DataProficiency
              loading={loading}
              data={sectionData[DATA_DASHBOARD_PAGES.PROFICIENCY] || {}}
            />
          )}
        />
        <Route
          exact
          path={`/data/${DATA_DASHBOARD_PAGES.MOTIVATION}`}
          render={() => (
            <DataMotivation
              loading={loading}
              data={sectionData[DATA_DASHBOARD_PAGES.MOTIVATION] || {}}
            />
          )}
        />
        <Route
          exact
          path={`/data/${DATA_DASHBOARD_PAGES.TEACHER_ENGAGEMENT}`}
          render={() => (
            <DataTeacherEngagement
              loading={loading}
              data={sectionData[DATA_DASHBOARD_PAGES.TEACHER_ENGAGEMENT] || {}}
            />
          )}
        />
      </Switch>
    </HeaderLayout>
  )
}

export default Data

const getMenu = (role, analyticsTracker) => {
  const menuOptions = [
    {
      label: 'Overview',
      url: `/data/overview`,
      onClick: analyticsTracker.overview,
      ninedotsOnly: false
    },
    {
      label: 'Student Proficiency',
      url: `/data/proficiency`,
      onClick: analyticsTracker.studentProficiency,
      ninedotsOnly: false
    },
    {
      label: 'Student Motivation',
      url: `/data/motivation`,
      onClick: analyticsTracker.studentMotivation,
      ninedotsOnly: false
    },
    {
      label: 'Teacher Engagement',
      url: `/data/engagement`,
      onClick: analyticsTracker.teacherEngagement,
      ninedotsOnly: false
    }
  ]

  return isNinedotsRole(role)
    ? menuOptions
    : menuOptions.filter(({ ninedotsOnly }) => !ninedotsOnly)
}
