import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Text,
  Cell
} from 'recharts'
import { Card } from 'antd'
import React from 'react'

const ProficiencyChart = ({ proficiencyPercents, graphInfo }) => {
  const renderCustomLabel = ({ x, y, fill, width, value }) => (
    <text
      x={x}
      y={y}
      dx={width / 2}
      dy={-4}
      fontSize={Math.min(16, width / 2)}
      fill={fill}
      textAnchor='middle'
    >{`${Math.floor(value)}%`}</text>
  )

  const numComparisons = graphInfo.length
  const renderCustomXAxisTick = ({ x, y, payload }) => (
    <Text
      x={x}
      y={y}
      fontSize={13}
      angle={numComparisons < 6 ? 0 : -35}
      textAnchor={numComparisons < 6 ? 'middle' : 'end'}
      width={100}
      dy={15}
    >
      {graphInfo[payload.index].label}
    </Text>
  )

  return (
    <Card title='Student Proficiency Percent' className='proficiency-chart'>
      <ResponsiveContainer width='100%' height={500}>
        {
          <BarChart
            data={proficiencyPercents}
            margin={{ top: 10, bottom: 70, left: 10 }}
          >
            <CartesianGrid strokeDasharray='3 3' vertical={false} />
            <XAxis
              tick={renderCustomXAxisTick}
              interval={0}
              dataKey='compIndex'
            />
            <YAxis unit='%' domain={[0, 100]} />
            <Bar dataKey='proficiencyPercent' label={renderCustomLabel}>
              {proficiencyPercents.map((_, index) => (
                <Cell key={`cell-${index}`} fill={graphInfo[index].color} />
              ))}
            </Bar>
          </BarChart>
        }
      </ResponsiveContainer>
    </Card>
  )
}

export default ProficiencyChart
