import { Route, Switch, Redirect, Router as DomRouter } from 'react-router-dom'
import SidebarLayout from 'components/SidebarLayout'
import Loading from 'shared/components/Loading'
import useOrgs from 'hooks/useOrgs/useOrgs'
import Integrations from '../Integrations'
import { history } from 'configureStore'
import AccountInfo from '../AccountInfo'
// import Analytics from '../Analytics'
import Schools from '../Schools'
import People from '../People'
import get from 'lodash/get'
import Data from '../Data'
import React from 'react'

import './Router.less'

const Router = () => {
  const { loading, orgs } = useOrgs()

  if (loading) return <Loading />

  //  Redirect to first school in list if no
  //  schoolId is provided in the route params
  const schoolId = get(orgs, 'schools[0].id')

  return (
    <DomRouter history={history}>
      <Route path='/:rootPath?'>
        <SidebarLayout>
          <Switch>
            <Route path='/' exact component={AccountInfo} />
            <Route path='/schools' component={Schools} />
            <Route path='/integrations' component={Integrations} />

            <Redirect exact path='/people' to={`/people/${schoolId}`} />
            <Route path='/people/:schoolId/:classId?' component={People} />

            <Redirect exact path='/data' to='/data/overview' />
            <Route path='/data/:category/:schoolId?' component={Data} />

            {/* <Redirect exact path='/analytics' to='/analytics/users' /> */}
            {/* <Route path='/analytics/:type' component={Analytics} /> */}
          </Switch>
        </SidebarLayout>
      </Route>
    </DomRouter>
  )
}

export default Router
