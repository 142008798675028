/**
 * @file a hook for verifying whether a user's session has expired
 * @author Giovanni Bonilla
 * modeled after verifySession middleware in client by
 * @author Daniel Levitt
 */

import TimeoutModal from 'shared/components/TimeoutModal'
import { useModal } from 'shared/hooks/useModal'
import { combineLatest, interval } from 'rxjs'
import apiRequest from 'shared/utils/api'
import { object } from 'rxfire/database'
import React, { useEffect } from 'react'
import { map } from 'rxjs/operators'

const modalDuration = 60 * 1000

/**
 * @function useVerifySession
 * @param {Number} expiration - session expiration date represented in milliseconds
 * @param {Object} profile object containing user info
 * @param {Object.<string>} profile.id user id
 * @description Gets expiration time from firebase realtime database and adjusts for server time offset.
 * If session expires in less than {modalDuration}, opens a modal to prompt the user to extend session. If
 * session has expired, logs user out.
 */
const useVerifySession = (expiration, { id: userId }) => {
  const db = window.firebase.database()
  const auth = window.firebase.auth
  const [showTimeoutModal, hideTimeoutModal] = useModal(
    <TimeoutModal expires={expiration} />
  )

  useEffect(() => {
    if (userId) {
      // session expiration timestamp observable
      const expires$ = object(db.ref(`sessions/${userId}/expires`)).pipe(
        map(({ snapshot }) => snapshot.val())
      )
      // server timestamp offset observable
      const offset$ = object(db.ref(`.info/serverTimeOffset`)).pipe(
        map(({ snapshot }) => snapshot.val())
      )
      const timer$ = interval(1000)

      const expirationObs$ = combineLatest(expires$, offset$, timer$)
        .pipe(map(([expires = 0, offset = 0]) => expires - offset))
        .subscribe(expires => {
          const currentTime = Date.now()
          const hasTimedOut = !expires || expires < currentTime
          const displayTimeout = expires - modalDuration < currentTime
          if (hasTimedOut) {
            hideTimeoutModal()
            apiRequest('auth/logout').then(() => auth().signOut())
          } else if (displayTimeout) {
            showTimeoutModal()
          }
        })

      return () => {
        expirationObs$.unsubscribe()
      }
    }
  }, [expiration, userId])
}

export default useVerifySession
