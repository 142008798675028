import palette from 'shared/theme/vars/palette'
import moment from 'moment' 

/**
 * @function teacherProgressToColor
 * @description Given a timestamp of the last time a lesson was taught and how
 * many lessons have been taught, returns a color based that teacher's progress.
 * Red is returned if it's been 2 weeks or more since the last time a teacher
 * taught or they haven't taught their first lesson. Yellow is returned if it's
 * been more than a week and less than 2 weeks since they last taught a lesson.
 * Green is returned if a teacher has taught the benchmark amount of lessons or
 * has taught within the week.
 * @author Ana Mesias
 * @param {String} lastLessonTaughtTs
 * @param {String} lessonsTaught
 * @returns {String}
 */
export default function teacherProgressToColor(lastLessonTaughtTs, lessonsTaught) {
  if (!lastLessonTaughtTs) return palette['@red']

  const benchmark = 25
  if (parseInt(lessonsTaught, 10) >= benchmark) return palette['@green']

  const now = moment().startOf('day')
  const lastTaught = moment(lastLessonTaughtTs).startOf('day')
  const numOfDaysAgo = now.diff(lastTaught, 'days')
  const daysInWeek = 7

  if (numOfDaysAgo >= daysInWeek * 2) {
    return palette['@red']
  } else if (numOfDaysAgo > daysInWeek) {
    return palette['@yellow']
  } else {
    return palette['@green']
  }
}