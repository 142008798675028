import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import createStore, { rrfbConfig } from './configureStore'
import { ModalProvider } from 'shared/hooks/useModal'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import Root from 'app/Root'
import React from 'react'
import './index.less'

const store = createStore()
const config = rrfbConfig(store)

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...config}>
      <ModalProvider>
        <Root />
      </ModalProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)
