import { gradeValueToId, grades, gradesToText } from 'shared/utils/data'
import CreateClassModal from 'components/CreateClassModal'
import { Link, withRouter } from 'react-router-dom'
import { useModal } from 'shared/hooks/useModal'
import { Menu, Row, Button, Col } from 'antd'
import SchoolPicker from './SchoolPicker'
import useClasses from 'hooks/useClasses'
import useOrgs from 'hooks/useOrgs'
import React from 'react'

const ContextMenu = ({
  match: {
    params: { schoolId }
  }
}) => {
  const { orgs } = useOrgs()
  const { schools = [] } = orgs
  const { classes, currentSchool } = useClasses()
  const pathRoot = `/people/${schoolId}`

  const [openCreateClassModal] = useModal(
    <CreateClassModal currentSchool={currentSchool} />
  )

  return (
    <>
      {schools.length > 1 && (
        <SchoolPicker schools={schools} currentSchool={currentSchool} path='/people' />
      )}
      <Menu selectedKeys={[window.location.pathname]}>
        <Menu.Item key={pathRoot}>
          <Link to={pathRoot}>All Users</Link>
        </Menu.Item>
        <Menu.Item key={`${pathRoot}/pending`}>
          <Link to={`${pathRoot}/pending`}>Invites Pending</Link>
        </Menu.Item>
        {/* <Menu.Item key={`${pathRoot}/unassigned`}>
          <Link to={`${pathRoot}/unassigned`}>Unassigned Users</Link>
        </Menu.Item> */}
        <Menu.Divider />
        <Menu.ItemGroup
          title={
            <Row type='flex' justify='space-between' align='middle'>
              <Col style={{ lineHeight: '24px' }}>Classes</Col>
              <Button size='small' onClick={openCreateClassModal}>
                Add Class
              </Button>
            </Row>
          }>
          {classes.length ? (
            grades.map(({ value }) => {
              const classesByGrade = classes.filter(
                ({ grade }) => grade === value
              )
              const gradeText = gradesToText(gradeValueToId(value), true)
              return classesByGrade.length ? (
                <Menu.ItemGroup title={gradeText}>
                  {classesByGrade.map(({ id, displayName }) => (
                    <Menu.Item key={`${pathRoot}/${id}`}>
                      <Link to={`${pathRoot}/${id}`} className='ellipsis'>
                        {displayName}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              ) : (
                <React.Fragment />
              )
            })
          ) : (
            <Menu.Item className='empty-item'>No classes yet</Menu.Item>
          )}
        </Menu.ItemGroup>
      </Menu>
    </>
  )
}

export default withRouter(ContextMenu)
