import HeaderLayout from 'components/HeaderLayout'
import { Card, Switch } from 'antd'
import React from 'react'

import faker from 'faker'

import './Integrations.less'

const Integrations = props => {
  return (
    <HeaderLayout
      title='Integrations'
      className='integrations'
      contentContainerStyle={{ maxWidth: 800 }}>
      <Card extra={<Switch />} title='Clever Roster Sync'>
        {faker.lorem.paragraph()}
      </Card>
      <Card extra={<Switch />} title='Clever Sign In'>
        {faker.lorem.paragraph()}
      </Card>
      <Card extra={<Switch />} title='Google Sign In'>
        {faker.lorem.paragraph()}
      </Card>
    </HeaderLayout>
  )
}

Integrations.propTypes = {}

export default Integrations
