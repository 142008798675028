import { EventTypes } from 'redux-segment'
import getConfig from './config/getConfig'
import apiRequest from './utils/api'

const isProduction = getConfig().projectId === 'school-5927d'
const deployment = isProduction ? 'production' : 'development'

const rpc = (method, data, meta) => {
  const thunk = async () => {
    return apiRequest(`api/${method}`, data)
  }
  thunk.meta = meta
  return thunk
}

const trackLogout = () => (dispatch, getState) => {
  if (!getState().user) return

  const type = 'LOGOUT'

  return dispatch({
    type,
    meta: {
      analytics: [
        {
          eventType: EventTypes.track,
          eventPayload: {
            event: type,
            properties: {
              deployment
            }
          }
        },
        {
          eventType: EventTypes.reset
        }
      ]
    }
  })
}

const trackLogin = ({ user: userData }) => async (
  dispatch,
  _,
  { getFirebase }
) => {
  const { uid } = userData.user || {}
  if (!uid) return

  const user = await getFirebase()
    .firestore()
    .collection('users')
    .doc(uid)
    .get()
    .then(doc => ({ ...doc.data(), id: doc.id }))

  const type = 'LOGIN'

  return dispatch({
    type,
    meta: {
      analytics: [
        getIdInfo(user),
        {
          eventType: EventTypes.track,
          eventPayload: {
            event: type,
            properties: {
              userId: uid,
              deployment
            }
          }
        }
      ]
    }
  })
}

const identify = (user = {}) => async (dispatch) => {
  /** @todo initialize firebase in shared package, and import this as a ref from firestore/refs.js */
  const classes = await window.firebase
    .firestore()
    .collection('classes')
    .where('students', 'array-contains', user.uid)
    .get()
    .then(qSnap => (qSnap.empty ? [] : qSnap.docs.map(doc => doc.id)))

  if (!user.uid) return

  return (
    user.uid &&
    dispatch({
      type: 'IDENTIFY',
      meta: {
        analytics: getIdInfo({ ...user, id: user.uid }, classes)
      }
    })
  )
}

function getIdInfo (user, classes) {
  return {
    eventType: EventTypes.identify,
    eventPayload: {
      userId: user.id,
      traits: {
        classes,
        description: user.role,
        id: user.id
      }
    }
  }
}

const ignorePaths = ['/authhandler']

const trackPageViews = loggedIn => (dispatch, getState) => {
  const { pathname } = window.location
  if (!getState().user) return

  if (ignorePaths.indexOf(pathname) === -1 && isProduction) {
    dispatch({
      type: 'PAGE_VIEW',
      meta: {
        analytics: EventTypes.page
      }
    })
  }
}

const setUrl = (url, opts = {}) => (dispatch, getState, { history }) => {
  return history.push(url, opts)
}

const replaceUrl = (url, opts = {}) => (dispatch, getState, { history }) => {
  return history.replace(url, opts)
}

export {
  setUrl,
  replaceUrl,
  rpc,
  trackPageViews,
  identify,
  trackLogin,
  trackLogout
}
