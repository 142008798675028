import React, { useState } from 'react'
import { Modal, Button, Collapse, Row, Input, Icon } from 'antd'
import DataFilterSelector from '../DataFilterSelector/DataFilterSelector'

import './DataEditModal.less'

const { Panel } = Collapse

export default ({
  visible,
  filterGroups,
  pageFilters,
  onOkOrCancel,
  onFilterChange,
  onAddFilterGroup,
  onDeleteFilterGroup,
  onUpdateLabel,
  onUpdateColor
}) => {
  const [editingLabelIndex, setEditingLabelIndex] = useState(null)
  const finalizeLabelEdit = (index, value) => {
    setEditingLabelIndex(null)
    if (value === '') {
      onUpdateLabel(index, index === 0 ? 'Default' : `Comparison ${index}`)
    }
  }

  return (
    <Modal
      className='data-edit-modal'
      width={'70vw'}
      visible={visible}
      onCancel={onOkOrCancel}
      onOk={onOkOrCancel}
      footer={[
        <Button key='add-btn' onClick={onAddFilterGroup}>
          + Add Comparison
        </Button>,
        <Button key='done-btn' type='primary' onClick={onOkOrCancel}>
          Done
        </Button>
      ]}
    >
      <div className='collapse-container'>
        <Collapse>
          {filterGroups.map(({ filterState, label, color }, index) => (
            <Panel
              key={`filter-group-${index}`}
              header={
                <Row justify='space-between' align='middle' type='flex'>
                  <Row justify='start' align='middle' type='flex'>
                    <input
                      className='color-picker'
                      type='color'
                      value={color}
                      onClick={e => e.stopPropagation()}
                      onChange={e => onUpdateColor(index, e.target.value)}
                    />
                    {editingLabelIndex === index ? (
                      <Input
                        className='label-edit-input'
                        onBlur={e => finalizeLabelEdit(index, e.target.value)}
                        onFocus={e => e.target.select()}
                        value={label}
                        autoFocus={true}
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          finalizeLabelEdit(index, e.target.value)
                        }
                        onChange={e => onUpdateLabel(index, e.target.value)}
                        onClick={e => e.stopPropagation()}
                      />
                    ) : (
                      <h2
                        className='filter-group-label'
                        onDoubleClick={() => setEditingLabelIndex(index)}
                        onClick={e => e.stopPropagation()}
                      >
                        {label}
                      </h2>
                    )}
                    <Icon
                      className='edit-label-icon'
                      type={
                        editingLabelIndex === index ? 'close-circle' : 'edit'
                      }
                      theme='filled'
                      onClick={e => {
                        e.stopPropagation()
                        setEditingLabelIndex(
                          editingLabelIndex === index ? null : index
                        )
                      }}
                    />
                  </Row>
                  {filterGroups.length > 1 && (
                    <Button
                      className='delete-btn'
                      shape='circle'
                      icon='delete'
                      onClick={e => {
                        e.stopPropagation()
                        onDeleteFilterGroup(index)
                      }}
                    />
                  )}
                </Row>
              }
            >
              <Row justify='space-between' align='middle' type='flex'>
                {pageFilters.map(category => (
                  <div className='filter-selector' key={`${category}-filter`}>
                    <DataFilterSelector
                      category={category}
                      filterData={filterState[category]}
                      onFilterChange={(category, selectedValue) =>
                        onFilterChange(category, selectedValue, index)
                      }
                    />
                  </div>
                ))}
              </Row>
            </Panel>
          ))}
        </Collapse>
      </div>
    </Modal>
  )
}
