import { Icon, Tag, Row, Col } from 'antd'
import React from 'react'

import PropTypes from 'prop-types'
import './Tagger.less'

const Tagger = ({ tags, editable, onAdd, onRemove, addText = 'Add' }) => {
  return (
    <>
      {tags.map(tag => (
        <Tag className='tagger-tag' key={tag.value}>
          <Row type='flex'>
            <Col className='ellipsis'>{tag.label}</Col>
            <Col>
              {editable && <Icon type='cross' onClick={() => onRemove(tag)} />}
            </Col>
          </Row>
        </Tag>
      ))}
      {!!editable && (
        <Tag onClick={onAdd} className='tagger-tag add-tag'>
          <Icon type='plus' />
          &ensp;{addText}
        </Tag>
      )}
    </>
  )
}

Tagger.propTypes = {
  tags: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func
}

export default Tagger
