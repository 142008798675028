import InputConfirmModal from 'shared/components/InputConfirmModal'
import { Dropdown, Menu, Button, Icon, Tooltip } from 'antd'
import CreateSchoolModal from './CreateSchoolModal'
import { useModal } from 'shared/hooks/useModal'
import { isRosterSynced } from 'shared/utils'
import useOrgs from '../../hooks/useOrgs'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import React from 'react'

const SchoolMenu = ({ school, rpc }) => {
  const {
    orgs: { district = {} }
  } = useOrgs()
  const rosterSyncProvider = isRosterSynced(school)
  const canEditSchools = rosterSyncProvider.canEditField('schools')
  const [openEditModal] = useModal(
    <CreateSchoolModal edit school={school} district={district} />
  )

  const archiveSchool = async function () {
    await rpc('school.archive', { school: school.id })
    window.location.reload()
  }
  const [openArchiveModal, hideArchiveModal] = useModal(() => (
    <InputConfirmModal
      title={`Remove ${school.displayName}?`}
      content={`This action will permanently delete ${school.displayName} and cannot be undone. 
      Are you absolutely sure you want to do this? If so, type the name of the school to confirm.`}
      okText={school.displayName}
      okButtonText='Remove School'
      hide={hideArchiveModal}
      onConfirm={archiveSchool} />
  ))

  return (
    <Dropdown
      trigger={['click']}
      placement='bottomRight'
      overlay={
        <Menu onClick={menuHandler}>
          <Menu.Item key='link'>
            <Link to={`/people/${school.id}`}>
              <Icon type='user' />
              View People
            </Link>
          </Menu.Item>
          <Menu.Item key='edit' disabled={!canEditSchools}>
            <Tooltip
              title={
                !canEditSchools &&
                `Can't edit ${rosterSyncProvider.providerDisplayName} synced schools`
              }
              overlayClassName='small'
              placement='left'>
              <Icon type='edit' />
              Edit Details
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            className='danger'
            key='archive'
            disabled={!canEditSchools}>
            <Tooltip
              title={
                !canEditSchools &&
                `Can't archive ${rosterSyncProvider.displayName} synced schools`
              }
              overlayClassName='small'
              placement='left'>
              <Icon type='delete' />
              Remove School
            </Tooltip>
          </Menu.Item>
        </Menu>
      }>
      <Button icon='ellipsis' shape='circle' />
    </Dropdown>
  )

  function menuHandler ({ key }) {
    switch (key) {
      case 'edit':
        return openEditModal()
      case 'archive':
        return openArchiveModal()
    }
  }
}

export default connect(
  null,
  { rpc }
)(SchoolMenu)
