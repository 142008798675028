import { Modal, Form, Input, Select } from 'antd'
import { grades } from 'shared/utils/data'
import useForm from 'shared/hooks/useForm'
import { rpc } from 'shared/utils/api'
import PropTypes from 'prop-types'
import schema from 'schema'
import React from 'react'

import './EditStudentModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const EditStudentModal = ({ create, classId, user, onOk, ...rest }) => {
  const { handleSubmit, getFieldProps, getFieldError, isSubmitting } = useForm({
    onSubmit: async vals => {
      await rpc('user.editUser', vals)
      onOk()
    },
    initialValues: {
      name: user.name,
      grade: user.grade,
      id: user.id
    },
    validationSchema: schema.user.editUser
  })

  return (
    <Modal
      {...rest}
      confirmLoading={isSubmitting}
      title='Edit Student'
      okText='Save'
      onOk={handleSubmit}>
      <Form {...formItemLayout}>
        <Form.Item {...getFieldError('name.given')} label='First Name'>
          <Input {...getFieldProps('name.given')} placeholder='First Name' />
        </Form.Item>
        <Form.Item {...getFieldError('name.family')} label='Last Name'>
          <Input {...getFieldProps('name.family')} placeholder='Last Name' />
        </Form.Item>
        <Form.Item {...getFieldError('grade')} label='Grade'>
          <Select
            {...getFieldProps('grade', 'select')}
            placeholder='Select a grade'>
            {grades.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

EditStudentModal.propTypes = {
  user: PropTypes.object
}

export default EditStudentModal
