const Schema = require('@weo-edu/schema')
const validate = require('@weo-edu/validate')
const { displayName, firebaseRef } = require('../utils')

const cleverDistrict = Schema()
  .prop(
    'data',
    Schema()
      .prop('id', Schema('string'))
      .prop('name', Schema('string'))
      .prop('state', Schema('string'))
      .prop('last_sync', Schema('string'))
      .prop('launch_data', Schema('string'))
      .prop('mdr_number', Schema('string'))
      .prop('nces_id', Schema('string'))
      .prop('error', Schema('string'))
      .prop('sis_type', Schema('string'))
      .prop('pause_start', Schema('string'))
      .prop('pause_end', Schema('string'))
  )
  .prop('uri', Schema('string'))

const District = Schema()
  .prop('id', Schema('string'))
  .prop('displayName', displayName)
  .prop('lastSyncId')
  .prop('providers', Schema().prop('clever', cleverDistrict.schema))

const update = Schema()
  .prop('district', firebaseRef)
  .prop('displayName', displayName)
  .prop('passwordEnabled', Schema('boolean'))
  .others(false, 'invalid_keys')

exports.default = District
exports.update = validate(update)
