import React, { useState } from 'react'
import { Modal, Input, Icon } from 'antd'
import PropTypes from 'prop-types'
import './InputConfirmModal.less'

const InputConfirmModal = ({
  title,
  content,
  hide,
  onConfirm,
  okText,
  okButtonText,
  ...rest
}) => {
  const [text, setText] = useState('')
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async event => {
    setLoading(true)
    await onConfirm(event)
    setLoading(false)
    hide()
  }

  return (
    <Modal
      {...rest}
      onOk={handleSubmit}
      okText={okButtonText}
      okButtonProps={{
        disabled: text.toLowerCase() !== okText.toLowerCase().trim(),
        type: 'danger'
      }}
      cancelButtonProps={{ disabled: false }}
      confirmLoading={isLoading}
      title={title}>
      <div className='modal-body'>
        <Icon
          type='exclamation-circle'
          theme='twoTone'
          twoToneColor='#ec5863'
          style={{ fontSize: '35px' }} />
      </div>
      <br />
      <h4>{content}</h4>
      <br />
      <div className='modal-body'>
        <Input
          onChange={e => setText(e.target.value)}
          placeholder={`Type ' ${okText.trim()} '`}
          style={{ width: 325 }} />
      </div>
    </Modal>
  )
}

InputConfirmModal.defaultProps = {
  okButtonText: 'Remove'
}

InputConfirmModal.propTypes = {}

export default InputConfirmModal
