export default function getRoleDisplay (role) {
  return {
    districtAdmin: 'District Admin',
    admin: 'Admin',
    teacher: 'Teacher',
    student: 'Student',
    ninedotsTeacher: 'Coding Coordinator',
    ninedotsAdmin: '9 Dots Admin'
  }[role]
}
