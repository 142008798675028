import { NavLink } from 'react-router-dom'
import vars from 'shared/theme/vars/vars'
import { Menu, Row, Layout, Col } from 'antd'
import React from 'react'
import './Header.less'

const Header = ({ title, menu = [], extra, selectedKeys, actions }) => {
  const hasMenu = menu && !!menu.length
  const fullHeight = vars['@layout-header-height']
  const lineHeight = fullHeight / 2

  return (
    <Layout.Header className='app-header' style={{ height: fullHeight + 'px' }}>
      <Col className='title-col flex-grow '>
        <Row className={'app-header-title' + (hasMenu ? ' has-menu' : '')}>
          <h2 className='ellipsis'>{title}</h2>
          <span className='extra'>{!!extra && extra}</span>
        </Row>

        {hasMenu && (
          <Menu
            mode='horizontal'
            className='mini-menu'
            selectedKeys={selectedKeys || [window.location.pathname]}
            style={{ lineHeight: lineHeight - 1 + 'px' }}>
            {menu.map(({ url, label, onClick }) => (
              <Menu.Item key={url}>
                <NavLink to={url} onClick={onClick}>
                  {label}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </Col>
      <Col className='action-col'>{actions}</Col>
    </Layout.Header>
  )
}

export default Header
