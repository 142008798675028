const { default: Module } = require('../module')
// const { firebaseRef, uuid } = require('../utils')
// const validate = require('@weo-edu/validate')
const Schema = require('@weo-edu/schema')

const activityProgress = Schema()
  .prop('score', Schema('number'))
  .prop('progress', Schema('number'))
  .prop('started', Schema('boolean'))
  .prop('completed', Schema('boolean'))

// Object of user progress by user id
const userProgress = Schema().prop(/userId/, activityProgress.schema)

// Document that contains object with keys activityId
const lessonProgress = Schema().prop(/userId/, userProgress.schema)

// Collection of documents by lessonID
Schema().prop(/lessonId/, lessonProgress.schema)

exports.default = Module
