import Header from 'shared/components/Header'
import React from 'react'
import './HeaderLayout.less'

const HeaderLayout = ({
  contentContainerStyle,
  children,
  header,
  title,
  ...rest
}) => {
  return (
    <span {...rest}>
      {title ? <Header title={title} /> : header || <></>}
      <div className='app-content' style={contentContainerStyle}>
        {children}
      </div>
    </span>
  )
}

/** @todo propTypes */

export default HeaderLayout
