import { districtsRef } from 'shared/firestore/refs'
import useFirestore from 'shared/hooks/firestore'
import { Select } from 'antd'
import React from 'react'
import './DistrictSwitcher.less'

const DistrictSwitcher = ({ onSelect, selectedDistrict }) => {
  const [snap, loading] = useFirestore(districtsRef(), { get: true })
  const districts = loading ? [] : snap.docs.map(doc => doc.data())
  return (
    <Select
      showSearch
      placeholder='Select a district'
      style={{ width: '200px' }}
      onSelect={onSelect}
      disabled={loading}
      value={loading ? '' : selectedDistrict}
      loading={loading}>
      {districts.map(district => (
        <Select.Option key={district.id} value={district.id}>
          {district.displayName}
        </Select.Option>
      ))}
    </Select>
  )
}

/** @todo propTypes */

export default DistrictSwitcher
