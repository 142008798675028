module.exports = [
  { path: './apple.png', id: 0, value: 'apple' },
  { path: './bat.png', id: 1, value: 'bat' },
  { path: './bea.png', id: 2, value: 'bea' },
  { path: './butterfly.png', id: 3, value: 'butterfly' },
  { path: './camel.png', id: 4, value: 'camel' },
  { path: './cupcake.png', id: 5, value: 'cupcake' },
  { path: './dino.png', id: 6, value: 'dino' },
  { path: './don.png', id: 7, value: 'don' },
  { path: './elephant.png', id: 8, value: 'elephant' },
  { path: './gorilla.png', id: 9, value: 'gorilla' },
  { path: './kitty.png', id: 10, value: 'kitty' },
  { path: './lotus.png', id: 11, value: 'lotus' },
  { path: './monster.png', id: 12, value: 'monster' },
  { path: './narwhal.png', id: 13, value: 'narwhal' },
  { path: './octopus.png', id: 14, value: 'octopus' },
  { path: './penguin.png', id: 15, value: 'penguin' },
  { path: './pop.png', id: 16, value: 'pop' },
  { path: './potato.png', id: 17, value: 'potato' },
  { path: './rabbit.png', id: 18, value: 'rabbit' },
  { path: './ramen.png', id: 19, value: 'ramen' },
  { path: './redpanda.png', id: 20, value: 'redpanda' },
  { path: './remy.png', id: 21, value: 'remy' },
  { path: './rhino.png', id: 22, value: 'rhino' },
  { path: './tiger.png', id: 23, value: 'tiger' },
  { path: './weo.png', id: 24, value: 'weo' },
  { path: './whale.png', id: 25, value: 'whale' },
  { path: './wiette.png', id: 26, value: 'wiette' },
  { path: './yellow.png', id: 27, value: 'yellow' }
]
