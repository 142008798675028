import React from 'react'
import { Select } from 'antd'

const DataFilterSelector = ({
  category,
  filterData,
  onFilterChange,
  loading
}) => {
  const { options, selectedValues, label } = filterData
  const onChange = selectedOption => onFilterChange(category, selectedOption)

  return (
    <>
      <p style={{ marginLeft: '1px', marginBottom: '3px' }}>{label}:</p>
      <Select
        placeholder={`Select ${label}`}
        onSelect={onChange}
        onDeselect={onChange}
        disabled={loading}
        value={selectedValues}
        loading={loading}
        mode='multiple'
        key={category}
        style={{ width: '220px' }}
        filterOption={(inputValue, option) => {
          const displayName = option.props.children
          return displayName.toLowerCase().startsWith(inputValue.toLowerCase())
        }}
      >
        {options.map(option => (
          <Select.Option key={option.id} value={option.id}>
            {option.displayName}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default DataFilterSelector
