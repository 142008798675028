import { useEffect, useState } from 'react'
import uniqBy from 'lodash/uniqBy'

const firestore = window.firebase.firestore()

export default (roles = ['ninedotsTeacher', 'ninedotsAdmin']) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    Promise.all(
      roles.map(role =>
        firestore
          .collection('users')
          .where('role', '==', role)
          .get()
      )
    ).then(snaps => {
      setLoading(false)
      const users = uniqBy(
        snaps.reduce(
          (acc, snap) =>
            acc.concat(snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))),
          []
        ),
        'id'
      )
      return setUsers(users)
    })
  }, [...roles])
  return [users, loading]
}
