const Schema = require('@weo-edu/schema')
const validate = require('@weo-edu/validate')
const {
  displayName,
  firebaseRef,
  timestamp,
  email,
  uuid,
  url
} = require('../utils')

const principal = Schema()
  .prop('name', Schema('string'))
  .prop('email', email)

const cleverSchool = Schema()
  .prop('id', uuid)
  .prop('district', uuid)
  .prop('name', Schema('string'))
  .prop('created', timestamp)
  .prop('last_modified', timestamp)
  .prop('sis_id', Schema('string'))
  .prop('school_number', Schema('string'))
  .prop('state_id', Schema('string'))
  .prop('nces_id', Schema('string'))
  .prop('mdr_number', Schema('string'))
  .prop('low_grade', Schema('string'))
  .prop('high_grade', Schema('string'))
  .prop(
    'location',
    Schema()
      .prop('zip', Schema('string'))
      .prop('address', Schema('string'))
      .prop('city', Schema('string'))
      .prop('state', Schema('string'))
  )

const School = Schema()
  .prop('displayName', displayName)
  .prop('providers', Schema().prop('clever', cleverSchool.schema))
  .prop('district', firebaseRef)
  .prop('id', Schema('string'))
  .required(['displayName'])
  .others(false)

const update = Schema()
  .prop('school', firebaseRef)
  .prop('displayName', displayName)
  .prop('principal', principal)
  .prop('passwordEnabled', Schema('boolean'))
  .required(['displayName', 'principal'], 'missing_required_field')
  .others(false, 'invalid_keys')

const create = Schema()
  .prop('imageUrl', url)
  .prop('displayName', displayName)
  .prop('district', firebaseRef)
  .prop('principal', principal)
  .required(['displayName', 'district'], 'missing_required_field')
  .others(false, 'invalid_keys')

const addTeacher = Schema()
  .prop('school', firebaseRef)
  .prop('teacher', firebaseRef)
  .required(['school', 'teacher'], 'missing_required_field')
  .others(false, 'invalid_keys')

const removeTeacher = Schema()
  .prop('school', firebaseRef)
  .prop('teacher', firebaseRef)
  .required(['school', 'teacher'], 'missing_required_field')
  .others(false, 'invalid_keys')

const archive = Schema().prop('school', firebaseRef)

const schoolAndOnlySchool = Schema()
  .prop('school', firebaseRef)
  .required(['school'], 'missing_required_field')
  .others(false, 'invalid_keys')

const schoolsByDistrict = Schema().prop(
  /^[a-zA-Z0-9-_]{6,}$/,
  Schema('array').items(firebaseRef)
)

const dataDashboardSelectedFilters = Schema()
  .prop('districts', Schema('array').items(firebaseRef))
  .prop('schools', Schema('array').items(firebaseRef))
  .prop('grades', Schema('array').items(Schema('string')))
  .prop('ninedotsMembers', Schema('array').items(firebaseRef))
  .prop('teachers', Schema('array').items(firebaseRef))
  .prop('schoolYears', Schema('array').items(firebaseRef))
  .prop('grLevels', Schema('array').items(Schema('string')))
  .required(
    [
      'districts',
      'schools',
      'grades',
      'ninedotsMembers',
      'teachers',
      'schoolYears',
      'grLevels'
    ],
    'missing_required_field'
  )
  .others(false, 'invalid_keys')

const teacherEngagementDashboardSelectedFilters = Schema()
  .prop('districts', Schema('array').items(firebaseRef))
  .prop('schools', Schema('array').items(firebaseRef))
  .prop('grades', Schema('array').items(Schema('string')))
  .prop('teachers', Schema('array').items(firebaseRef))
  .prop('schoolYears', Schema('array').items(firebaseRef))
  .prop('grLevels', Schema('array').items(Schema('string')))
  .required(
    ['districts', 'schools', 'grades', 'teachers', 'schoolYears', 'grLevels'],
    'missing_required_field'
  )
  .others(false, 'invalid_keys')

exports.default = School
exports.removeTeacher = validate(removeTeacher)
exports.addTeacher = validate(addTeacher)
exports.archive = validate(archive)
exports.create = validate(create)
exports.update = validate(update)
exports.getProficiencyData = validate(dataDashboardSelectedFilters)
exports.getTeacherEngagement = validate(
  teacherEngagementDashboardSelectedFilters
)
exports.getOverviewData = validate(dataDashboardSelectedFilters)

/** @todo remove when getProficiencyOverview deprecated */
exports.getProficiencyOverview = validate(dataDashboardSelectedFilters)
