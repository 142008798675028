import {
  alphaCompare,
  gradesToLabel,
  nameSorter,
  teacherProgressToColor
} from 'shared/utils'
import { Table, Card, Row, Col, Progress } from 'antd'
import { gradeValueToId } from 'shared/utils/data'
import palette from 'shared/theme/vars/palette'
import React, { useState, useMemo } from 'react'
import uniq from 'lodash/uniq'

import './TeacherEngagementTable.less'

const LESSONS_TAUGHT_BENCHMARK = 25

export default ({ data }) => {
  const [sortInfo, setSortInfo] = useState({
    columnKey: 'teacherName',
    order: 'ascend'
  })

  const multiClassTeacherData = useMemo(() => getMultiClassTeacherData(data), [
    data
  ])
  const sortedData = sortTeacherData(data, sortInfo, multiClassTeacherData)

  return (
    <Card className='teacher-engagement-table'>
      <Table
        columns={columns}
        bordered
        dataSource={[getEngagementAggregate(sortedData), ...sortedData]}
        rowKey={(_, i) => i}
        pagination={false}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={(pagnation, filters, sorter, extra) => setSortInfo(sorter)}
      />
    </Card>
  )
}

const teacherSpecificColumns = [
  {
    title: 'Teachers',
    key: 'teacherName',
    sorter: () => 0,
    render: ({ rowSpan, teacherName = {}, priorityColor, badgeColor }) => {
      if (teacherName.family === 'All Teachers') return teacherName.family

      const obj = {
        children: (
          <Row type='flex' align='middle' justify='space-between'>
            {teacherName.family}, {teacherName.given}
            <div
              className='badge'
              style={{
                backgroundColor: rowSpan === 1 ? priorityColor : badgeColor
              }}
            />
          </Row>
        ),
        props: {
          className: 'teacherName',
          rowSpan
        }
      }

      return obj
    },
    defaultSortOrder: 'ascend'
  },
  {
    title: 'School',
    key: 'schoolName',
    sorter: () => 0,
    render: ({ rowSpan, schoolName }) => {
      const obj = {
        children: (
          <Row type='flex' align='middle' justify='center'>
            {schoolName}
          </Row>
        ),
        props: {
          rowSpan
        }
      }

      return obj
    }
  },
  {
    title: 'District',
    key: 'districtName',
    sorter: () => 0,
    render: ({ rowSpan, districtName }) => {
      const obj = {
        children: (
          <Row type='flex' align='middle' justify='center'>
            {districtName}
          </Row>
        ),
        props: {
          rowSpan
        }
      }

      return obj
    }
  },
  {
    title: '% of Certification Modules Completed',
    key: 'pdCompletionPercentage',
    sorter: () => 0,
    render: ({ pdCompletionPercentage, rowSpan }) => ({
      children:
        pdCompletionPercentage === null || isNaN(pdCompletionPercentage) ? (
          'No Assigned PD Modules'
        ) : (
          <span>
            <Progress strokeLinecap='square' percent={pdCompletionPercentage} />
          </span>
        ),
      props: { rowSpan }
    })
  },
  {
    title: 'Avg. Usage Time Per Week',
    key: 'avgUsageTime',
    sorter: () => 0,
    render: ({ avgUsageTime, rowSpan }) => ({
      children: (
        <Row type='flex' align='middle' justify='center'>
          {Number((avgUsageTime || 0) / 60).toFixed()} mins
        </Row>
      ),
      props: { rowSpan }
    })
  }
]

const classDataColumns = [
  {
    title: 'School Year',
    dataIndex: 'schoolYear',
    key: 'schoolYear',
    sorter: () => 0,
    align: 'center'
  },
  {
    title: 'Grade',
    key: 'grades',
    sorter: () => 0,
    render: ({ grades }) => gradesToLabel(grades),
    align: 'center'
  },
  {
    title: 'Lessons Taught',
    key: 'lessonsTaught',
    sorter: () => 0,
    render: ({ modulesTaughtData, lessonsTaught }) => {
      const benchmarkMet =
        parseInt(lessonsTaught, 10) >= LESSONS_TAUGHT_BENCHMARK
      return (
        <Row justify='center' align='middle' type='flex'>
          <Row type='flex' className='lessons-taught-progress'>
            {(modulesTaughtData || []).length > 0 && !benchmarkMet ? (
              modulesTaughtData.map(module => (
                <Col
                  className='default-progress-bar'
                  style={{ width: `${module.progress}%` }}
                  key={module.moduleId}
                />
              ))
            ) : benchmarkMet ? (
              <Col className='full-progress-bar' />
            ) : null}
          </Row>
          <span className='benchmark-proportion'>{`${lessonsTaught} / ${LESSONS_TAUGHT_BENCHMARK}`}</span>
        </Row>
      )
    },
    align: 'center'
  },
  {
    title: 'Last Lesson Taught',
    key: 'lastLessonTaught',
    sorter: () => 0,
    render: ({ timeSinceLastLesson, priorityColor }) => {
      return (
        <span style={{ color: priorityColor }}>
          {timeSinceLastLesson || 'NA'}
        </span>
      )
    },
    align: 'center'
  },
  {
    title: 'Current Lesson',
    key: 'currentLesson',
    render: ({ moduleName, lessonIndex }) => {
      if (moduleName === '-') {
        return moduleName
      } else if (moduleName && lessonIndex !== null) {
        return `${moduleName} / Lesson ${lessonIndex + 1}`
      } else {
        return 'NA'
      }
    },
    align: 'center'
  }
]

const columns = [...teacherSpecificColumns, ...classDataColumns]

/**
 * @function dateCompare
 * @description A function for sorting teachers by time since last lesson taught.
 * Note: Null values are sorted to bottom of list regardless of sort order
 * @param {Object} a
 * @param {Object} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function dateCompare(a, b, sortOrder) {
  const multiplier = sortOrder === 'ascend' ? 1 : -1
  const { lastLessonTaughtTime: aTime } = a || {}
  const { lastLessonTaughtTime: bTime } = b || {}

  if (aTime && !bTime) {
    return -1
  } else if (!aTime && bTime) {
    return 1
  } else if (!aTime && !bTime) {
    return 0
  }

  return multiplier * (new Date(bTime) - new Date(aTime))
}

/**
 * @function gradeSorter
 * @description A function for sorting classes by grade. Classes of the same
 * grade will be sorted by lastLessonTaughtTime if the classes have the same
 * teacher, otherwise they will be sorted by name.
 * @param {Object} a
 * @param {Object} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function gradeSorter(a, b, sortOrder) {
  const multiplier = sortOrder === 'ascend' ? 1 : -1
  const { grades: aGrade, id: aTeacherId } = a
  const { grades: bGrade, id: bTeacherId } = b

  if (aGrade && !bGrade) {
    return -1
  } else if (!aGrade && bGrade) {
    return 1
  } else if (!aGrade && !bGrade) {
    return 0
  }

  const aGradeId = gradeValueToId(aGrade[0])
  const bGradeId = gradeValueToId(bGrade[0])

  if (aGradeId === bGradeId) {
    if (aTeacherId === bTeacherId) {
      return (
        multiplier *
        (new Date(b.lastLessonTaughtTime) - new Date(a.lastLessonTaughtTime))
      )
    }

    return multiplier * nameSorter(a, b)
  }

  return multiplier * (aGradeId - bGradeId)
}

/**
 * @function lessonsTaughtSorter
 * @description A function for sorting classes by lessons taught.
 * @param {(Number|Object)} a
 * @param {(Number|Object)} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function lessonsTaughtSorter(a, b, sortOrder) {
  const aLessonsTaught = typeof a === 'number' ? a : a.lessonsTaught
  const bLessonsTaught = typeof b === 'number' ? b : b.lessonsTaught

  const multiplier = sortOrder === 'ascend' ? 1 : -1

  return multiplier * (aLessonsTaught - bLessonsTaught)
}

/**
 * @function getMultiClassTeacherData
 * @description Calculates an object of data for teachers with multiple classes.
 * The data contains min/max values for all sortable columns.
 * @param {Array.<Object>} filteredEngagementData
 * @returns {Object}
 */
function getMultiClassTeacherData(filteredEngagementData) {
  const multipleClasses = {} // keys - teacherIds, values - array of class objects
  const multiClassTeacherData = {}
  const teachers = uniq(
    filteredEngagementData.map(({ teacherId }) => teacherId)
  )

  filteredEngagementData.forEach(data => {
    const { teacherId, lastLessonTaughtTime, lessonsTaught } = data
    const newData = {
      ...data,
      priorityColor: teacherProgressToColor(lastLessonTaughtTime, lessonsTaught)
    }

    if (multipleClasses[teacherId]) {
      multipleClasses[teacherId].push(newData)
    } else {
      multipleClasses[teacherId] = [newData]
    }
  })

  teachers.forEach(teacher => {
    if (multipleClasses[teacher].length === 1) {
      delete multipleClasses[teacher]
    }
  })

  Object.keys(multipleClasses).forEach(teacher => {
    const classes = multipleClasses[teacher]
    const lastIndex = classes.length - 1
    const priorityValues = classes.map(({ priorityColor }) =>
      priorityColorToNum(priorityColor)
    )

    classes.sort((a, b) => gradeSorter(a, b, 'ascend'))
    multiClassTeacherData[teacher] = {
      badgeColor: priorityNumToColor(Math.min(...priorityValues)),
      numOfClasses: classes.length,
      teacherName: {
        min: classes[0].teacherName,
        max: classes[0].teacherName
      },
      grades: {
        min: classes[0].grades[0],
        max: classes[lastIndex].grades[0]
      }
    }

    const { lessonsTaughtValues, schoolYears } = classes.reduce(
      (accObj, { lessonsTaught, schoolYear }) => {
        const { lessonsTaughtValues, schoolYears } = accObj

        lessonsTaughtValues.push(lessonsTaught)
        schoolYears.push(schoolYear)

        return {
          lessonsTaughtValues,
          schoolYears
        }
      },
      {
        lessonsTaughtValues: [],
        schoolYears: []
      }
    )
    multiClassTeacherData[teacher].lessonsTaught = {
      min: Math.min(...lessonsTaughtValues),
      max: Math.max(...lessonsTaughtValues)
    }

    const sortedSchoolYears = schoolYears.sort()
    multiClassTeacherData[teacher].schoolYear = {
      min: sortedSchoolYears[0],
      max: sortedSchoolYears[sortedSchoolYears.length - 1]
    }

    const classesByTs = [...classes].sort((a, b) => dateCompare(a, b, 'ascend'))
    const filteredClasses = classesByTs.filter(
      ({ lastLessonTaughtTime }) => lastLessonTaughtTime !== null
    )

    multiClassTeacherData[teacher].lastLessonTaught = {
      min: classesByTs[0].lastLessonTaughtTime,
      max: (
        filteredClasses[filteredClasses.length - 1] || classesByTs[lastIndex]
      ).lastLessonTaughtTime
    }
  })

  return multiClassTeacherData
}

/**
 * @function sortTeacherData
 * @description Sorts filteredEngagementData taking into account multiClassTeacherData
 * of teachers with multiple classes.
 * @param {Array.<Object>} filteredEngagementData
 * @param {Object} sortInfo
 * @param {Object} multiClassTeacherData
 * @returns {Array.<Object>}
 */
function sortTeacherData(
  filteredEngagementData,
  sortInfo,
  multiClassTeacherData
) {
  const { columnKey, order } = sortInfo
  const multiplier = order === 'ascend' ? 1 : -1

  filteredEngagementData.sort((a, b) => {
    const data = {
      a,
      b,
      sortInfo,
      multiClassTeacherData
    }

    if (columnKey === 'teacherName') {
      return sortTeacherSpecificColumn(
        a,
        b,
        (a, b) => nameSorter(a, b) * multiplier
      )
    } else if (columnKey === 'districtName') {
      return sortTeacherSpecificColumn(
        a,
        b,
        (a, b) =>
          alphaCompare(a, b, 'districtName') * multiplier ||
          alphaCompare(a, b, 'schoolName') ||
          nameSorter(a, b)
      )
    } else if (columnKey === 'schoolName') {
      return sortTeacherSpecificColumn(
        a,
        b,
        (a, b) =>
          alphaCompare(a, b, 'schoolName') * multiplier || nameSorter(a, b)
      )
    } else if (columnKey === 'pdCompletionPercentage') {
      return sortTeacherSpecificColumn(
        a,
        b,
        (a, b) => pdCompletionCompare(a, b, multiplier) || nameSorter(a, b)
      )
    } else if (columnKey === 'avgUsageTime') {
      return sortTeacherSpecificColumn(
        a,
        b,
        (a, b) =>
          (a.avgUsageTime - b.avgUsageTime) * multiplier || nameSorter(a, b)
      )
    } else if (columnKey === 'schoolYear') {
      return (
        sortWithMulipleClasses(
          data,
          () => alphaCompare(a, b, 'schoolYear') * multiplier
        ) || nameSorter(a, b)
      )
    } else if (columnKey === 'lessonsTaught') {
      return (
        sortWithMulipleClasses(data, lessonsTaughtSorter) || nameSorter(a, b)
      )
    } else if (columnKey === 'lastLessonTaught') {
      return sortWithMulipleClasses(data, dateCompare) || nameSorter(a, b)
    } else if (columnKey === 'grades') {
      return sortWithMulipleClasses(data, gradeSorter) || nameSorter(a, b)
    }
  })

  const hasRowSpan = {}
  // add rowSpan, priorityColor, and badgeColor to all classes
  // Note: badgeColor is only set to a color if rowSpan is greater than 1, i.e. teacher name will be rendered
  const sortedFilteredData = filteredEngagementData.map(classData => {
    const { teacherId, lessonsTaught, lastLessonTaughtTime } = classData
    let badgeColor = ''
    let rowSpan

    if (multiClassTeacherData[teacherId]) {
      if (hasRowSpan[teacherId]) {
        rowSpan = 0
      } else {
        hasRowSpan[teacherId] = true
        rowSpan = multiClassTeacherData[teacherId].numOfClasses
        badgeColor = multiClassTeacherData[teacherId].badgeColor
      }
    } else {
      rowSpan = 1
    }

    return {
      ...classData,
      rowSpan,
      badgeColor,
      priorityColor: teacherProgressToColor(lastLessonTaughtTime, lessonsTaught)
    }
  })

  return sortedFilteredData
}

/**
 * @function sortTeacherSpecificColumn
 * @description used to sort columns that have the same values for a specific teacher
 * regardless of how many classes the teacher teaches, i.e. district, usage time, pd completion
 * @param {Object} a
 * @param {Object} b
 * @param {Function} sorterFn
 * @returns {Number}
 */
const sortTeacherSpecificColumn = (a, b, sorterFn) => {
  const { teacherId: aTeacherId } = a
  const { teacherId: bTeacherId } = b
  return aTeacherId === bTeacherId
    ? gradeSorter(a, b, 'ascend')
    : sorterFn(a, b)
}

/**
 * @function sortWithMulipleClasses
 * @description A higher-order sort function that takes into account teachers with
 * multiple classes when sorting. The sort considers the lowest/min value when
 * sorting by ascending order and the highest/max value when sorting by
 * descending order.
 * @param {Object} data
 * @param {Function} sorterFn
 * @returns {Number}
 */
function sortWithMulipleClasses(data, sorterFn) {
  const { a, b, sortInfo, multiClassTeacherData } = data
  const { columnKey, order } = sortInfo
  const { teacherId: aTeacherId } = a
  const { teacherId: bTeacherId } = b

  if (aTeacherId === bTeacherId) {
    return sorterFn(a, b, order)
  }

  const aClass = multiClassTeacherData[aTeacherId]
    ? order === 'ascend'
      ? multiClassTeacherData[aTeacherId][columnKey].min
      : multiClassTeacherData[aTeacherId][columnKey].max
    : a
  const bClass = multiClassTeacherData[bTeacherId]
    ? order === 'ascend'
      ? multiClassTeacherData[bTeacherId][columnKey].min
      : multiClassTeacherData[bTeacherId][columnKey].max
    : b

  return sorterFn(aClass, bClass, order)
}

/**
 * @author Giovanni Bonilla
 * @function pdCompletionCompare
 * @description A function for sorting teachers by pd completion percentage. Teacher's whose
 * PD completion percentage are null are sorted to the bottom regardless of sort order.
 * @param {Object} a
 * @param {Object} b
 * @param {Number} multiplier
 * @returns {Number}
 */
function pdCompletionCompare(a, b, multiplier) {
  const { pdCompletionPercentage: aPercent } = a
  const { pdCompletionPercentage: bPercent } = b
  const aPercentIsNull = aPercent === null || isNaN(aPercent)
  const bPercentIsNull = bPercent === null || isNaN(bPercent)

  if (aPercentIsNull && !bPercentIsNull) {
    return 1
  } else if (!aPercentIsNull && bPercentIsNull) {
    return -1
  } else if (aPercentIsNull && bPercentIsNull) {
    return 0
  }

  return (aPercent - bPercent) * multiplier
}

/**
 * @author Giovanni Bonilla
 * Given an array of engagement data, returns an object aggregating all the given data,
 * in a format that matches an engagement data object (with some exclusions that are not
 * required for displaying the data in this component).
 * @function getEngagementAggregate
 * @param {Array.<Object>} teacherEngagementData
 * @returns {Object}
 */
function getEngagementAggregate(teacherEngagementData) {
  const aggregateLessonsTaught = getPropertyAggregate(
    teacherEngagementData,
    'lessonsTaught'
  )

  const aggregateAvgUsageTime = getAvgUsageTimeAggregate(teacherEngagementData)

  const aggregateTotalPdActivities = getPropertyAggregate(
    teacherEngagementData,
    'totalPdActivities'
  )

  const aggregateCompletedPdActivities = getPropertyAggregate(
    teacherEngagementData,
    'completedPdActivities'
  )

  const numOfClasses = Object.keys(teacherEngagementData).length

  return {
    id: '',
    teacherName: {
      family: 'All Teachers'
    },
    grades: [],
    schoolYear: '-',
    schoolName: '-',
    districtName: '-',
    lessonsTaught: Math.round(aggregateLessonsTaught / numOfClasses),
    avgUsageTime: aggregateAvgUsageTime,
    pdCompletionPercentage: Math.round(
      aggregateCompletedPdActivities / aggregateTotalPdActivities
    ),
    moduleName: '-',
    timeSinceLastLesson: '-'
  }
}

/**
 * @author Giovanni Bonilla
 * @function getPropertyAggregate
 * @description Given an array of teacher engagement data and a property,
 * calculates the aggregate value for the given property
 * @param {Array.<Object>} teacherEngagementData
 * @param {String} property
 * @returns {Number}
 */
function getPropertyAggregate(teacherEngagementData, property) {
  return teacherEngagementData.reduce(
    (total, curr) => total + curr[property],
    0
  )
}

const getAvgUsageTimeAggregate = teacherEngagementData => {
  const teacherToUsageTimeMap = teacherEngagementData.reduce(
    (accObj, { teacherId, avgUsageTime }) => {
      if (accObj[teacherId] === undefined) {
        accObj[teacherId] = avgUsageTime
      }

      return accObj
    },
    {}
  )

  const aggregateAvgUsageTime = Object.values(teacherToUsageTimeMap).reduce(
    (sum, avgUsageTime) => sum + avgUsageTime,
    0
  )
  const numTeachers = Object.keys(teacherToUsageTimeMap).length || 1

  return aggregateAvgUsageTime / numTeachers
}

const priorities = [palette['@red'], palette['@yellow'], palette['@green']]
const priorityColorToNum = priorityColor => priorities.indexOf(priorityColor)
const priorityNumToColor = priorityNum => priorities[priorityNum]
