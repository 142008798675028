import { setItem, getItem, removeItem } from 'redux-effects-localstorage'
import { trackLogin, trackLogout } from '../../actions'
import createAction from '@f/create-action'
import apiRequest from '../../utils/api'

const auth = window.firebase.auth

export const userDidLogin = createAction('USER_DID_LOGIN')
export const userDidLogout = createAction('USER_DID_LOGOUT')

export const cookieLogin = () => dispatch => {
  return apiRequest('auth/cookieLogin')
    .then(({ token }) => {
      if (!token) return Promise.reject(new Error('no_token'))
      return dispatch(signInWithCredential(token))
    })
    .then(({ user }) => {
      dispatch(userDidLogin(user.uid))
      return user
    })
    .catch(e => {
      if (e.message === 'no_token') {
        return
      }
      return Promise.reject(e)
    })
}

export const login = service => async dispatch => {
  await dispatch(removeItem('studentLogoutRedirect'))
  const { url } = await apiRequest('auth/login/' + service)
  return window.location.assign(url)
}

/**
 * @function studentSignIn
 * @description An insecure login for students. Basically match the password with the
 * database to sign in. Consists of
 * - request token from api server
 * - firebase signInWithToken
 * - set logout redirect in local storage
 * - track user login
 * @param {object} data student credentials
 * @returns {object} user object
 */
export const studentSignIn = data => async dispatch => {
  try {
    const { token } = await apiRequest('auth/picturePassword', data)
    const user = await dispatch(signInWithCredential(token))
    dispatch(userDidLogin(user.user.uid))
    dispatch(
      setItem(
        'studentLogoutRedirect',
        window.location.href
          .split('/')
          .slice(0, -1)
          .join('/')
      )
    )
    return user
  } catch (e) {
    return Promise.reject(e)
  }
}

/**
 * @function logout
 * @description Logout thunk.
 * Checks local storage to see if we should redirect to school login page.
 * @param {Object} user User object
 */
export const logout = (user = {}, isForced) => async (dispatch, _) => {
  const onLogout = () =>
    isForced
      ? Promise.resolve(auth().signOut())
      : Promise.resolve(
        user.role === 'student'
          ? dispatch(getItem('studentLogoutRedirect'))
          : Promise.resolve()
      ).then((redirect = '/') => {
        // add the redirect || / in case of null value
        window.location.assign(redirect || '/')
      })
  dispatch(trackLogout())

  return apiRequest('auth/logout')
    .then(onLogout)
    .then(() => dispatch(removeItem('studentLogoutRedirect')))
}
logout.type = 'PLATFORM_LOGOUT'

export const signInWithCredential = token => {
  return async (dispatch, s) => {
    const user = await signInWithToken(token)
    dispatch(trackLogin(user))
    return user
  }
}

function signInWithToken (token) {
  return auth().signInWithCustomToken(token)
}
