import useSchoolMembersByRoles from 'shared/firestore/useSchoolMembersByRoles'
import useNinedotsMembers from 'shared/firestore/useNinedotsMembers'
import isNinedotsRole from 'shared/utils/isNinedotsRole'
import { studentGrades, grades } from 'shared/utils/data'
import { Modal, Form, Input, Select } from 'antd'
import { rpc, setUrl } from 'shared/actions'
import { getFullName } from 'shared/utils'
import useForm from 'shared/hooks/useForm'
import { connect } from 'react-redux'
import schema from 'schema'
import React from 'react'

import './CreateClassModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const CreateClassModal = ({
  onOk,
  rpc,
  role,
  setUrl,
  currentSchool,
  ...rest
}) => {
  const { id: schoolId, district } = currentSchool
  const [users, loading] = useSchoolMembersByRoles(currentSchool, [
    'admin',
    'teacher'
  ])
  const [ninedotsUsers, ninedotsLoading] = useNinedotsMembers()
  const {
    values,
    handleSubmit,
    getFieldProps,
    getFieldError,
    isSubmitting
  } = useForm({
    onSubmit: async vals => {
      await rpc('class.createClass', vals)
      onOk()
    },
    initialValues: {
      school: schoolId,
      district
    },
    validationSchema: schema.class.createClass
  })

  if (loading || ninedotsLoading) return <React.Fragment />
  const isNinedots = isNinedotsRole(role)
  const gradeOptions = isNinedots ? grades : studentGrades
  const teacherOptions =
    values.grade === 'ProfessionalDevelopment'
      ? ninedotsUsers
      : users.concat(ninedotsUsers)
  return (
    <Modal
      {...rest}
      title='Create New Class'
      confirmLoading={isSubmitting}
      onOk={handleSubmit}
      okText='Save'>
      <Form {...formItemLayout}>
        <Form.Item {...getFieldError('displayName')} label='Class Name'>
          <Input {...getFieldProps('displayName')} placeholder='Class Name' />
        </Form.Item>
        <Form.Item {...getFieldError('grade')} label='Grade'>
          <Select
            {...getFieldProps('grade', 'select')}
            placeholder='Select a grade'>
            {gradeOptions.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...getFieldError('teacher')} label='Teacher'>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            {...getFieldProps('teacher', 'select')}
            placeholder='Select a teacher'>
            {teacherOptions.map(({ id, ...user }) => (
              <Select.Option key={id} value={id}>
                {getFullName(user)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

/** @todo propTypes */

export default connect(
  ({
    firebase: {
      profile: { district, role }
    }
  }) => ({ district, role }),
  { rpc, setUrl }
)(CreateClassModal)
