export default ({
  name = {},
  displayName,
  district,
  school,
  email,
  role,
  id
}) => ({
  firstName: name.given,
  lastName: name.family,
  name: displayName,
  user_id: id,
  district,
  school,
  email,
  role
})
