/**
 * @file reducer for analytics
 * @author Julius Diaz Panoriñgan 
 */

import createAction from '@f/create-action'

export const setAnalytics = createAction('SET_ANALYTICS')

export default function (state = null, action) {
  const { type, payload } = action
  switch (type) {
    case setAnalytics.type:
      return payload !== undefined ? payload : null
    default:
      return state
  }
}
