import { useIsEqualRef, useLoadingValue } from './utils'
import { useEffect, useCallback } from 'react'

/**
 *
 * @param {FirebaseFirestore.CollectionReference | FirebaseFirestore.DocumentReference} firestoreRef
 * @param {*} options
 */
const useFirestore = (firestoreRef, options = {}) => {
  const { get, ...restOptions } = options
  const {
    error,
    loading,
    reset,
    setError,
    setSnap,
    snap: value
  } = useLoadingValue()
  const ref = useIsEqualRef(firestoreRef, reset)
  const setValue = useCallback(snap => {
    const value = snap.docs
      ? snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      : { id: snap.id, ...snap.data() }
    return setSnap(value)
  })

  useEffect(() => {
    if (firestoreRef) {
      if (get) {
        ref.current.get(restOptions).then(setValue, setError)
      } else {
        const listener = restOptions
          ? ref.current.onSnapshot(restOptions, setValue, setError)
          : ref.current.onSnapshot(setValue, setError)
        return () => {
          listener()
        }
      }
    } else {
      setSnap(undefined)
    }
  }, [ref.current])

  return [value, loading, error]
}

export default useFirestore
