import getRosterSync from 'shared/utils/isRosterSynced'
import { Modal, Form, Input, Select } from 'antd'
import useFirestore from 'shared/hooks/firestore'
import { classRef } from 'shared/firestore/refs'
import useForm from 'shared/hooks/useForm'
import { grades } from 'shared/utils/data'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import schema from 'schema'

import './EditClassModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const EditClassModal = ({ onOk, classId, rpc, ...rest }) => {
  const [doc, loading] = useFirestore(classRef(classId), { get: true })
  const cls = doc ? doc.data() : {}
  const { grade, displayName } = cls
  const canEditClasses = getRosterSync(cls).canEditField('classes')

  const {
    handleSubmit,
    getFieldProps,
    getFieldError,
    isSubmitting,
    updateValues
  } = useForm({
    onSubmit: async vals => {
      await rpc('class.updateDetails', vals)
      onOk()
    },
    validationSchema: schema.class.updateDetails
  })

  // Set initial values when Class loads
  useEffect(() => updateValues({ class: classId, grade, displayName }), [
    grade,
    displayName
  ])

  if (loading) return <React.Fragment />

  return (
    <Modal
      {...rest}
      title='Edit Class Info'
      confirmLoading={isSubmitting}
      onOk={handleSubmit}
      okText='Save'>
      <Form {...formItemLayout}>
        <Form.Item {...getFieldError('displayName')} label='Class Name'>
          <Input {...getFieldProps('displayName')} placeholder='Class Name' />
        </Form.Item>
        {canEditClasses && (
          <Form.Item {...getFieldError('grade')} label='Grade'>
            <Select
              {...getFieldProps('grade', 'select')}
              placeholder='Select a grade'>
              {grades.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

/** @todo propTypes */

export default connect(
  null,
  { rpc }
)(EditClassModal)
