import { trimValues, isPD, alphaCompare } from 'shared/utils'
import { classesBySchool } from 'shared/firestore/refs'
import useDistrict from 'hooks/useDistrict/useDistrict'
import { Modal, Form, Input, Select } from 'antd'
import useFirestore from 'shared/hooks/firestore'
import useForm from 'shared/hooks/useForm'
import { grades } from 'shared/utils/data'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import schema from 'schema'
import React from 'react'

import './CreateStudentModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const CreateStudentModal = ({
  create,
  school,
  classId,
  rpc,
  onOk,
  ...rest
}) => {
  const { id: schoolId, district } = school

  const [currentDistrict] = useDistrict(district)
  const requiredIdType = currentDistrict && currentDistrict.uniqueIdField

  const [classesSnap] = useFirestore(classesBySchool(school))
  const classes = classesSnap
    ? classesSnap.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(cls => !isPD(cls))
    : []

  // choose appropriate form validation schema based on required id type
  let validationSchema
  switch (requiredIdType) {
    case 'stateId':
      validationSchema = schema.user.createStudentWithStateId
      break
    case 'email':
      validationSchema = schema.user.createStudentWithEmail
      break
    default:
      validationSchema = schema.user.createStudent
  }

  const { handleSubmit, getFieldProps, getFieldError, isSubmitting } = useForm({
    onSubmit: async vals => {
      const { uid } = await rpc('user.createStudent', trimValues(vals))
      if (uid && vals.classId) {
        await rpc('class.addStudent', { class: vals.classId, student: uid })
      }
      onOk()
    },
    initialValues: {
      name: { family: '', given: '' },
      email: undefined,
      stateId: undefined,
      grade: undefined,
      classId: (classes.find(cls => cls.id === classId) || {}).id,
      school: schoolId,
      district
    },
    validationSchema
  })

  return (
    requiredIdType && (
      <Modal
        {...rest}
        confirmLoading={isSubmitting}
        title='Add New Student'
        onOk={handleSubmit}
        okText='Add'>
        <Form {...formItemLayout}>
          <Form.Item {...getFieldError('name.given')} label='First Name'>
            <Input {...getFieldProps('name.given')} placeholder='First Name' />
          </Form.Item>
          <Form.Item {...getFieldError('name.family')} label='Last Name'>
            <Input {...getFieldProps('name.family')} placeholder='Last Name' />
          </Form.Item>
          {requiredIdType === 'email' && (
            <Form.Item {...getFieldError('email')} label='Email'>
              <Input {...getFieldProps('email')} placeholder='Email' />
            </Form.Item>
          )}
          {requiredIdType === 'stateId' && (
            <Form.Item {...getFieldError('stateId')} label='State ID'>
              <Input {...getFieldProps('stateId')} placeholder='State ID' />
            </Form.Item>
          )}
          <Form.Item {...getFieldError('grade')} label='Grade'>
            <Select
              {...getFieldProps('grade', 'select')}
              placeholder='Select a grade'>
              {grades.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item {...getFieldError('classId')} label='Class'>
            <Select
              placeholder='Select a class'
              {...getFieldProps('classId', 'select')}>
              {classes
                .sort((a, b) => alphaCompare(a, b, 'displayName'))
                .map(({ displayName, id }) => (
                  <Select.Option key={id} value={id}>
                    {displayName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  )
}

/** @todo propTypes */

export default connect(
  null,
  { rpc }
)(CreateStudentModal)
